import { createContext } from 'react';
import { CartItem } from '../types/CartItem';
import { HistoryElement } from '../types/HistoryElement';
import { Address } from '../controllers/AddressController';
import { IOrgCustomInput } from '../types/OrgCustomInput';

type CartItemComplete = CartItem & {
  amount: number;
  obs?: string;
};

type PaymentModifier = {
  id: number;
  modifier: number;
  modifierType: string;
};

export interface IGlobalContext {
  isLoggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  guestUUID: string;
  setGuestUUID: React.Dispatch<React.SetStateAction<string>>;
  paymentModifier: PaymentModifier | undefined;
  setPaymentModifier: React.Dispatch<
    React.SetStateAction<PaymentModifier | undefined>
  >;
  tokenFacebook: string;
  setTokenFacebook: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  toastMessage: string;
  setToastMessage: React.Dispatch<React.SetStateAction<string>>;
  redirectTarget?: HistoryElement;
  setRedirectTarget: React.Dispatch<
    React.SetStateAction<HistoryElement | undefined>
  >;
  triggerRedirect: () => unknown;
  chosenAddress?: Address;
  setChosenAddress: React.Dispatch<React.SetStateAction<Address | undefined>>;
  cartItems: CartItemComplete[];
  setCartItems: React.Dispatch<React.SetStateAction<CartItemComplete[]>>;
  orgCustomInputs: Array<IOrgCustomInput>;
  setOrgCustomInputs(data: Array<IOrgCustomInput>): void;
}

const GlobalContext = createContext<IGlobalContext>({
  isLoggedIn: false,
  setLoggedIn: (): void => {},
  guestUUID: '',
  setGuestUUID: (): void => {},
  paymentModifier: undefined,
  setPaymentModifier: (): void => {},
  tokenFacebook: '',
  setTokenFacebook: (): void => {},
  isLoading: false,
  setLoading: (): void => {},
  toastMessage: '',
  setToastMessage: (): void => {},
  triggerRedirect: (): void => {},
  cartItems: [],
  setCartItems: (): void => {},
  setRedirectTarget: (): void => {},
  setChosenAddress: (): void => {},
  orgCustomInputs: [],
  setOrgCustomInputs: (): void => {},
});

export default GlobalContext;
