import styled from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const Title = styled.h1`
  margin-top: 96px;
  margin-bottom: 30px;
  font-weight: normal;
  font-size: ${FontSize.Big};
`;

const ListContent = styled.section`
  display: flex;
  flex-wrap: wrap;

  > div {
    min-width: 280px;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @media (min-width: 600px) {
    > div {
      min-width: 260px;
      max-width: 47.5%;

      &:nth-child(2n) {
        margin-left: 5%;
      }
    }
  }
`;

const Container = styled.main`
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  min-height: 30rem;
`;

const FilteredViewStyles = { Title, ListContent, Container };

export default FilteredViewStyles;
