import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import {MediaQuery} from 'octa-front-sdk';

type PaymentModifierType = {
  isDiscount?: boolean;
};

export const PixPaymentAlert = styled.div`
  padding: 1rem;
  background-color: ${Color.Red100};
  color: ${Color.Red700};
  border: 1px solid ${Color.Red700};
  border-radius: 8px;
  margin: 1rem 0;

  a {
    text-decoration: none;
  }

  p {
    color: ${Color.Red900};
  }
`;

const OrderId = styled.h1`
  margin: 0 20px 20px;
`;

const Date = styled.span`
  color: ${Color.Grey700};
`;

const PaymentModifier = styled.span<PaymentModifierType>`
  color: ${props =>
    props.isDiscount ? Color.Green500 : Color.Red900} !important;
  font-size: 0.8rem;
  font-weight: bold;
`;

const CustomMessage = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  background-color: ${Color.Blue100};
  border: 1px solid ${Color.Blue200};
  border-radius: 5px;
  color: ${Color.Blue700};
  padding: 10px;
  margin-bottom: 1rem;
  font-size: ${FontSize.Small};
  text-align: center;
  margin: 0 -20px;
`;

const RejectedOrder = styled.div`
  color: ${Color.Red500};
  font-size: ${FontSize.NormalX};
`;

const Separator = styled.div`
  flex: 1;
  margin: 0 5px;
  border-bottom: 1px dashed ${Color.Grey400};
`;

const Amount = styled.span<{ outline?: boolean }>`
  padding: 5px;
  margin-right: 10px;
  background-color: ${({outline}): string =>
    outline ? 'transparent' : Color.Grey200};
  color: ${({outline}): string => (outline ? Color.Grey500 : Color.Grey700)};
  border-radius: 3px;
  font-weight: ${({outline}): string => (outline ? 'normal' : 'bold')};
`;

const Name = styled.span`
  color: ${Color.Grey900};
  font-size: ${FontSize.Normal};

  &:last-of-type {
    white-space: nowrap;
  }

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Normal};
  }
`;

const Price = styled(Name)``;

const ComplementName = styled(Name)`
  color: ${Color.Grey500};
  font-size: ${FontSize.SmallX};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Product = styled.li`
  margin-bottom: 20px;

  ${Separator} {
    min-width: 10%;
  }
`;

const MainItem = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex: 1;
  }
`;

const FlavourItem = styled.li`
  display: flex;
  align-items: center;
`;

const Flavours = styled.ul`
  margin: 0 0 10px 3.5em;
  padding: 0;
  list-style: none;

  ${Name} {
    color: ${Color.Grey700};
    font-size: ${FontSize.Normal};
    line-height: 1.5em;
  }
`;

const ExtraItem = styled.li`
  display: flex;
  align-items: center;
`;

const Extras = styled.ul`
  margin: 0;
  margin-left: 1.5em;
  padding: 0;
  list-style: none;

  ${Name} {
    color: ${Color.Grey500};
    font-size: ${FontSize.NormalX};
  }
`;

const Products = styled.ul`
  margin: 30px 0 0;
  padding: 0;
  list-style: none;
`;

const ChargesAndChange = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  &#total {
    margin-top: 40px;
  }

  span {
    color: ${Color.Grey700};
  }
`;

const PaymentMethod = styled.div`
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;

  &,
  span {
    color: ${Color.Grey700};
  }

  span:last-of-type {
    text-transform: uppercase;
  }
`;

const PhoneContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div:first-child {
    margin-bottom: 20px;
  }
`;

const Address = styled.span`
  color: ${Color.Grey700};

  strong {
    text-transform: uppercase;
  }
`;

const SectionTop = styled.div`
  padding: 10px 0;

  h1 {
    font-size: ${FontSize.NormalX};
    margin: 0;
    color: ${Color.Grey900};
  }
`;

const SectionContent = styled.div`
  padding: 0 1rem;
  margin-bottom: 1rem;

  &:first-of-type {
    display: flex;
    justify-content: space-between;
  }
`;

const CardIcon = styled.div`
  color: ${Color.Grey300};
  font-size: 40px;
  margin-right: 10px;
`;

const CardContent = styled.div`
  display: block;
  padding: 10px;
  width: 100%;
`;

const SectionCard = styled.div`
  background-color: ${Color.White};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(255, 255, 255, 0.16);
  border-radius: 10px;
  padding: 20px;
  margin: 10px auto;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  background-color: ${Color.Grey50};
`;

const DetailsStepStyles = {
  PaymentModifier,
  Date,
  CustomMessage,
  RejectedOrder,
  OrderId,
  Separator,
  Amount,
  Name,
  Price,
  ComplementName,
  InfoContainer,
  MainItem,
  FlavourItem,
  Product,
  Flavours,
  ExtraItem,
  Extras,
  Products,
  ChargesAndChange,
  PaymentMethod,
  PhoneContainer,
  Address,
  SectionTop,
  SectionContent,
  CardIcon,
  CardContent,
  SectionCard,
  Container,
};

export default DetailsStepStyles;
