import React, { ReactElement } from 'react';
import Icon from 'octa-front-sdk/dist/components/Icon';
import IconName from 'octa-front-sdk/dist/enums/IconName';
import Color from 'octa-front-sdk/dist/enums/Color';
import WhatsappLinkStyles from './styles';

interface IProps {
  phone: string;
  message?: string;
  label?: string;
}

const WhatsappLink = (props: IProps) => {
  const { phone, message, label } = props;

  return (
    <WhatsappLinkStyles.Container
      href={`https://wa.me/55${phone}${message ? `?text=${message}` : ''}`}
      target="_blank"
    >
      {label && <WhatsappLinkStyles.Label>{label}</WhatsappLinkStyles.Label>}

      <Icon name={IconName.Whatsapp} color={Color.White} />
    </WhatsappLinkStyles.Container>
  );
};

export default WhatsappLink;
