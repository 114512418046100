import React, { ReactElement } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page';
import Color from "octa-front-sdk/dist/enums/Color";

const ProfilePage = (props: RouteComponentProps) => {
  const { match } = props;

  return (
    <Page title="Perfil" isPrivate>
      <p>
        <Link to={`${match.url}/enderecos`}>Endereços</Link>
      </p>

      <p>
        <Link to="sair/">Sair</Link>
      </p>

      <p>
        <Link to={`${match.url}/apagar`} style={{ color: Color.Red500 }}>
          Apagar conta
        </Link>
      </p>
    </Page>
  );
};

export default withRouter(ProfilePage);
