import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import OrganizationContext from '../../contexts/OrganizationContext';

import ImageFullscreen from '../ImageFullscreen';

const ONE_DAY_MILISECONDS = 86400000;

export const InitialPopup = () => {
  const { organizationInfo } = useContext(OrganizationContext);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (organizationInfo && !!organizationInfo.popupUrl) {
      if (organizationInfo.alwaysShowPopup) return setShowPopup(true);

      const lastExhibition = localStorage.getItem(
        `@PedidoOnline:${organizationInfo.id}-popup`,
      );
      const isAfter =
        lastExhibition && dayjs().isAfter(JSON.parse(lastExhibition));

      if (!lastExhibition || isAfter) {
        setShowPopup(true);
        localStorage.setItem(
          `@PedidoOnline:${organizationInfo.id}-popup`,
          (+new Date() + ONE_DAY_MILISECONDS).toString(),
        );
      }
    }
  }, [organizationInfo]);

  return showPopup ? (
    <ImageFullscreen
      imgUrl={organizationInfo?.popupUrl}
      handleClose={() => setShowPopup(false)}
    />
  ) : (
    <></>
  );
};
