import { ReactElement, useState, useContext } from 'react';
import Form from 'octa-front-sdk/dist/components/Form';
import Input from 'octa-front-sdk/dist/components/Input';
import { validatePassword } from 'octa-front-sdk';
import InputType from 'octa-front-sdk/dist/enums/InputType';
import FormContext, {
  FormValues,
} from 'octa-front-sdk/dist/contexts/FormContext';
import Button from 'octa-front-sdk/dist/components/Button';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';

import LoginFormStyles from './styles';
import Loader from '../Loader';
import GlobalContext from '../../contexts/GlobalContext';
import OrganizationContext from '../../contexts/OrganizationContext';
import { validateEmail } from '../../utils/validateEmail';

interface IProps {
  onLogin: () => void;
}

const LoginForm = (
  props: IProps & RouteComponentProps
): ReactElement => {
  const { onLogin, match } = props;

  const { setLoggedIn } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  const [isLoading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const handleLogin = (values: FormValues): void => {
    const { username, password } = values;

    setLoading(true);
    setCredentials({ username: username.toString().trim(), password: '' });

    RequestHandler.post('auth/login/', { username, password })
      .then((response): void => {
        const { data } = response;

        Object.keys(data).map((key): void =>
          localStorage.setItem(key, data[key]),
        );

        setLoggedIn(true);

        onLogin();
      })
      .finally((): void => setLoading(false));
  };

  return (
    <LoginFormStyles.Container>
      {isLoading && <Loader />}

      <Form onSubmit={handleLogin} initialValues={{ ...credentials }}>
        <Input name="username" label="Email" validator={validateEmail} />

        <Input
          name="password"
          label="Senha"
          validator={validatePassword}
          type={InputType.Password}
        />

        <button
          type="button"
          className="forgot-password"
          onClick={() =>
          (window.location.href = `${window.location.origin}/${organizationInfo && organizationInfo.urlSuffix
            }/perfil/recuperar-senha`)
          }
        >
          Esqueceu a senha?
        </button>

        <FormContext.Consumer>
          {({ hasErrors }): ReactElement => (
            <Button text="Entrar" isDisabled={hasErrors} />
          )}
        </FormContext.Consumer>
      </Form>
      <LoginFormStyles.SignUpCall>
        Ainda não tem uma conta?{' '}
        <Link to={`/${match.url.split('/')[1]}/perfil/cadastrar`}>
          Cadastre-se
        </Link>
      </LoginFormStyles.SignUpCall>
    </LoginFormStyles.Container>
  );
};

export default withRouter(LoginForm);
