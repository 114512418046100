import React, {
  useImperativeHandle,
  useState,
  ReactElement,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useChain, useSpring, useSpringRef } from 'react-spring';

import { ScrollProvider } from '../../contexts/ScrollContext';
import BtnIcon from '../BtnIcon';

import { Container, Overlay, SidebarContent } from './styles';

interface IProps {
  title?: string;
  showHeader?: boolean;
  children: ReactElement;
}

export interface ISidebarRef {
  isOpen: () => boolean;
  setIsOpen: (value: boolean) => void;
  toggle: () => void;
}

const Sidebar = forwardRef<ISidebarRef, IProps>(
  ({ children, title, showHeader }, ref) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const [isActive, setIsActive] = useState(false);
    const [renderComponent, setRenderComponent] = useState(true);

    const overlayRef = useSpringRef();
    const overlayProps = useSpring({
      ref: overlayRef,
      opacity: isActive ? 1 : 0,
      delay: 200,
      onStart: () => {
        if (isActive) setRenderComponent(true);
      },
      onResolve: () => {
        if (!isActive) setRenderComponent(false);
      },
    });

    const sidebarRef = useSpringRef();
    const sidebarProps = useSpring({
      ref: sidebarRef,
      right: isActive ? '0%' : '-100%',
      delay: 200,
      reverse: !isActive,
    });

    useChain([overlayRef, sidebarRef], [0, 0.05]);

    useImperativeHandle(ref, () => ({
      isOpen: () => isActive,
      setIsOpen: (value: boolean) => setIsActive(value),
      toggle: () => {
        setIsActive(value => !value);
      },
    }));

    useEffect(() => {
      const mainScroll = document.getElementById('main-container');

      if (mainScroll) {
        if (isActive) mainScroll.style.overflow = 'hidden';
        if (!isActive) mainScroll.style.overflow = 'auto';
      }
    }, [isActive]);

    const enableHeader = !!title || showHeader;

    const [scrollIndex, setScrollIndex] = useState(1);

    function scrollToNext(name: string) {
      const children = contentRef.current?.children;

      if (children) {
        const targetChild = children.namedItem(name);

        //* Receber a ref do elemento pai e scrollar para o elemento filho usando o ID

        if (targetChild) {
          targetChild.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          setScrollIndex(val => val + 1);
        }
      }
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return renderComponent ? (
      <Container style={overlayProps}>
        <Overlay onClick={() => setIsActive(false)} />

        <ScrollProvider scrollContainerRef={contentRef}>
          <SidebarContent ref={contentRef} style={sidebarProps}>
            {enableHeader && (
              <div className="header">
                <h2 className="text-lg">{title}</h2>
                <BtnIcon
                  icon={faTimes}
                  size="2x"
                  color="#23999E"
                  onClick={() => setIsActive(false)}
                />
              </div>
            )}

            {children}
            {isSafari && <div style={{ height: '100px' }} />}
          </SidebarContent>
        </ScrollProvider>
      </Container>
    ) : (
      <></>
    );
  },
);

export default Sidebar;
