import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

const QuantityContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > span {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0.6rem;
    border-radius: 0.2rem;
    background-color: ${Color.BlueGrey200};
    color: ${Color.BlueGrey600};
  }
`;

const ProductContent = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  position: relative;

  > button {
    transition: 0.4s;
    transition-timing-function: ease-in-out;
    position: absolute;
    align-self: flex-end;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }

  > button:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  > button > img {
    width: 1.6rem;
  }

  > span {
    margin: 2px;
    color: ${Color.BlueGrey400};
  }

  > .warning {
    display: flex;
    align-items: center;
  }

  > .warning > img {
    width: 1.4rem;
    margin-right: 4px;
  }

  > .title {
    font-weight: bold;
    color: ${Color.BlueGrey600};
  }

  > .price {
    margin-top: 4px;
    text-align: end;
    font-weight: bold;
    color: ${Color.BlueGrey600};
  }
`;

const Warning = styled.p`
  flex: 100%;
`;

const Separator = styled.div`
  flex: 1;
  margin: 0 5px;
  border-bottom: 1px dashed ${Color.Grey400};
  min-width: 30px;
  height: 40%;
`;

const Amount = styled.span<{ outline?: boolean }>`
  padding: 5px;
  margin-right: 10px;
  background-color: ${({ outline }): string =>
    outline ? 'transparent' : Color.Grey200};
  color: ${({ outline }): string => (outline ? Color.Grey500 : Color.Grey700)};
  border-radius: 3px;
  font-weight: ${({ outline }): string => (outline ? 'normal' : 'bold')};
`;

const Name = styled.span`
  color: ${Color.Grey900};
  font-size: ${FontSize.Normal};

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.NormalX};
  }
`;

const Price = styled(Name)``;

const ComplementName = styled(Name)`
  color: ${Color.Grey500};
  font-size: ${FontSize.SmallX};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  & > span {
    &:last-of-type {
      white-space: nowrap;
    }

    ${MediaQuery.BiggerThanSmall} {
      font-size: ${FontSize.Normal};
    }
  }

  & > svg {
    margin-left: 10px;
  }
`;

const Flavours = styled.ul`
  margin: 0;
  margin-left: 4em;
  padding: 0;
  list-style: none;

  ${Name} {
    color: ${Color.Grey700};
    font-size: ${FontSize.Normal};
    line-height: 0.8em;
  }
`;

const Product = styled.li`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid ${Color.BlueGrey100};
  width: 100%;
  max-width: 32rem;

  &:last-child {
    border-bottom: none;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  & > ul {
    margin-top: 15px;
    width: 100%;

    & > li {
      flex-wrap: nowrap;
    }
  }
`;

const Extras = styled.ul`
  margin: 0;
  margin-left: 2em;
  padding: 0;
  list-style: none;

  ${Name} {
    color: ${Color.Grey500};
    font-size: ${FontSize.NormalX};
  }
`;

const Products = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Container = styled.div`
  margin-bottom: 10px;

  & > ${InfoContainer} {
    margin-top: 40px;
  }
`;

const ReceiptStyles = {
  QuantityContainer,
  ProductContent,
  Warning,
  Separator,
  Amount,
  Name,
  Price,
  ComplementName,
  InfoContainer,
  Flavours,
  Extras,
  Product,
  Products,
  Container,
};

export default ReceiptStyles;
