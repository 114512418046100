// @ts-nocheck

import { ReactElement, useState, useEffect, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Button from 'octa-front-sdk/dist/components/Button';
import Color from 'octa-front-sdk/dist/enums/Color';
import {
  formatDate,
  getHoursAndMinutesFromDate,
  parseQueryString,
} from 'octa-front-sdk';
import Wizard from 'octa-front-sdk/dist/components/Wizard';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import Page from '../../components/Page';
import PizzaLoader from '../../components/PizzaLoader';
import { Order } from '../../types/Order';
import OrdersPageStyles from './styles';
import { formatPrice, orderStatuses } from '../../Helpers';
import OrderStatus from '../../enums/OrderStatus';
import DetailsStep from './DetailsStep';
import OrganizationContext from '../../contexts/OrganizationContext';
import GlobalContext from '../../contexts/GlobalContext';

const OrdersPage = (props: RouteComponentProps) => {
  const { history, match, location } = props;
  const [isLoading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>();
  const [isWizardOpen, setWizardOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState();
  const { organizationInfo } = useContext(OrganizationContext);
  const { isLoggedIn } = useContext(GlobalContext);

  const getOrderUpdated = (id: string) => {
    RequestHandler.get(`user/orders/${id}/status`).then((response): void => {
      // @ts-ignore
      const currentOrders = [...orders];
      const index = currentOrders.findIndex(obj => obj.id === Number(id));
      const newOrder = currentOrders[index];

      newOrder.status = response.data.status.status;

      currentOrders.splice(index, 1, newOrder);
      setOrders([...currentOrders]);
    });
  };

  useEffect((): void => {
    const orderId = parseQueryString(location.search).detalhes;

    if (orderId) {
      // @ts-ignore
      setCurrentOrderId(orderId);

      setWizardOpen(true);
    }
  }, [location.search]);

  useEffect((): void => {
    if (isWizardOpen !== undefined && !isWizardOpen) {
      window.history.pushState(null, '', '?');
    }
  }, [isWizardOpen]);

  useEffect((): void => {
    if (organizationInfo && !!isLoggedIn) {
      RequestHandler.defaults.headers.common = {
        ...RequestHandler.defaults.headers.common,
        'X-Org-Id': organizationInfo.id,
      };

      setLoading(true);

      RequestHandler.get('user/orders/')
        .then((response): void => setOrders(response.data))
        .finally((): void => setLoading(false));
    }
  }, [setLoading, organizationInfo, isLoggedIn]);

  if (isLoading) return <PizzaLoader message="Buscando pedidos..." />;

  return (
    <>
      <Page title="Pedidos" isPrivate>
        <OrdersPageStyles.Container>
          {orders && orders.length ? (
            <OrdersPageStyles.List>
              {orders.map(
                (order) => (
                  <OrdersPageStyles.Card
                    key={order.id}
                    onClick={(): void => {
                      // @ts-ignore
                      setCurrentOrderId(order.id);

                      window.history.pushState(
                        null,
                        '',
                        `?detalhes=${order.id}`,
                      );

                      setWizardOpen(true);
                    }}
                  >
                    <OrdersPageStyles.Date>
                      {`${formatDate(new Date(order.orderDate))
                        .split('-')
                        .reverse()
                        .join('/')} (${getHoursAndMinutesFromDate(
                          new Date(order.orderDate),
                        )})`}
                    </OrdersPageStyles.Date>

                    <OrdersPageStyles.ProductsList>
                      {order.products &&
                        order.products.map(
                          (product, i): ReactElement => (
                            <OrdersPageStyles.Product key={i}>
                              {`${product.amount}x ${product.name}`}
                            </OrdersPageStyles.Product>
                          ),
                        )}
                    </OrdersPageStyles.ProductsList>

                    <OrdersPageStyles.BottomWrapper>
                      <OrdersPageStyles.Price>
                        {formatPrice(order.totalValue)}
                      </OrdersPageStyles.Price>

                      <OrdersPageStyles.Status>
                        {order.isPaid ?
                          orderStatuses()[
                          Object.keys(OrderStatus).findIndex(
                            (s): boolean => s === order.status,
                          )
                          ] : "Aguardando pagamento"
                        }
                      </OrdersPageStyles.Status>
                    </OrdersPageStyles.BottomWrapper>
                  </OrdersPageStyles.Card>
                ),
              )}
            </OrdersPageStyles.List>
          ) : (
            <div>
              <p>Você ainda não fez um pedido.</p>

              <Button
                text="Que tal pedir agora?"
                color={Color.MeuDeliveryCyan}
                onClick={(): void =>
                  history.push(`/${match.url.split('/')[1]}`)
                }
              />
            </div>
          )}
        </OrdersPageStyles.Container>
      </Page>

      <Wizard
        steps={[
          <DetailsStep
            currentOrderId={currentOrderId}
            shouldRenderIndicator={false}
            shouldRenderBackButton={false}
            shouldHideBottomWaves
          />,
        ]}
        shouldRender={isWizardOpen}
        closeWizard={(): void => {
          getOrderUpdated(currentOrderId);
          setWizardOpen(false);
        }}
      />
    </>
  );
};

export default withRouter(OrdersPage);
