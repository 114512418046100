import styled, { css, keyframes } from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

interface IPropsStep { active: boolean, isPaid?: boolean, currentIndex: number }

const breatheAnimation = keyframes`
 0% { transform: scale(1); }
 30% { transform: scale(1.05); }
 40% { transform: scale(1.2); }
 100% { transform: scale(1.05); }
`;

function changeStepColor({ active, currentIndex, isPaid }: IPropsStep) {
  if (!isPaid && currentIndex === 0) return css`color: ${Color.Red500};`;
  if (!active) return css`color: ${Color.Grey400};`;
  return css`color: ${Color.MeuDeliveryCyan};`;
}

const MarkerIcon = styled.div`
  font-size: 35px;
  color: ${Color.Grey400};
  flex: 1;
  height: 50px;
`;

const ProgressBar = styled.div<{ active: boolean }>`
  height: 3px;
  width: 95%;
  top: calc((1.125rem - 3px) / 2);
  left: calc(50% + 7px);
  position: absolute;
  background-color: ${({ active }): string =>
    active ? Color.MeuDeliveryCyan : Color.Grey400};
`;

const CircleIcon = styled.div`
  height: ${FontSize.NormalX};
  align-items: center;

  &.pulse {
    svg {
      animation-name: ${breatheAnimation};
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
    }
  }
`;

const IconsContainer = styled.div<{ spacer: boolean }>`
  position: relative;
  margin-top: ${({ spacer }): string => (spacer ? '50px' : '0')};
`;

const Steps = styled.li<IPropsStep>`
  font-size: ${FontSize.NormalX};
  text-align: center;
  flex: 1;

  ${({ active, currentIndex, isPaid = false }) => changeStepColor({ active, currentIndex, isPaid })}

  p {
    margin: 10px 3px 5px 3px;
    font-size: ${FontSize.Small};
    text-transform: uppercase;
    ${({ active, currentIndex, isPaid = false }) => changeStepColor({ active, currentIndex, isPaid })}

    ${MediaQuery.BiggerThanSmall} {
      font-size: ${({ active }): string =>
    active ? FontSize.SmallX : FontSize.Small};
    }
  }

  span {
    margin-top: 0;
    color: ${({ active }): string => (active ? Color.Grey500 : '')};
    font-size: ${FontSize.Small};
  }

  &:last-child {
    ${ProgressBar} {
      display: none;
    }
  }
`;

const Content = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
`;

const TimelineStyles = {
  MarkerIcon,
  ProgressBar,
  CircleIcon,
  IconsContainer,
  Steps,
  Content,
  Container,
};

export default TimelineStyles;
