import styled, { css } from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';
import { MediaQuery } from 'octa-front-sdk';

interface IPropsCard {
  unavailable?: boolean;
}

export const SimpleCard = styled.button<IPropsCard>`
  flex: 1;
  display: flex;
  grid-column: span 1 / span 1;
  flex-direction: column;
  height: 16rem;
  padding: 12px;
  border: 1px solid ${Color.Grey300};
  border-radius: 6px;
  background-color: ${Color.White};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  figure {
    width: 100%;
    height: 120px;
    margin: 0 0 8px 0;
    border-radius: 4px;
    padding: 0;
    background-color: ${Color.Grey300};
  }

  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: ${Color.Grey600};
  }

  h2 {
    margin: 0 0 8px 0;
    font-size: 1rem;
    text-align: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin: 0;
  }

  ${props =>
    props.unavailable &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

export const Ribbon = styled.div<IPropsCard>`
  width: 60%;
  padding: 5px 0;
  background-color: ${props =>
    props.unavailable ? Color.Grey500 : Color.MeuDeliveryCyan};
  color: ${Color.White};
  position: absolute;
  bottom: 25%;
  right: -15%;
  transform: rotate(-45deg);
  transform-origin: center;
  text-align: center;
  text-transform: uppercase;
`;

export const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${MediaQuery.BiggerThanSmall} {
    max-width: 100%;
  }
`;

export const Description = styled.p`
  color: #6c6c6c;
  font-size: ${FontSize.Small};
  margin: 6px 8px 8px 16px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 1;
`;

export const Name = styled.h1`
  font-size: ${FontSize.Normal};
  color: ${Color.Black};
  font-weight: 500;
  margin: 0;
  padding: 14px 8px 0px 16px;
  line-height: 1.2rem;

  flex: 1;
`;

export const PhotoContainer = styled.div`
  background-color: ${Color.Grey200};

  flex: 1;
  width: 9rem;
  height: 9rem;
  border-radius: 0 0.5rem 0.5rem 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 321px) {
    width: 10rem;
    height: 10rem;
    flex: 5;
  }
`;

export const Card = styled.div<IPropsCard>`
  width: 100%;
  height: 9rem;

  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s;
  position: relative;

  background-color: ${Color.White};
  border: 0.5px solid #d7d7d7;
  border-radius: 0.5rem;

  ${props =>
    props.unavailable &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  &:hover {
    filter: brightness(0.95);
    border: 0.5px solid ${Color.Grey500};
  }

  .infos {
    display: grid;
    flex-direction: column;
    align-content: space-between;
    flex: 1;
    padding: 0.75rem;

    .title {
      margin: 0 0 0.5rem 0;
      font-size: 1rem;
      font-weight: 500;
      color: ${Color.BlueGrey900};

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .desc {
      margin: 0 0 0.5rem 0;
      font-size: 0.75rem;
      color: ${Color.BlueGrey600};

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .start-at {
      font-size: 0.75rem;
      color: ${Color.BlueGrey700};
    }

    .price {
      font-size: 1rem;
      color: #7ac287;
      font-weight: 500;
      margin: 0;
    }

    .promotion .from {
      font-size: 0.75rem;
      margin: 0 0 6px 0;
      color: ${Color.BlueGrey700};
      text-decoration: line-through;

      strong {
        font-weight: normal;
        color: ${Color.BlueGrey800};
      }
    }

    .promotion .for {
      font-size: 1rem;
      margin: 0;
      color: ${Color.BlueGrey600};

      strong {
        color: #7ac287;
      }
    }
  }

  @media (min-width: 321px) {
    height: 10rem;

    .infos {
      flex: 7;
      padding: 0.75rem;

      .desc {
        font-size: 0.85rem;
      }
    }
  }
`;
