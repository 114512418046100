import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';

import GlobalContext from '../contexts/GlobalContext';
import OrganizationContext from '../contexts/OrganizationContext';

import { LoyaltyProgram } from '../types/LoyaltyProgram';
import { MemberPoints } from '../types/MemberPoints';

interface LoyaltyContextData {
  loyaltyProgram: LoyaltyProgram | null;
  setLoyaltyProgram(data: LoyaltyProgram): void;
  memberPoints: MemberPoints | null;
  setMemberPoints(data: MemberPoints): void;
}

const LoyaltyContext = createContext<LoyaltyContextData>(
  {} as LoyaltyContextData,
);

const LoyaltyProvider = ({ children }: { children: React.ReactNode }) => {
  const { cartItems, isLoggedIn } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  const [loyaltyProgram, setLoyaltyProgram] = useState<LoyaltyProgram | null>(
    null,
  );
  const [memberPoints, setMemberPoints] = useState<MemberPoints | null>(null);

  useEffect((): void => {
    const getLoyaltyData = async (id: number): Promise<void> => {
      const itemsReedmed = cartItems.filter(item => item.redeemed === true);

      RequestHandler.defaults.headers.common = {
        ...RequestHandler.defaults.headers.common,
        'X-Org-Id': id,
      };

      const loyaltyProgram = await RequestHandler.get(
        'loyaltyprogram/organization',
      ).then(({ data }): LoyaltyProgram => data);

      if (loyaltyProgram) {
        setLoyaltyProgram(loyaltyProgram);

        const pointsArray = itemsReedmed.map(item => {
          const points = loyaltyProgram.prizes.find(prize => {
            let p;

            if (
              item.product.id !== undefined &&
              prize.productId === item.product.id.toString()
            ) {
              p = prize;
            }

            return p;
          });

          return points?.priceInPoints;
        });

        const totalPointsSpend = pointsArray.reduce(
          (total, current): number => {
            return (total! += current!);
          },
          0,
        );

        if (isLoggedIn) {
          const memberPoints = await RequestHandler.get(
            'loyaltyprogram/member/points',
          ).then(({ data }): MemberPoints => data);

          let cartPoints = 0;

          if (memberPoints && totalPointsSpend !== undefined) {
            cartPoints = memberPoints.validPoints - totalPointsSpend;
          }

          setMemberPoints({ ...memberPoints, cartPoints });
        }
      }
    };

    if (organizationInfo) getLoyaltyData(organizationInfo.id);
  }, [cartItems, isLoggedIn, organizationInfo]);

  return (
    <LoyaltyContext.Provider
      value={{
        loyaltyProgram,
        setLoyaltyProgram,
        memberPoints,
        setMemberPoints,
      }}
    >
      {children}
    </LoyaltyContext.Provider>
  );
};

function useLoyalty(): LoyaltyContextData {
  const context = useContext(LoyaltyContext);

  if (!context) {
    throw new Error('useLoyalty must be used within an LoyaltyProvider');
  }

  return context;
}

export { LoyaltyProvider, useLoyalty };
