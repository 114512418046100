import styled, {css} from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import { MediaQuery } from 'octa-front-sdk';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

interface IPropsContainer {
  isCompact?: boolean;
}

export const Container = styled.section<IPropsContainer>`
  padding: 40px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;

  .list-header {
    background-color: initial;
    margin-bottom: 20px;
    padding: 0;

    h3 {
      color: ${Color.Grey900};
      font-size: ${FontSize.Big};
      font-weight: normal;
    }

    ${MediaQuery.BiggerThanSmall} {
      margin-bottom: 30px;
    }
  }

  ${({isCompact}) => isCompact ? css`
    ul {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      ${MediaQuery.BiggerThanSmall} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  ` : css`
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));

      ${MediaQuery.BiggerThanSmall} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  `}

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 8px;

    ${MediaQuery.BiggerThanSmall} {
      margin-bottom: 30px;
    }
  }
`;
