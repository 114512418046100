import styled, { css } from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import CheckboxStyles from 'octa-front-sdk/dist/components/Checkbox/styles';
import DividerStyles from 'octa-front-sdk/dist/components/Divider/styles';
import { MediaQuery } from 'octa-front-sdk';
import IconStyles from 'octa-front-sdk/dist/components/Icon/styles';

interface ISwitch {
  isPickup: boolean;
}

const PhoneWarning = styled.span`
  display: block;
  color: ${Color.Red500};
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.85rem;
`;

const HeaderSwitch = styled.span`
  background-color: #dcdce5;
  height: 52px;
  border-radius: 27px;
  border: 3px solid #dcdce5;
  display: grid;
  place-items: center;
`;

const Switch = styled.label<ISwitch>`
  display: flex;
  background-color: #dcdce5;
  height: 52px;
  border-radius: 27px;
  position: relative;
  border: 3px solid #dcdce5;

  > input {
    display: none;
  }

  .pickup-span,
  .delivery-span {
    transition: 0.2s;
  }

  .knob {
    z-index: 1;
    left: ${props => (props.isPickup ? '50%' : '0')};
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: inherit;
    background-color: ${Color.MeuDeliveryCyan};
    transition: 0.4s ease left;
  }

  .option {
    z-index: 2;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6a6180;
  }

  .option > img {
    margin-right: 0.4rem;
  }

  ${props =>
    props.isPickup
      ? css`
          .pickup-span {
            color: white;
            font-weight: 600;
          }

          .pickup-icon {
            filter: brightness(3.5);
          }
        `
      : css`
          .delivery-span {
            color: white;
            font-weight: 600;
          }

          .delivery-icon {
            filter: brightness(3.5);
          }
        `};
`;

const WarningText = styled.p`
  box-sizing: border-box;
  padding: 0.6rem;
  border: 1px solid ${Color.Red900};
  background-color: ${Color.Red200};
  color: ${Color.Red900};
  font-weight: bold;
  border-radius: 4px;
  width: 100%;
  font-size: ${FontSize.NormalX};
`;

const SuccessMessage = styled.h1`
  font-size: ${FontSize.NormalX};
  margin: 20px;
  text-align: center;

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Big};
  }
`;

const PaymentInfoRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0;

    & > span {
      font-weight: bold;
      color: ${Color.Green500};
    }
  }

  span {
    color: ${Color.Grey600};
  }
`;

const CustomMessage = styled.p`
  margin: 0 20px 20px 20px;
  text-align: center;
  font-size: ${FontSize.Normal};
  font-weight: lighter;
  color: ${Color.Grey700};
  line-height: 1.5rem;

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.NormalX};
    line-height: 1.8rem;
  }
`;

const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;

  &,
  ${ButtonContainer} {
    padding: 20px;
  }

  ${ButtonContainer} {
    font-size: ${FontSize.NormalX};
    border-radius: 12px;
  }

  ${IconStyles.Container} {
    width: 40%;
    margin: 0 auto 40px;
  }
`;

const Card = styled.div`
  margin: 0 20px 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${Color.Grey300};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(0, 0, 0, 0.16);

  & > h1 {
    margin-top: 0;
    font-size: ${FontSize.NormalX};
  }

  & > p {
    margin: 0 0 8px;
    color: ${Color.Grey600};

    &:last-of-type {
      margin-bottom: 20px;
    }
  }
`;

const RadioInputAddress = styled.li`
  margin: 0.8rem 0 0.6rem 0.8rem;
  font-size: ${FontSize.Normal};
  color: ${Color.BlueGrey600};

  > input {
    margin-right: 0.6rem;
  }
`;

const OrganizationAddress = styled.div`
  margin: 20px 1rem;

  > strong {
    color: #32264d;
  }

  > span {
    color: #6a6180;
  }
`;

const NeighbourhoodWarning = styled.div`
  margin-bottom: 20px;

  p:first-of-type {
    color: ${Color.Blue500};
  }
`;

const NeighbourhoodPicker = styled.select`
  width: 100%;
  border: 1px solid ${Color.Grey400};
  border-radius: 3px;
  color: ${Color.Grey700};
  background-color: transparent;
  padding: 0.6rem 0.4rem;
`;

const SectionTop = styled.div`
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: ${Color.Grey300};

  h1 {
    font-size: ${FontSize.NormalX};
    margin: 0;
    color: ${Color.Grey900};
  }
`;

const SectionContent = styled.div`
  padding: 0 20px;
  margin-bottom: 40px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ${DividerStyles.Container} {
    margin: 20px 0;
  }
`;

const Container = styled.div`
  & > h1 {
    margin: 0 20px 20px;
  }

  ${CheckboxStyles.Container} {
    margin-right: 10px;

    input[type='checkbox']:checked {
      background-color: ${Color.MeuDeliveryCyan};
    }
  }

  ${ButtonContainer} {
    margin-top: auto;
    margin-bottom: 20px;
  }
`;

const Icon = styled.img`
  margin-right: 8px;
  height: 24px;
  width: 24px;
  object-fit: contain;
`;

const PlaceOrderStepStyles = {
  PhoneWarning,
  HeaderSwitch,
  Switch,
  WarningText,
  SuccessMessage,
  PaymentInfoRow,
  CustomMessage,
  SuccessMessageContainer,
  Card,
  RadioInputAddress,
  OrganizationAddress,
  NeighbourhoodWarning,
  NeighbourhoodPicker,
  SectionTop,
  SectionContent,
  Container,
  Icon,
};

export default PlaceOrderStepStyles;
