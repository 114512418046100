import styled from 'styled-components';
import { MediaQuery } from 'octa-front-sdk';

const Title = styled.h1`
  margin-top: 0;

  &.centered {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ContentContainer = styled.div`
  margin-bottom: 130px;
  background-color: #f7f8f9;

  ${MediaQuery.BiggerThanSmall} {
    margin-bottom: 70px;
  }
`;

const Container = styled.div`
  padding: 30px 20px 20px 20px;
  flex: 1;
  max-width: calc(100% - 40px);

  ${MediaQuery.BiggerThanSmall} {
    padding-top: 120px;
    max-width: 800px;
    margin-bottom: initial;
  }
`;

const PageStyles = { Title, ContentContainer, Container };

export default PageStyles;
