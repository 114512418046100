import React, { ReactElement } from 'react';

import { formatPrice } from '../../../../Helpers';

import { useLoyalty } from '../../../../hooks/Loyalty';

import TrophyIcon from '../../../../images/icons/trophy.svg';
import LoyaltyContentStyles from './styles';

const LoyaltyContent = () => {
  const { loyaltyProgram, memberPoints } = useLoyalty();

  return (
    <LoyaltyContentStyles.Container>
      <LoyaltyContentStyles.Title>
        <LoyaltyContentStyles.IconSvg
          src={TrophyIcon}
          alt="Icone Programa de Fidelidade"
        />{' '}
        Programa de fidelidade
      </LoyaltyContentStyles.Title>

      {loyaltyProgram && (
        <>
          <LoyaltyContentStyles.Description>
            <p>Junte pontos e troque por produtos!</p>

            <p>
              A cada {formatPrice(loyaltyProgram.valuePerPoint)} você soma 1
              ponto.
            </p>

            {memberPoints && (
              <LoyaltyContentStyles.PointsPhrase>
                Você possui{' '}
                <strong>{`${memberPoints.cartPoints} pontos`}</strong>{' '}
                acumulados.
              </LoyaltyContentStyles.PointsPhrase>
            )}
          </LoyaltyContentStyles.Description>

          {loyaltyProgram.prizes.length > 0 && (
            <LoyaltyContentStyles.Products>
              <h2>Produtos disponíveis para troca</h2>

              <table>
                <tbody>
                  {loyaltyProgram.prizes.map(
                    (prize): ReactElement => {
                      const { productId, productName, priceInPoints } = prize;

                      return (
                        <tr key={productId}>
                          <td>{productName}</td>

                          <LoyaltyContentStyles.ProductsPrice>{`${priceInPoints} pontos`}</LoyaltyContentStyles.ProductsPrice>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </LoyaltyContentStyles.Products>
          )}
        </>
      )}

      <LoyaltyContentStyles.Obs>
        * Você começará a pontuar a partir do dia que criar sua senha.
      </LoyaltyContentStyles.Obs>

      <LoyaltyContentStyles.Obs>
        * A pontuação só é confirmada quando um pedido é finalizado no sistema.
      </LoyaltyContentStyles.Obs>

      <LoyaltyContentStyles.Obs>
        * O valor da taxa de entrega não conta para a pontuação.
      </LoyaltyContentStyles.Obs>
    </LoyaltyContentStyles.Container>
  );
};

export default LoyaltyContent;
