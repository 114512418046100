import Color from 'octa-front-sdk/dist/enums/Color';
import styled, { css } from 'styled-components';

export const Label = styled.label<{ hasError: boolean }>`
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: ${({ hasError }): string => (hasError ? Color.Red500 : Color.Grey600)};
  position: absolute;
  top: 14px;
  left: 16px;
  transition: all ease 300ms;
  z-index: 1;
  background-color: ${Color.White};
`;

export const Container = styled.div<{ hasError: boolean; hasValue: boolean }>`
  margin-bottom: 1rem;
  position: relative;
  transition: all ease 300ms;

  input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid
      ${({ hasError }): Color => (hasError ? Color.Red500 : Color.Grey400)};
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 5px;
    height: 48px;
    font-size: 1rem;
    outline: none;

    &::placeholder {
      color: ${Color.Grey400};
    }

    &:focus {
      border: 2px solid ${Color.MeuDeliveryCyan};
    }
  }

  .error {
    display: block;
    font-size: 0.75rem;
    color: ${Color.Red500};
    margin-left: 8px;
    margin-top: 4px;
    font-style: italic;
  }

  .hint {
    display: block;
    font-size: 0.75rem;
    color: ${Color.Grey500};
    margin-left: 8px;
    margin-top: 4px;
    font-style: italic;
  }

  ${({ hasValue }) =>
    hasValue &&
    css`
      ${Label} {
        top: -6px;
        left: 14px;
        font-size: 0.8rem;
      }
    `}

  &:focus-within ${Label} {
    top: -6px;
    left: 14px;
    font-size: 0.8rem;
  }
`;
