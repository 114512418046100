import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  withRouter,
  RouteComponentProps,
  matchPath,
  Link,
  useLocation,
} from 'react-router-dom';

import Button from 'octa-front-sdk/dist/components/Button';
import Color from 'octa-front-sdk/dist/enums/Color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faFileAlt,
  faMapMarkerAlt,
  faShoppingCart,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import IconName from 'octa-front-sdk/dist/enums/IconName';
import GlobalContext from '../../contexts/GlobalContext';
import LoginForm from '../LoginForm';
import OrganizationCard from '../../components/OrganizationCard';
import OrganizationContext from '../../contexts/OrganizationContext';
import TopNavStyles from './styles';
import Badge from '../Badge';

const TopNav = (props: RouteComponentProps) => {
  const { organizationInfo } = useContext(OrganizationContext);
  const { isLoggedIn, cartItems } = useContext(GlobalContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasScreenScrolled, setHasScreenScrolled] = useState(false);
  const { location, match } = props;
  const url = match.url.replace(/\/$/, '');
  const topOffset = 55;
  const { pathname } = useLocation();

  const navLinks = [
    { label: 'Carrinho', path: `${url}/carrinho`, icon: faShoppingCart },
    { label: 'Meus Pedidos', path: `${url}/pedidos`, icon: faFileAlt },
    {
      label: 'Meus Endereços',
      path: `${url}/perfil/enderecos`,
      icon: faMapMarkerAlt,
    },
    {
      label: 'Configurações',
      path: `${url}/perfil/configuracoes`,
      icon: faCog,
    },
    { label: 'Sair', path: `${url}/sair`, icon: faSignOutAlt },
  ];

  const isFixed = !!(
    organizationInfo &&
    organizationInfo.urlSuffix !== location.pathname.substring(1)
  );
  const shouldRenderOrgCard = !!(
    organizationInfo &&
    organizationInfo.urlSuffix !==
    location.pathname.substring(1).replace('/', '')
  );

  const buttonContent = isLoggedIn
    ? {
      text: 'Meu Perfil',
      icon: IconName.Person,
    }
    : {
      text: 'Entrar',
    };

  const scrollHandler = useCallback((): void => {
    const scrollPosition = document.documentElement.scrollTop;

    return scrollPosition > topOffset
      ? setHasScreenScrolled(true)
      : setHasScreenScrolled(false);
  }, [setHasScreenScrolled]);

  useEffect((): (() => void) => {
    document.addEventListener('scroll', scrollHandler);

    return (): void => document.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  const isAppleAndRegisterPage =
    process.env.REACT_APP_IS_APPLE && pathname.includes('/cadastrar');
  const isAppleAndLoginPage =
    process.env.REACT_APP_IS_APPLE && pathname.includes('/entrar');

  return (
    <TopNavStyles.Container
      isFixed={isFixed}
      hasScreenScrolled={hasScreenScrolled}
    >
      {shouldRenderOrgCard && <OrganizationCard />}

      <TopNavStyles.Content>
        {isAppleAndRegisterPage || isAppleAndLoginPage ? (
          <></>
        ) : (
          <>
            <Button
              text={buttonContent.text}
              iconName={buttonContent.icon}
              color={Color.MeuDeliveryCyan}
              onClick={(): void => setIsMenuOpen(!isMenuOpen)}
              shouldDebounce={false}
            />
            <TopNavStyles.Menu isMenuOpen={isMenuOpen}>
              <TopNavStyles.Arrow />

              {isLoggedIn ? (
                <TopNavStyles.LinksList>
                  {navLinks.map(
                    (navLink): ReactElement => {
                      const matchObj = matchPath(
                        location.pathname,
                        navLink.path,
                      );
                      const isActive =
                        navLink.path === match.url
                          ? !!matchObj && matchObj.isExact
                          : !!matchObj;

                      return (
                        <li
                          key={navLink.label}
                          onClick={(): void => setIsMenuOpen(false)}
                        >
                          <Link
                            className={isActive ? 'active' : ''}
                            to={navLink.path}
                          >
                            <FontAwesomeIcon icon={navLink.icon} />{' '}
                            {navLink.label}
                            {navLink.label === 'Carrinho' &&
                              cartItems.length > 0 && (
                                <Badge content={cartItems.length.toString()} />
                              )}
                          </Link>
                        </li>
                      );
                    },
                  )}
                </TopNavStyles.LinksList>
              ) : (
                <LoginForm onLogin={(): void => setIsMenuOpen(false)} />
              )}
            </TopNavStyles.Menu>
          </>
        )}
      </TopNavStyles.Content>
    </TopNavStyles.Container>
  );
};

export default withRouter(TopNav);
