import Color from "octa-front-sdk/dist/enums/Color";
import styled, { keyframes } from "styled-components";

const Ping = keyframes`
   75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const Container = styled.div`
position: relative;
display: flex;
align-items: center;
gap: 1rem;
text-align: left;

label {
  color: ${Color.Grey700}
}

input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid ${Color.Grey500};
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
  color: ${Color.Grey500};

  &:checked {
    border-color: ${Color.MeuDeliveryCyan}
  }
}

input:checked ~ label {
  color: ${Color.Grey800};
}

input::before {
  content: '';
  width: 0.75rem;
  height: 0.75rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: scale(0);
  border-radius: 50%;
  background-color: ${Color.MeuDeliveryCyan};
}

input:checked::before {
  transform: scale(100%);
}

input[type='radio'] ~ span {
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${Color.Grey500};
  top: 1.35rem;
}


input[type='radio']:checked ~ span {
  border-radius: 50%;
  animation: ${Ping} 1s cubic-bezier(0, 0, 0.2, 1) forwards;
  background-color: ${Color.Grey300};
}
`;
