import {useContext, useRef, useState} from "react";

import {Form} from "@unform/web";
import Input from "../../components/Input";
import BtnWithLoad from "../../components/BtnWithLoad";

import {Container, Warning} from "./styles";
import RequestHandler from "octa-front-sdk/dist/RequestHandler";
import {useHistory} from "react-router-dom";
import OrganizationContext from "../../contexts/OrganizationContext";
import GlobalContext from "../../contexts/GlobalContext";
import {FormHandles} from "@unform/core";
import {handleApiErrors} from "../../utils/handleApiErrors";

interface IForm {
  password: string;
}

export function DeleteAccountPage() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const {organizationInfo} = useContext(OrganizationContext);
  const {setToastMessage, isLoggedIn} = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(data: IForm) {
    formRef.current?.setErrors({});

    if (!data.password) {
      return formRef.current?.setErrors({password: 'Senha é obrigatória'});
    }

    try {
      setIsLoading(true);

      await RequestHandler.delete("user", {
        data,
      });

      setToastMessage('Conta apagada com sucesso!');
      history.push(`/${organizationInfo?.urlSuffix}/sair`);

    } catch (err) {
      const errorArr = handleApiErrors(err);

      if(errorArr.length > 0) {
        return setToastMessage(errorArr[0]);
      }

      setToastMessage('Houve algum erro ao apagar a conta, tente novamente!');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <h1>Deseja apagar sua conta?</h1>

      <Warning>
        <p>Tem certeza que deseja apagar sua conta? Essa acão é <strong>irreversivel</strong>, <strong>todos</strong> os
          seus dados e seus pontos no programa de fidelidade serão perdidos!</p>
      </Warning>

      {isLoggedIn && (
        <>
          <p>
            Para continuar digite a senha da sua conta abaixo:
          </p>

          <Form ref={formRef} onSubmit={data => handleSubmit(data)} placeholder={''}>
            <Input name={"password"} label={'Senha'} type={'password'} />
            <BtnWithLoad loading={isLoading} type='submit' name={'Apagar conta'} />
          </Form>
        </>
      )}

      {!isLoggedIn && (
        <p>
          Para continuar acesse o <a href={'https://docs.google.com/forms/d/e/1FAIpQLScluTxaEsr-xsfcl3v9922L6_zyOzaKRdi63qyxs1T5skUd1w/viewform'}>formulário de exclusão de conta ⇱</a>.
        </p>
      )}
    </Container>
  )
}
