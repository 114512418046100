// @ts-nocheck
import React, { ReactElement, useState, useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Form from 'octa-front-sdk/dist/components/Form';
import Input from 'octa-front-sdk/dist/components/Input';
import Button from 'octa-front-sdk/dist/components/Button';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import InputType from 'octa-front-sdk/dist/enums/InputType';
import FormContext from 'octa-front-sdk/dist/contexts/FormContext';
import Color from 'octa-front-sdk/dist/enums/Color';
import {
  maskPhoneNumber,
  cleanPhoneNumber,
} from 'octa-front-sdk';

import Loader from '../../components/Loader';
import Page from '../../components/Page';
import RenderOrgCustomInput, {
  IRenderCustomInputRef,
} from '../../components/RenderOrgCustomInput';

import GlobalContext from '../../contexts/GlobalContext';
import OrganizationContext from '../../contexts/OrganizationContext';

import Auth from '../../services/Auth';
import { validatePhone } from '../../utils/validatePhone';

import SignUpFacebookPageStyles from './styles';

const SignupFacebook = (): ReactElement => {
  const customInputRef = useRef<IRenderCustomInputRef>(null);
  const history = useHistory();

  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { organizationInfo } = useContext(OrganizationContext);
  const {
    setLoggedIn,
    triggerRedirect,
    setToastMessage,
    setGuestUUID,
    tokenFacebook,
  } = useContext(GlobalContext);

  const shouldPromote = !!state && state.shouldPromote;

  function handleRegisterUserFacebook(values): void {
    setLoading(true);

    const customInputsApiData = customInputRef.current?.getValues();

    const { phone } = values;

    if (!customInputsApiData)
      return setToastMessage('Preencha todos os campos corretamente!');

    Auth.registerWithFacebook({
      token: tokenFacebook,
      phone: cleanPhoneNumber(phone.toString()),
      data: customInputsApiData,
    })
      .then((): void => {
        setLoggedIn(true);

        triggerRedirect();

        if (shouldPromote) {
          localStorage.removeItem('guestUUID');

          setGuestUUID(undefined);
        }

        setToastMessage('Cadastro realizado!');

        if (organizationInfo) history.push(`/${organizationInfo.urlSuffix}`);
      })
      .finally((): void => setLoading(false));
  }

  return (
    <Page title="Cadastrar" shouldCenterTitle>
      {loading && <Loader />}

      <SignUpFacebookPageStyles.Container>
        <Form
          onSubmit={handleRegisterUserFacebook}
          initialValues={{ phone: '' }}
        >
          <Input
            name="phone"
            label="Telefone"
            type={InputType.Telephone}
            validator={validatePhone}
            mask={maskPhoneNumber}
          />

          <RenderOrgCustomInput ref={customInputRef} />

          <FormContext.Consumer>
            {({ hasErrors }): ReactElement => (
              <Button
                text="Entrar"
                isDisabled={hasErrors}
                color={Color.MeuDeliveryCyan}
              />
            )}
          </FormContext.Consumer>
        </Form>
      </SignUpFacebookPageStyles.Container>
    </Page>
  );
};

export default SignupFacebook;
