// @ts-nocheck

import {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import Button from 'octa-front-sdk/dist/components/Button';
import Color from 'octa-front-sdk/dist/enums/Color';
import { useHistory, useRouteMatch, useLocation, Link } from 'react-router-dom';
import Wizard from 'octa-front-sdk/dist/components/Wizard';

import Page from '../../components/Page';
import GlobalContext from '../../contexts/GlobalContext';
import CartPageStyles from './styles';
import { formatPrice } from '../../Helpers';
import OrganizationContext from '../../contexts/OrganizationContext';
import CartItemStep from '../../components/Dishes/CartItemStep';
import { Product } from '../../types/Product';
import { CartItem } from '../../types/CartItem';
import AddressStep from '../../components/Steps/AddressStep';
import PlaceOrderStep from '../../components/Steps/PlaceOrderStep';

import { useLoyalty } from '../../hooks/Loyalty';
import { usePopup } from '../../components/Popup/context';

const CartPage = () => {
  const { cartItems, setCartItems, setChosenAddress } = useContext(
    GlobalContext,
  );
  const { isAvailable, organizationInfo } = useContext(OrganizationContext);
  const { isConfirmed } = usePopup();

  const { setMemberPoints, memberPoints } = useLoyalty();

  const [isWizardOpen, setWizardOpen] = useState();
  const history = useHistory();
  const { state } = useLocation();
  const match = useRouteMatch();

  const placeOrderStep = (
    <PlaceOrderStep
      shouldRenderIndicator={false}
      shouldRenderBackButton={false}
      shouldHideBottomWaves
    />
  );

  const addressStep = (
    <AddressStep
      shouldRenderIndicator={false}
      shouldHideBottomWaves
      onSave={(newAddress): void => {
        if (newAddress) {
          setChosenAddress(newAddress);
        }
      }}
      shouldGoBackOnSave
    />
  );
  const [steps, setSteps] = useState([placeOrderStep, addressStep]);

  useEffect((): void => {
    if (state && state.isWizardOpen) {
      setWizardOpen(state.isWizardOpen);
    }
  }, [state]);

  const openCartItemStep = useCallback(
    (currentDish: Partial<Product>, cartItem: CartItem): void => {
      setSteps([
        <CartItemStep
          shouldRenderIndicator={false}
          shouldRenderBackButton={false}
          shouldHideBottomWaves
          currentDish={currentDish}
          cartItem={cartItem}
        />,
      ]);

      setWizardOpen(true);
    },
    [setSteps, setWizardOpen],
  );

  const ClearCart = async (): void => {
    const confirmation = await isConfirmed(
      'Confirmar remoção de todos os itens do carrinho?',
    );

    if (confirmation) {
      setCartItems([]);

      if (memberPoints) {
        const newPoints = {
          validPoints: memberPoints.validPoints,
          pendingPoints: memberPoints.pendingPoints,
          cartPoints: memberPoints.validPoints,
        };

        setMemberPoints(newPoints);
      }
    }
  };

  return (
    <>
      <Page title="Carrinho">
        {cartItems.length > 0 ? (
          <CartPageStyles.Container>
            <CartPageStyles.CartList>
              {cartItems.map(
                (cI, i): ReactElement => {
                  const { amount, totalPrice, product, extras, flavors } = cI;
                  const extrasCount = extras.size;
                  const hasBadge = extras.size > 0;
                  const { name, description, pictureUrl } = product;

                  return (
                    <>
                      <CartPageStyles.CartItem
                        key={i}
                        onClick={(): void => openCartItemStep(product, cI)}
                      >
                        <div>
                          <CartPageStyles.Name>{`x${amount} ${name}`}</CartPageStyles.Name>

                          <CartPageStyles.Description>
                            {description}
                          </CartPageStyles.Description>

                          {flavors.size > 0 &&
                            [...flavors.values()].map(
                              (item, i): ReactElement => {
                                return (
                                  <CartPageStyles.Flavor key={i}>
                                    {item.name}
                                  </CartPageStyles.Flavor>
                                );
                              },
                            )}

                          {totalPrice && (
                            <CartPageStyles.Price>
                              {formatPrice(totalPrice)}
                            </CartPageStyles.Price>
                          )}
                        </div>

                        <CartPageStyles.PictureAndBadgeContainer>
                          {pictureUrl && (
                            <CartPageStyles.Picture
                              src={pictureUrl}
                              alt={name}
                              hasBadge={hasBadge}
                            />
                          )}

                          {hasBadge && (
                            <CartPageStyles.ExtrasBadge>
                              {`${extrasCount} adiciona${extrasCount === 1 ? 'l' : 'is'
                                }`}
                            </CartPageStyles.ExtrasBadge>
                          )}
                        </CartPageStyles.PictureAndBadgeContainer>
                      </CartPageStyles.CartItem>
                      {product.redeemed && product.forbidsDiscountCoupon && (
                        <CartPageStyles.WarningMsg>
                          <img
                            src="https://img.icons8.com/color/48/000000/error.png"
                            alt="Atenção"
                          />
                          O item acima é inválido para cupons
                        </CartPageStyles.WarningMsg>
                      )}
                    </>
                  );
                },
              )}
            </CartPageStyles.CartList>

            <Button
              text={
                isAvailable ? 'Fazer pedido' : 'Não estamos aceitando pedidos'
              }
              color={Color.MeuDeliveryCyan}
              onClick={(): void => {
                setSteps([placeOrderStep, addressStep]);

                setWizardOpen(true);
              }}
              isDisabled={!isAvailable}
            />

            <Button
              text="Esvaziar carrinho"
              color={Color.Red500}
              onClick={ClearCart}
            />

            <Link to={`/${match.url.split('/')[1]}`}>Continuar comprando</Link>
          </CartPageStyles.Container>
        ) : (
          <div>
            <p>Seu carrinho está vazio.</p>

            <Button
              text="Ver produtos."
              color={Color.MeuDeliveryCyan}
              onClick={(): void => history.push(`/${match.url.split('/')[1]}`)}
            />
          </div>
        )}
      </Page>

      <Wizard
        shouldRender={isWizardOpen}
        closeWizard={(): void => setWizardOpen(false)}
        steps={steps}
      />
    </>
  );
};

export default CartPage;
