import React, { ReactElement, useState } from 'react';
import {
  faTimesCircle,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import Color from 'octa-front-sdk/dist/enums/Color';
import ImageFullscreenStyle from './styles';

import BtnIcon from '../BtnIcon';

interface IProps {
  imgArr?: { index: number; url: string }[];
  imgUrl?: string;
  currentImg?: number;
  handleClose: () => void;
}

const ImageFullscreen = (props: IProps): ReactElement | null => {
  const { imgArr, imgUrl, currentImg, handleClose } = props;

  const imgArray = imgArr || [];

  if (imgUrl) imgArray.push({ index: 1, url: imgUrl });

  const photosArray = imgArray.filter(item => {
    return item && item.url;
  });

  const [currentImgFs, setCurrentImgFs] = useState(currentImg || 0);

  function nextPic() {
    if (currentImgFs === imgArray.length - 1) return setCurrentImgFs(0);

    setCurrentImgFs(currentImgFs + 1);
  }

  function backPic() {
    if (currentImgFs === 0) return setCurrentImgFs(imgArray.length - 1);

    setCurrentImgFs(currentImgFs - 1);
  }

  function onCLose() {
    handleClose && handleClose();
  }

  if (photosArray.length === 0) return <></>;

  // TODO: add animation with motion framer
  return (
    <ImageFullscreenStyle.Container>
      <ImageFullscreenStyle.Overlay onClick={onCLose} />
      <BtnIcon
        className="btn-close"
        onClick={onCLose}
        size="2x"
        icon={faTimesCircle}
        color={Color.White}
      />
      {photosArray.length > 1 && (
        <BtnIcon
          className="btn-left"
          onClick={backPic}
          size="2x"
          icon={faArrowAltCircleLeft}
          color={Color.White}
        />
      )}
      {photosArray.length > 1 && (
        <BtnIcon
          className="btn-right"
          onClick={nextPic}
          size="2x"
          icon={faArrowAltCircleRight}
          color={Color.White}
        />
      )}
      {photosArray.map((img, index) => (
        <ImageFullscreenStyle.Image
          key={img.index}
          src={img.url}
          showImg={index === currentImgFs}
          alt="Banner Fullscreen"
        />
      ))}
    </ImageFullscreenStyle.Container>
  );
};

export default ImageFullscreen;
