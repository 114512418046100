import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10% 0;
  box-sizing: border-box;
  max-height: 100vh;

  h1 {
    color: ${Color.Grey900};
    margin: 0 0 30px;
    text-align: center;
  }

  h2 {
    color: ${Color.Grey700};
    margin: 30px;
    text-align: center;
    font-weight: normal;
    font-size: ${FontSize.SmallX};
  }

  ${MediaQuery.BiggerThanMedium} {
    h1 {
      font-size: ${FontSize.Huge};
    }

    h2 {
      font-size: ${FontSize.Normal};
    }
  }
`;

const Picture = styled.img`
  width: 50%;

  ${MediaQuery.SmallerThanMedium} {
    width: 75%;
  }
`;

const NotFoundStyles = { Container, Picture };

export default NotFoundStyles;
