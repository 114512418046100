import styled, { css, keyframes } from 'styled-components';

interface IProps {
  variant: 'primary' | 'secondary';
}

const SpinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.button<IProps>`
  border-radius: 5px;
  width: 100%;
  border: none;
  padding: 12px;
  font-size: 1rem;
  cursor: pointer;
  display: grid;
  place-items: center;

  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: #14C1DF;
          color: #ffffff;
          border: 1px solid #14C1DF;
        `
      case 'secondary':
        return css`
          background-color: #ffffff;
          color: #14C1DF;
          border: 1px solid #14C1DF;
        `
    }
  }}

  > div > svg {
    animation: ${SpinAnimation} 3s linear infinite;
  }
`;
