import { Button, Container, Count } from './styles';

interface IProps {
  count?: number;
  setCount: (count: number) => unknown;
  minimum?: number;
  maximum?: number;
  shouldAlwaysShowDecrement?: boolean;
}

const Counter = (props: IProps) => {
  const {
    count = 0,
    setCount,
    minimum = 0,
    maximum,
    shouldAlwaysShowDecrement,
  } = props;

  return (
    <Container>
      {(count >= 1 || shouldAlwaysShowDecrement) && (
        <>
          <Button
            onClick={(): void => {
              setCount(Math.max(count - 1, minimum));
            }}
            disabled={count <= minimum}
          >
            -
          </Button>

          <Count>{count}</Count>
        </>
      )}

      <Button
        onClick={(): void => {
          setCount(count + 1);
        }}
        disabled={maximum !== undefined && count >= maximum}
      >
        +
      </Button>
    </Container>
  );
};

export default Counter;
