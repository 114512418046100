import React, { useContext } from 'react';
import FilteredViewStyles from './styles';

import { Product } from '../../../types/Product';
import { Prize } from '../../../types/Prize';

import { useLoyalty } from '../../../hooks/Loyalty';

import DishCard from '../../../components/Dishes/DishCard';

interface IProps {
  filteredArray: Product[];
  openWizard: () => unknown;
  setCurrentDish: React.Dispatch<
    React.SetStateAction<Partial<Product> | undefined>
  >;
}

const FilteredView = ({
  filteredArray,
  openWizard,
  setCurrentDish,
}: IProps) => {
  const { loyaltyProgram, memberPoints } = useLoyalty();

  return (
    <FilteredViewStyles.Container>
      <FilteredViewStyles.Title>
        {filteredArray.length !== 0
          ? `${filteredArray.length} Itens encontrados`
          : 'Nenhum item encontrado!'}
      </FilteredViewStyles.Title>
      <FilteredViewStyles.ListContent>
        {filteredArray.map(dish => {
          const { id } = dish;

          let prizeIndex;
          let hasPoints = false;

          if (!!loyaltyProgram && !!memberPoints) {
            prizeIndex = loyaltyProgram.prizes.findIndex(
              (prize: Prize): boolean => parseInt(prize.productId) === id,
            );

            if (prizeIndex > -1) {
              hasPoints =
                memberPoints.validPoints >=
                loyaltyProgram.prizes[prizeIndex].priceInPoints;
            }
          }

          return (
            <div key={dish.id}>
              <DishCard
                onClickCard={(): void => {
                  setCurrentDish({ ...dish });

                  openWizard();
                }}
                dish={dish}
                hasPoints={hasPoints}
              />
            </div>
          );
        })}
      </FilteredViewStyles.ListContent>
    </FilteredViewStyles.Container>
  );
};

export default FilteredView;
