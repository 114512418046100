import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import BadgeStyles from '../Badge/styles';

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${Color.White};
  top: -8px;
  right: 15px;
`;

const Menu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  padding: 25px;
  margin: 45px 15px 45px auto;
  right: 0;
  display: ${({ isMenuOpen }): string => (isMenuOpen ? 'block' : 'none')};
  width: max-content;
  background-color: ${Color.White};
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(255, 255, 255, 0.16);
`;

const LinksList = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: auto;
  list-style: none;

  li {
    padding: 10px;
    position: relative;

    &:last-of-type {
      padding-right: 0;
    }

    a {
      font-size: ${FontSize.NormalX};
      text-decoration: none;
      color: ${Color.Grey500};

      &:hover,
      &.active {
        color: ${Color.MeuDeliveryCyan};
      }

      svg {
        margin-right: 5px;
      }

      ${BadgeStyles.Container} {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: 15px;
  margin: 0;
  margin-left: auto;
  display: flex;
  height: 35px;

  ${ButtonContainer} {
    min-width: 80px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
      0px -1px 5px -5px rgba(255, 255, 255, 0.16);
  }
`;

const Container = styled.div<{ isFixed: boolean; hasScreenScrolled: boolean }>`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: ${({ isFixed }): string => (isFixed ? 'fixed' : 'absolute')};
  top: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ hasScreenScrolled }): string =>
    hasScreenScrolled ? Color.White : 'transparent'};
  box-shadow: ${({ hasScreenScrolled }): string =>
    hasScreenScrolled
      ? '0px 3px 5px 0px rgba(0, 0, 0, 0.16), 0px -1px 5px -5px rgba(255, 255, 255, 0.16)'
      : 'none'};
  z-index: 9;

  a {
    text-decoration: none;
  }

  h1 {
    font-weight: normal;
    font-size: ${FontSize.Big};
    color: ${Color.Grey600};
  }

  ${MediaQuery.BiggerThanSmall} {
    display: flex;
  }
`;

const TopNavStyles = { Arrow, Menu, LinksList, Content, Container };

export default TopNavStyles;
