import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const Logo = styled.img<{ hasScrolled: boolean }>`
  width: ${({ hasScrolled }): string => (hasScrolled ? '45px' : '70px')};
  min-width: ${({ hasScrolled }): string => (hasScrolled ? '45px' : '70px')};
  height: ${({ hasScrolled }): string => (hasScrolled ? '45px' : '70px')};
  align-self: center;
  margin-right: 10px;
  border-radius: 10px;
  background-color: ${Color.White};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(0, 0, 0, 0.16);
`;

const Name = styled.h1`
  align-self: center;
  font-size: ${FontSize.Big};
`;

const Back = styled.span<{ hasScrolled: boolean }>`
  display: ${({ hasScrolled }): string => (hasScrolled ? 'none' : 'block')};
  align-self: flex-end;
  font-size: ${FontSize.Small};
  color: ${Color.Grey500};
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div<{ hasScrolled: boolean }>`
  background-color: ${({ hasScrolled }): string =>
    hasScrolled ? 'transparent' : Color.White};
  border-radius: ${({ hasScrolled }): string =>
    hasScrolled ? 'none' : '10px'};
  display: flex;
  align-content: center;
  margin: 5px auto;
  padding: ${({ hasScrolled }): string => (hasScrolled ? '0' : '10px')};
  box-shadow: ${({ hasScrolled }): string =>
    hasScrolled
      ? 'none'
      : '0px 3px 5px 0px rgba(0, 0, 0, 0.16), 0px -1px 5px -5px rgba(255, 255, 255, 0.16)'};
`;

const Container = styled.div`
  margin: 0 auto;
  width: 300px;
`;

const OrganizationCardStyles = {
  Logo,
  Name,
  Back,
  TextContainer,
  Content,
  Container,
};

export default OrganizationCardStyles;
