import { useEffect, useContext } from 'react';
import Color from 'octa-front-sdk/dist/enums/Color';
import LoaderStyles from './styles';
import OrganizationContext from '../../contexts/OrganizationContext';

const Loader = () => {
  const { organizationInfo } = useContext(OrganizationContext);

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';

    return (): void => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  let color = Color.MeuDeliveryCyan;

  if (organizationInfo && organizationInfo.headerColor) {
    color = organizationInfo.headerColor;
  }

  const logoUrl = organizationInfo ? organizationInfo.logoUrl : '';

  return (
    <LoaderStyles.Container>
      <LoaderStyles.Background companyColor={color} />

      {logoUrl ? (
        <LoaderStyles.Logo borderColor={color} src={logoUrl} alt="logo" />
      ) : (
        <p>Carregando...</p>
      )}
    </LoaderStyles.Container>
  );
};

export default Loader;
