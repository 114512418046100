import styled from 'styled-components';

export const WarningContent = styled.div`
  width: 70vw;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  background: #edfff6;
  border: 2px solid #a1e9c5;
  text-align: center;

  div {
    display: flex;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
    }

    h1 {
      color: #37c77f;
      font-weight: 400;
      margin: 0 0 0 0.5rem;
    }
  }

  p {
    color: #39cc83;
    font-size: 1.15rem;
  }

  button {
    text-transform: uppercase;
    transition: 0.2s;
    background: #37c77f;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    width: 100%;
    height: 52px;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }

  @media (min-width: 425px) {
    width: 400px;

    div img {
      width: 96px;
      height: 96px;
    }
  } ;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 500;
  display: grid;
  place-items: center;
`;
