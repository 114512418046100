import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';

interface ImageIProps {
  showImg: boolean;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Color.BlueGrey900};
  opacity: 0.6;
  backdrop-filter: blur(5px);
  z-index: 210;
`;

const Image = styled.img<ImageIProps>`
  width: 100%;
  max-width: 60vw;
  border-radius: 0.6rem;
  z-index: 220;
  display: ${props => (props.showImg ? 'block' : 'none')};
  background-color: ${Color.BlueGrey100};

  @media (min-width: 1024px) {
    width: 30rem;
    height: 30rem;
  }
`;

const Container = styled.div`
  transition: width 0.4s, height 0.4s;
  transition-delay: 0.3s;
  transition-timing-function: ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
  align-items: center;

  > .btn-close {
    z-index: 220;
    position: absolute;
    top: 2rem;
    right: 1rem;
  }

  > .btn-right {
    z-index: 230;
    position: absolute;
    right: 1rem;
  }

  > .btn-left {
    z-index: 230;
    position: absolute;
    left: 1rem;
  }

  @media (min-width: 1024px) {
    > .btn-close {
      right: 2rem;
    }

    > .btn-right {
      right: 2rem;
    }

    > .btn-left {
      left: 2rem;
    }
  }
`;

const ImageFullscreenStyle = {
  Overlay,
  Image,
  Container,
};

export default ImageFullscreenStyle;
