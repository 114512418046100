import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import DividerStyles from 'octa-front-sdk/dist/components/Divider/styles';

const PhoneWarning = styled.span`
  display: block;
  color: ${Color.Red500};
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.85rem;
`;

const LoginCall = styled.p`
  margin-top: 15px;
  color: ${Color.Grey600};
  text-align: center;

  a {
    text-decoration: none;
    color: ${Color.MeuDeliveryCyan};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 400px;

  ${DividerStyles.Container} {
    margin: 20px 0;
  }
`;

const SignUpPageStyles = { PhoneWarning, LoginCall, Container };

export default SignUpPageStyles;
