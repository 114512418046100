import React, { ReactElement, useEffect, useContext } from 'react';
import Button from 'octa-front-sdk/dist/components/Button';
import Color from 'octa-front-sdk/dist/enums/Color';
import { useHistory, useRouteMatch } from 'react-router-dom';
import IconName from 'octa-front-sdk/dist/enums/IconName';
import GlobalContext from '../../contexts/GlobalContext';
import AuthOverlayStyles from './styles';

interface IProps {
  closeOverlay: () => void;
  startCheckoutAsGuest: () => void;
}

const AuthOverlay = (props: IProps) => {
  const { closeOverlay, startCheckoutAsGuest } = props;
  const history = useHistory();
  const url = useRouteMatch().url.split('/')[1];
  const { setRedirectTarget } = useContext(GlobalContext);

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';

    return (): void => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const sendToAuth = (): void => {
    setRedirectTarget({
      pathname: `/${url}/carrinho`,
      state: { isWizardOpen: true },
    });

    history.push(`/${url}/perfil/entrar`);

    window.scrollTo(0, 0);
  };

  return (
    <AuthOverlayStyles.Container>
      <AuthOverlayStyles.CloseButton onClick={closeOverlay}>
        X
      </AuthOverlayStyles.CloseButton>

      <h1>Fazer seu pedido é rápido e prático!</h1>

      <AuthOverlayStyles.ButtonsWrapper>
        <Button
          text="Tenho cadastro, quero entrar"
          color={Color.MeuDeliveryBlue}
          iconName={IconName.PersonFilled}
          onClick={(): void => {
            sendToAuth();
          }}
        />

        <Button
          text="Apenas fazer meu pedido"
          color={Color.MeuDeliveryCyan}
          iconName={IconName.CartFilled}
          onClick={(): void => {
            startCheckoutAsGuest();
          }}
        />
      </AuthOverlayStyles.ButtonsWrapper>
    </AuthOverlayStyles.Container>
  );
};

export default AuthOverlay;
