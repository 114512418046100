import React, { ReactElement, useContext } from 'react';
import dayjs from "dayjs";
import isBetweenPlugin from 'dayjs/plugin/isBetween';


import { CgPin } from 'react-icons/cg';
import { FaCircle, FaClock } from 'react-icons/fa';
import { BsTrophy } from 'react-icons/bs';
import { FiCreditCard } from 'react-icons/fi';
import { MdArrowForwardIos } from 'react-icons/md';

import DayOfTheWeek from 'octa-front-sdk/dist/enums/DayOfTheWeek';

import GlobalContext from '../../../contexts/GlobalContext';
import OrganizationContext from '../../../contexts/OrganizationContext';

import { useLoyalty } from '../../../hooks/Loyalty';

import ModalButton from './ModalButton';
import LoyaltyContent from './LoyaltyContent';
import PaymentContent from './PaymentContent';
import DeliveryContent from './DeliveryContent';
import WorkHoursContent from './WorkHoursContent';
import RadiusDeliveryContent from './RadiusDeliveryContent';

import {
  Logo,
  StatusLabel,
  Name,
  Description,
  ContentColumn,
  Content,
  Background,
  Container,
  Modals,
  ModalsLeft,
  ModalsRight,
  ModalContainer,
} from './styles';

dayjs.extend(isBetweenPlugin);

function OrgBanner() {
  const { organizationInfo } = useContext(OrganizationContext);
  const { isLoggedIn } = useContext(GlobalContext);
  const { loyaltyProgram, memberPoints } = useLoyalty();

  const shouldRenderLoyaltyData = !!loyaltyProgram && loyaltyProgram.active;
  const isOpen = !!organizationInfo && organizationInfo.isInWorkHour;

  let statusLabel = 'Fechado';

  const isWorkHourToday = () => {
    const currentDay = new Date();
    const weekday = Object.keys(DayOfTheWeek)[currentDay.getDay()];

    if (organizationInfo && !organizationInfo.alwaysOpen) {
      const hasWorkHours = organizationInfo.workHours.filter(
        wh => wh.dayOfWeek === weekday,
      );

      if (hasWorkHours.length > 0) {
        const checkIfIsCurrentWorkHour = hasWorkHours.filter((wh) => {
          const today = dayjs().format('YYYY-MM-DD');

          const isBetween = dayjs().isBetween(dayjs(`${today} ${wh.start}`), dayjs(`${today} ${wh.end}`));

          if(isBetween) return wh
        })


        return {
          timeEnd: checkIfIsCurrentWorkHour[0].end.substring(0, 5),
        };
      }
    }
  };

  if (isOpen) {
    statusLabel =
      !!organizationInfo && organizationInfo.alwaysOpen
        ? 'Peça agora'
        : `Aberto até as ${isWorkHourToday()?.timeEnd}`;
  }

  const hasLoyaltyProgram = !!loyaltyProgram && !!memberPoints;
  const hasCartPoints = `${memberPoints && memberPoints.cartPoints} pontos`;

  return (
    <Container>
      {organizationInfo && (
        <>
          <Background
            color={organizationInfo.headerColor}
            headerUrl={organizationInfo.headerUrl}
          />
          <Content>
            <div className="content-header">
              <Logo src={organizationInfo.logoUrl} />

              <ContentColumn>
                <Name>{organizationInfo.name}</Name>
                <Description>{organizationInfo.description}</Description>
              </ContentColumn>
            </div>

            {organizationInfo && (
              <Modals>
                <ModalsLeft shouldRenderLoyaltyData={shouldRenderLoyaltyData}>
                  <ModalContainer>
                    <FaCircle
                      color={isOpen ? '#4caf50' : '#f44336'}
                      size={12}
                    />
                    <ModalButton label="" modalContent={<DeliveryContent />}>
                      <StatusLabel isOpen={isOpen}>{statusLabel}</StatusLabel>
                    </ModalButton>
                  </ModalContainer>

                  {!organizationInfo.alwaysOpen && (
                    <ModalContainer>
                      <FaClock />
                      <ModalButton
                        label="Horários"
                        modalContent={<WorkHoursContent />}
                      />
                    </ModalContainer>
                  )}

                  <ModalContainer>
                    <FiCreditCard />
                    <ModalButton
                      label="Formas de pagamento"
                      modalContent={<PaymentContent />}
                    />
                  </ModalContainer>

                  <ModalContainer>
                    <CgPin />
                    <ModalButton
                      label="Área de Entrega"
                      modalContent={<RadiusDeliveryContent />}
                    />
                  </ModalContainer>
                </ModalsLeft>

                {shouldRenderLoyaltyData && (
                  <ModalsRight
                    shouldRenderLoyaltyData={shouldRenderLoyaltyData}
                  >
                    <ModalButton label="" modalContent={<LoyaltyContent />}>
                      <div className="modals-r">
                        <p>Fidelidade</p>
                        <span>
                          <BsTrophy size={20} />
                          {isLoggedIn && hasLoyaltyProgram
                            ? hasCartPoints
                            : '0 pontos'}
                        </span>
                      </div>
                    </ModalButton>
                  </ModalsRight>
                )}
              </Modals>
            )}
          </Content>
        </>
      )}
    </Container>
  );
};

export default OrgBanner;
