export const validateEmail = (value: string): string | void => {
  value = value.trim();

  if (value && value.length > 0) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return 'Email inválido.';
    }
  } else {
    return 'Email é obrigatório.';
  }
};
