import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
  }

  input {
    margin: 8px 0 16px 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 1rem;

    min-height: 40px;
    padding-left: 10px;
  }
`;
