import { ReactElement } from 'react';
import BadgeStyles from './styles';

interface IProps {
  content: string;
}

const Badge = (props: IProps): ReactElement => {
  const { content } = props;

  return <BadgeStyles.Container>{content}</BadgeStyles.Container>;
};

export default Badge;
