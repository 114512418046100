import styled from 'styled-components';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import RadioStyles from 'octa-front-sdk/dist/components/Input/Radio/styles';
import Color from 'octa-front-sdk/dist/enums/Color';

const AddressList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    padding: 20px 0;

    &:not(:first-of-type) {
      border-top: 1px solid ${Color.Grey300};
    }

    label {
      margin-bottom: 0;
    }

    ${RadioStyles.InnerCircle} {
      fill: ${Color.MeuDeliveryCyan};
    }

    span {
      flex: 1;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 15px;

      &,
      & > strong {
        color: ${Color.Grey700};
      }

      & > strong {
        text-transform: uppercase;
      }

      &.active,
      &.active > strong {
        color: ${Color.Grey900};
      }
    }
  }
`;

const Container = styled.div`
  ${ButtonContainer} {
    margin-top: 30px;
  }
`;

const AddressesPageStyles = { AddressList, Container };

export default AddressesPageStyles;
