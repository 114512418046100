import styled from 'styled-components';
import { MediaQuery } from 'octa-front-sdk';
import PageStyles from '../../components/Page/styles';

export const Grid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  div {
    grid-column: span 1 / span 1;
  }

  .dishes {
    display: block;
  }

  .placeholder {
    display: none;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr minmax(800px, 800px) 1fr;

    .placeholder {
      display: block;
    }
  }
`;


const Container = styled.div`
  width: 100%;

  ${PageStyles.Container} {
    max-width: unset;
    padding: unset;

    ${MediaQuery.BiggerThanSmall} {
      max-width: unset;
      padding-top: 90px;
    }
  }
`;

const DishesPageStyles = { Container };

export default DishesPageStyles;
