import React, { ButtonHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Container } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: 'primary' | 'secondary';
  name: string;
}

function BtnWithLoad({ loading = false, variant = 'primary', name, ...rest }: IProps) {
  return (
    <Container variant={variant} {...rest}>
      {!loading ? name :
        <div>
          <FontAwesomeIcon icon={faSpinner} size="lg" />
        </div>}
    </Container>
  );
};

export default BtnWithLoad;
