import { InputHTMLAttributes, ReactElement } from 'react';

import { Container } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactElement;
  name: string;
}

export function Radio({ label, name, id, ...rest }: IProps) {
  return (
    <Container>
      <input id={id} name={name} type="radio" {...rest} />
      <span />
      <label className="ml-7 text-slate-500" htmlFor={id}>
        {label}
      </label>
    </Container>
  );
}
