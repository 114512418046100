import styled from 'styled-components';
import { MediaQuery, transformColor } from 'octa-front-sdk';
import Color from 'octa-front-sdk/dist/enums/Color';

const Logo = styled.img<{ borderColor: Color }>`
  width: 90px;
  height: 90px;
  border-radius: 10px;
  border: 2px solid
    ${({ borderColor }): string => transformColor(borderColor, 0.7)};

  ${MediaQuery.BiggerThanSmall} {
    width: 180px;
    height: 180px;
  }
`;

const Background = styled.div<{ companyColor: Color }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    45deg,
    ${({ companyColor }): Color => companyColor},
    ${({ companyColor }): string => transformColor(companyColor, 1.5)}
  );
  background-size: 250% 250%;
  animation: gradient 1s ease infinite;
  opacity: 0.95;

  @keyframes gradient {
    0% {
      background-position: 0% 75%;
    }
    50% {
      background-position: 100% 75%;
    }
    100% {
      background-position: 0% 75%;
    }
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const LoaderStyles = { Logo, Background, Container };

export default LoaderStyles;
