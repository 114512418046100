import styled from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

const Title = styled.h1`
  color: #32264d;
  font-weight: normal;
  font-size: ${FontSize.Normal};
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  ${MediaQuery.BiggerThanMedium} {
    font-size: ${FontSize.Big};
  }
`;

const Description = styled.section`
  margin-bottom: 3rem;
  > p {
    margin: 0.4rem 0;
    color: #6a6180;
  }
`;

const PointsPhrase = styled.p`
  margin: 1rem 0;
`;

const IconSvg = styled.img`
  width: 1.6rem;
  margin: 0 0.4rem;
`;

const Products = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  color: #6a6180;

  > h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: ${FontSize.NormalX};
    color: #32264d;
  }

  > table {
    width: 100%;
  }
`;

const ProductsPrice = styled.td`
  text-align: right;
`;

const Obs = styled.p`
  font-size: ${FontSize.SmallX};
  width: 100%;
  color: #c1bccc;
  margin: 0;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    margin: 0;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-bottom: 20px;
    }
  }
`;

const LoyaltyContentStyles = {
  Title,
  IconSvg,
  Description,
  PointsPhrase,
  Products,
  ProductsPrice,
  Obs,
  Container,
};

export default LoyaltyContentStyles;
