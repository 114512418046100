import {formatWorkHours} from "../../utils/formatWorkHoursArr";
import React, {useContext} from "react";
import OrganizationContext from "../../contexts/OrganizationContext";
import DayOfTheWeek from "octa-front-sdk/dist/enums/DayOfTheWeek";
import {translateDayOfTheWeek} from "../../Helpers";

import {Container} from "./styles";

interface IProps {
  darkMode?: boolean;
}

export function WorkDaysList({darkMode = false}: IProps) {
  const {organizationInfo} = useContext(OrganizationContext);
  const arr = formatWorkHours(organizationInfo?.workHours || [])

  return(
    <Container isDarkMode={darkMode}>
      <ul>
        {Object.values(DayOfTheWeek).map((dW, i) => {
          const indexOfDay = arr.findIndex((wh) => wh.dayOfWeek === dW);
          const translatedDay = translateDayOfTheWeek(DayOfTheWeek[dW]);

          if (indexOfDay === -1) {
            return (
              <li key={i}>
                <span>
                  <strong>{`${translatedDay}: `}</strong>
                </span>
                <span>Fechado</span>
              </li>
            );
          }

          return (
            <li key={dW}>
                  <span>
                    <strong>{`${translatedDay}: `}</strong>
                  </span>

              <span>{arr[indexOfDay].workHours}</span>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}
