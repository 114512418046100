import { ReactElement, useContext } from 'react';

import OrganizationContext from '../../contexts/OrganizationContext';
import { WorkDaysList } from "../WorkDaysList";

import logoLevilo from '../../assets/levilo-primary-white.png';

import {
  Container,
  FooterTop,
  WorkHours,
  PaymentsMethods,
  FooterBottom,
  FooterBottomAddress,
  FooterBottomDevLevilo,
  FooterMiddle,
  FooterMiddleItem,
} from './styles';

const Footer = () => {
  const { organizationInfo } = useContext(OrganizationContext);

  const { paymentMethods } = organizationInfo!;

  const orgAddressFormatted = `
    ${organizationInfo?.address.street}, ${organizationInfo?.address.number}.
    ${organizationInfo?.address.neighbourhood} -
    ${organizationInfo?.address.city} - ${organizationInfo?.address.state}
  `;

  return (
    <Container>
      {organizationInfo && (
        <div className="main">
          <FooterTop>
            <WorkHours>
              <h2>Horário de funcionamento</h2>

              {!organizationInfo.alwaysOpen ? (
                <WorkDaysList darkMode />) : (
                <li>Loja sempre aberta</li>
              )}
            </WorkHours>

            <PaymentsMethods>
              <h2>Formas de Pagamento</h2>
              <ul>
                {paymentMethods.map(
                  ({ name, id }): ReactElement => {
                    return <li key={id}>{name}</li>;
                  },
                )}
              </ul>
            </PaymentsMethods>
          </FooterTop>

          {organizationInfo.phone2 && (
            <FooterMiddle>
              <FooterMiddleItem>
                Nome:
                <br />
                {organizationInfo.name}
              </FooterMiddleItem>
              <FooterMiddleItem>
                Telefone:
                <br />
                {organizationInfo.phone}
              </FooterMiddleItem>
              <FooterMiddleItem>
                CNPJ:
                <br />
                {organizationInfo.phone2}
              </FooterMiddleItem>
            </FooterMiddle>
          )}

          <FooterBottom>
            {organizationInfo.allowStorePickup && (
              <FooterBottomAddress>
                <p>{organizationInfo.name}</p>
                <p>{orgAddressFormatted}</p>
              </FooterBottomAddress>
            )}

            <FooterBottomDevLevilo>
              <span>V1 Desenvolvido por:</span>
              <a
                href="https://levilo.com.br/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={logoLevilo} alt="Levilo" />
              </a>
            </FooterBottomDevLevilo>
          </FooterBottom>
        </div>
      )}
    </Container>
  );
};

export default Footer;
