import { weekDays } from 'octa-front-sdk';
import DayOfTheWeek from 'octa-front-sdk/dist/enums/DayOfTheWeek';
import { CartItem } from "./types/CartItem";
import { ProductOrder } from "./types/Order";

// TODO: validate wording
export const orderStatuses = (isDelivery?: boolean): string[] => {
  return [
    'Pedido recebido',
    isDelivery ? 'Pedido sendo separado' : 'Sendo preparado',
    'Rejeitado',
    'Pronto para retirada',
    'Saiu para entrega',
    isDelivery ? 'Pedido entregue' : 'Finalizado',
  ];
};

export const formatPrice = (price: number): string =>
  `R$ ${(price || 0).toFixed(2).replace('.', ',')}`;

export const formatPhoneNumber = (number: string): string => {
  const cleaned = number.replace(/\D/g, '');
  const isMobileNumber = cleaned.length === 11;

  const matched = isMobileNumber
    ? cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    : cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

  if (matched) return `(${matched[1]}) ${matched[2]}-${matched[3]}`;

  return '';
};

export const translateDayOfTheWeek = (dayOfTheWeek: DayOfTheWeek): string =>
  weekDays[
  Object.keys(DayOfTheWeek).findIndex((d): boolean => d === dayOfTheWeek)
  ];

export const calcCartItemPrice = (cartItem: CartItem): number => {
  const extras = Array.from(cartItem.extras.values()).reduce((sum, { amount, price }) => sum + (amount * price), 0);
  return cartItem.redeemed ? extras : cartItem.totalPrice;
}

export const calcOrderItemPrice = (product: ProductOrder): number => {
  const extras = product.extras?.reduce((sum, { amount, price }) => sum + ((amount || 0) * price), 0) || 0;
  const finalPrice = (product.productPrice + extras) * product.amount;

  return product.redeemed ? extras * product.amount : finalPrice;
}
