import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import { transformColor, MediaQuery } from 'octa-front-sdk';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const CartBar = styled.div`
  display: flex;
  width: 100vw;
  justify-content: space-around;
  line-height: 2rem;
  color: ${Color.White};

  span {
    color: ${Color.White};
    text-transform: uppercase;
    font-size: ${FontSize.Small};
  }

  ${MediaQuery.BiggerThanSmall} {
    justify-content: center;

    span {
      margin: auto 30px;
      font-size: ${FontSize.Normal};
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 576px;
  min-height: 70px;
  position: fixed;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to bottom right,
    ${transformColor(Color.MeuDeliveryCyan, 1)},
    ${transformColor(Color.MeuDeliveryCyan, 0.8)}
  );
  cursor: pointer;
`;

const PasswordRegisterStyles = { CartBar, Container };

export default PasswordRegisterStyles;
