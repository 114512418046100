import React, { createContext, useContext, useEffect, useState } from 'react';

export interface ScrollContextData {
  scrollTarget: string;
  setScrollTarget: React.Dispatch<React.SetStateAction<string>>;
}

interface IProps {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

const ScrollContext = createContext<ScrollContextData>({} as ScrollContextData);

const ScrollProvider = ({ children, scrollContainerRef }: IProps) => {
  const [scrollTarget, setScrollTarget] = useState('');

  useEffect(() => {
    if (scrollTarget) {
      const children = scrollContainerRef.current?.children;

      if (children) {
        const targetChild = children.namedItem(scrollTarget);

        if (targetChild) {
          //HACK: Remove scroll animation as it makes the scroll behaviour inconsistently on android
          targetChild.scrollIntoView(true);
          setScrollTarget('');
        }
      }
    }
  }, [scrollContainerRef, scrollTarget]);

  return (
    <ScrollContext.Provider value={{ scrollTarget, setScrollTarget }}>
      {children}
    </ScrollContext.Provider>
  );
};

function useScroll(): ScrollContextData {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error('useScroll must be used within an ScrollProvider');
  }

  return context;
}

export { ScrollProvider, useScroll };
