import { parseQueryString } from "octa-front-sdk";
import RequestHandler from "octa-front-sdk/dist/RequestHandler";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom"
import GlobalContext from "../../contexts/GlobalContext";
import { Order } from "../../types/Order";
import { MdContentCopy } from "react-icons/md"

import { formatPrice } from '../../Helpers';

import { Container, CopyButton, Error, PixWarning, QrCodeImg, TutorialList } from "./styles";
import OrganizationContext from "../../contexts/OrganizationContext";

export function PixPaymentPage() {
  const location = useLocation();
  const history = useHistory();
  const orderId = parseQueryString(location.search).orderId;

  const { setLoading, setToastMessage } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  const [order, setOrder] = useState<Order | undefined>();
  const [looping, setLooping] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getOrderDetails() {
      if (!orderId) {
        return setError('Id do pedido não informado');
      }

      setLoading(true);

      try {
        const res = await RequestHandler.get(`user/orders/${orderId}`)

        setOrder(res.data);
      } catch (error) {
        setToastMessage('Erro ao buscar detalhes do pedido');
      } finally {
        setLoading(false);
      }
    }

    getOrderDetails();
  }, []);

  useEffect(() => {
    async function getOrderStatus() {
      const res = await RequestHandler.get(`user/orders/${orderId}/status`)

      if (res.data.isPaid) {
        history.push(
          `/${organizationInfo?.urlSuffix}/pedidos?detalhes=${orderId}`
        );
      }

      setLooping(val => !val);
    };

    if (orderId) setTimeout(getOrderStatus, 30 * 1000)
  });

  function copyToClipboard(key?: string) {
    navigator.clipboard.writeText(key || '');
    setToastMessage('Chave copiada com sucesso');
  }

  return (
    <div>
      <h1>Pagamento com pix</h1>

      <Container>
        {error && <Error>{error}</Error>}
        {!order || !order.onlinePayment?.pix && (
          <Error>Nenhum dado do PIX encontrado</Error>
        )}
        {!error && order?.onlinePayment?.pix && (
          <>
            <section>
              <h2>Pague {formatPrice(order.totalValue)} via Pix</h2>
              <QrCodeImg src={`data: image / png; base64, ${order?.onlinePayment?.pix.qrCodeImage} `} alt="QRCode para pagamento pix" />
              <CopyButton type="button" onClick={() => copyToClipboard(order?.onlinePayment?.pix?.qrCode)}>
                <span>{order?.onlinePayment?.pix?.qrCode}</span>
                <div>
                  <MdContentCopy size='1.25rem' />
                </div>
              </CopyButton>
              <h2>Como pagar?</h2>
              <TutorialList>
                <li>
                  <div><span>1</span></div>
                  <p>Entre no app ou site do seu banco e escolha a opção de pagamento via Pix.</p>
                </li>
                <li>
                  <div><span>2</span></div>
                  <p>Escaneie o código QR ou copie e cole o código de pagamento.</p>
                </li>
                <li>
                  <div><span>3</span></div>
                  <p>Pronto! O pagamento será creditado na hora e você receberá um e-mail de confirmação.</p>
                </li>
              </TutorialList>
            </section>
            <PixWarning>O Pix tem um limite diário de transferências. Para mais informações, por favor, consulte seu banco.</PixWarning>
          </>
        )}
      </Container>
    </div>
  );
}
