
import BallPagerStyles from './styles';

interface IProps {
  numberOfBalls: number;
  dark?: boolean;
  activated: number;
}

const BallPager = ({ numberOfBalls, dark, activated }: IProps) => {
  const balls = numberOfBalls ? Array.from(Array(numberOfBalls).keys()) : [];

  return (
    <BallPagerStyles.Container>
      {balls.map(ball => (
        <BallPagerStyles.Ball
          dark={dark}
          key={ball}
          activated={activated === ball}
        />
      ))}
    </BallPagerStyles.Container>
  );
};

export default BallPager;
