import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  border: none;
  color: ${Color.Grey700};
  background: ${Color.Grey300};
  border-radius: 100%;
  height: 30px;
  width: 30px;
`;

const ButtonsWrapper = styled.div`
  width: 80%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${ButtonContainer} {
    height: 70px;
    font-size: ${FontSize.SmallX};

    &:first-of-type {
      margin-bottom: 50px;
    }

    ${MediaQuery.BiggerThanSmall} {
      font-size: ${FontSize.Normal};
    }
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;

  h1 {
    color: white;
    text-align: center;
    margin: 0 20px 70px;
  }
`;

const AuthOverlayStyles = { CloseButton, ButtonsWrapper, Container };

export default AuthOverlayStyles;
