import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import IconStyles from 'octa-front-sdk/dist/components/Icon/styles';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk/dist/main';
import BadgeStyles from '../Badge/styles';

const LinksList = styled.ul`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;

  li > a {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: relative;

    span {
      font-size: ${FontSize.SmallX};
    }

    span,
    ${IconStyles.Container}>use {
      color: ${Color.Grey500};
    }

    ${BadgeStyles.Container} {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.active {
      span,
      ${IconStyles.Container}>use {
        color: ${Color.MeuDeliveryCyan};
      }
    }
  }
`;

const Container = styled.div`
  background-color: ${Color.White};
  height: 60px;
  width: 100%;
  border-top: 1px solid ${Color.Grey300};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  position: fixed;
  bottom: 0;

  ${MediaQuery.BiggerThanSmall} {
    display: none;
  }
`;

const BottomNavStyles = { LinksList, Container };

export default BottomNavStyles;
