import {
  ReactElement,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Wizard from 'octa-front-sdk/dist/components/Wizard';
import OrderPlacerStyles from './styles';
import OrganizationContext from '../../contexts/OrganizationContext';
import GlobalContext from '../../contexts/GlobalContext';
import AuthOverlay from '../AuthOverlay';
import { formatPrice, calcCartItemPrice } from '../../Helpers';
import PlaceOrderStep from '../Steps/PlaceOrderStep';
import AddressStep from '../Steps/AddressStep';

const OrderPlacer = () => {
  const [isTakingOrders, setIsTakingOrders] = useState<boolean | undefined>(
    false,
  );
  const { isLoggedIn, cartItems, setChosenAddress } = useContext(GlobalContext);
  const { organizationInfo, isAvailable } = useContext(OrganizationContext);
  const [shouldRenderAuthOverlay, setShouldRenderAuthOverlay] = useState(false);
  const [total, setTotal] = useState(0);
  const [shouldAllowOrder, setShouldAllowOrder] = useState<boolean | undefined>(
    false,
  );
  const [isWizardOpen, setWizardOpen] = useState(false);
  const placeOrderStep = (
    // @ts-ignore
    <PlaceOrderStep
      shouldRenderIndicator={false}
      shouldRenderBackButton={false}
      shouldHideBottomWaves
    />
  );
  const addressStep = (
    // @ts-ignore
    <AddressStep
      shouldRenderIndicator={false}
      shouldHideBottomWaves
      onSave={(newAddress): void => {
        if (newAddress) {
          setChosenAddress(newAddress);
        }
      }}
      shouldGoBackOnSave
    />
  );

  const [steps] = useState([placeOrderStep, addressStep]);

  useEffect((): void => {
    setIsTakingOrders(
      organizationInfo && organizationInfo.isInWorkHour && isAvailable,
    );
  }, [organizationInfo, isAvailable]);

  useEffect((): void => {
    const total = cartItems.reduce((sum, current): number => sum + calcCartItemPrice(current), 0);

    setTotal(total);
  }, [cartItems]);

  useEffect((): void => {
    setShouldAllowOrder(
      isTakingOrders &&
      organizationInfo &&
      total >= organizationInfo.minimumOrderValue,
    );
  }, [setShouldAllowOrder, isTakingOrders, organizationInfo, total]);

  const getContent = useCallback((): ReactElement => {
    if (!isTakingOrders) return <span>Não estamos aceitando pedidos</span>;

    if (organizationInfo && total < organizationInfo.minimumOrderValue) {
      return (
        <span>
          Valor mínimo para pedidos:{' '}
          {formatPrice(organizationInfo.minimumOrderValue)}
        </span>
      );
    }

    return (
      <OrderPlacerStyles.CartBar>
        <div>
          <FontAwesomeIcon icon={faShoppingCart} /> Ver Carrinho
        </div>

        <div>
          <span>{cartItems.length}</span> {formatPrice(total)}
        </div>
      </OrderPlacerStyles.CartBar>
    );
  }, [organizationInfo, isTakingOrders, total, cartItems]);

  const clickHandler = useCallback((): void => {
    if (shouldAllowOrder) {
      if (isLoggedIn) {
        setWizardOpen(true);
      } else {
        setShouldRenderAuthOverlay(true);
      }
    }
  }, [isLoggedIn, shouldAllowOrder]);

  return (
    <>
      {shouldRenderAuthOverlay ? (
        <AuthOverlay
          closeOverlay={(): void => setShouldRenderAuthOverlay(false)}
          startCheckoutAsGuest={(): void => {
            setShouldRenderAuthOverlay(false);

            setWizardOpen(true);
          }}
        />
      ) : (
        <OrderPlacerStyles.Container
          isDisabled={!shouldAllowOrder}
          onClick={clickHandler}
        >
          <span>{getContent()}</span>
        </OrderPlacerStyles.Container>
      )}

      <Wizard
        shouldRender={isWizardOpen}
        closeWizard={(): void => setWizardOpen(false)}
        steps={steps}
      />
    </>
  );
};

export default OrderPlacer;
