import styled, { css } from 'styled-components';

const FULLSCREEN_STYLES = css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const PopupContainer = styled.div`
  z-index: 60;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  width: 100%;
  max-width: 15rem;

  .popup-actions {
    display: flex;
    justify-content: flex-end;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition-duration: 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }

    .confirmation-btn {
      font-weight: bold;
      color: #23999e;
    }

    .cancel-btn {
      color: #94a3b8;
    }
  }
`;

export const Overlay = styled.button`
  ${FULLSCREEN_STYLES}
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
`;

export const Container = styled.div`
  ${FULLSCREEN_STYLES}
  display: grid;
  place-items: center;
  z-index: 40;
  padding: 1rem;
  box-sizing: border-box;
`;
