import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import { cleanPhoneNumber } from 'octa-front-sdk';
import { AxiosResponse } from 'axios';
import { IOrgCustomInput } from '../types/OrgCustomInput';

type SignUpPayload = {
  userName: string;
  firstName: string;
  phone: string;
  password: string;
  email: string;
  data?: Array<Omit<IOrgCustomInput, 'valueMask' | 'required'>>;
};

type FacebookSignUpPayload = {
  phone: string;
  token: string;
  data?: Array<Omit<IOrgCustomInput, 'valueMask' | 'required'>>;
};

type SimplifiedSignUpPayload = {
  userName: string;
  phone: string;
  firstName: string;
  data?: Array<Omit<IOrgCustomInput, 'valueMask' | 'required'>>;
};

type SimplifiedPromotionPayload = SimplifiedSignUpPayload & {
  simplifiedUserName: string;
  password: string;
  email: string;
  data?: Array<Omit<IOrgCustomInput, 'valueMask' | 'required'>>;
};

type SimplifiedFacebookPromotionPayload = {
  simplifiedUserName: string;
  token: string;
  phone: string;
  data?: Array<Omit<IOrgCustomInput, 'valueMask' | 'required'>>;
};

const register = (payload: SignUpPayload): Promise<AxiosResponse> => {
  return RequestHandler.post('auth/register', payload).then(
    (response): AxiosResponse => {
      const { data } = response;

      Object.keys(data).map((key): void =>
        localStorage.setItem(key, data[key]),
      );

      return response;
    },
  );
};

const registerWithFacebook = (
  payload: FacebookSignUpPayload,
): Promise<AxiosResponse> => {
  const { phone, token, data } = payload;

  return RequestHandler.post('auth/register/facebook', {
    phone,
    token,
    data,
  }).then(
    (response): AxiosResponse => {
      const { data } = response;

      Object.keys(data).map((key): void =>
        localStorage.setItem(key, data[key]),
      );

      return response;
    },
  );
};

const registerAsGuest = (
  payload: SimplifiedSignUpPayload,
): Promise<Response | void> => {
  const { userName, firstName, phone } = payload;

  return RequestHandler.post('auth/simplified/register', {
    ...payload,
    phone: cleanPhoneNumber(phone),
  }).then((response): void => {
    const {
      accessToken,
      accessTokenValidTo,
      refreshToken,
      refreshTokenValidTo,
    } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('accessTokenValidTo', accessTokenValidTo);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('refreshTokenValidTo', refreshTokenValidTo);
    localStorage.setItem('firstName', firstName);
    localStorage.setItem('phone', phone);
    localStorage.setItem('guestUUID', userName);
  });
};

const promoteGuest = (
  payload: SimplifiedPromotionPayload,
): Promise<AxiosResponse> => {
  return RequestHandler.post('auth/simplified/promote', payload).then(
    (response): AxiosResponse => {
      Object.keys(response.data).map((key): void =>
        localStorage.setItem(key, response.data[key]),
      );

      return response;
    },
  );
};

const promoteGuestUsingFacebook = (
  payload: SimplifiedFacebookPromotionPayload,
): Promise<AxiosResponse> => {
  return RequestHandler.post('auth/simplified/promote/facebook', payload).then(
    (response): AxiosResponse => {
      Object.keys(response.data).map((key): void =>
        localStorage.setItem(key, response.data[key]),
      );

      return response;
    },
  );
};

const logout = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('accessTokenValidTo');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenValidTo');
  localStorage.removeItem('chosenAddress');
  localStorage.removeItem('guestUUID');
  localStorage.removeItem('firstName');
  localStorage.removeItem('phone');
};

export default {
  register,
  registerWithFacebook,
  registerAsGuest,
  promoteGuest,
  promoteGuestUsingFacebook,
  logout,
};
