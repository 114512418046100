import {WorkHour} from "octa-front-sdk";
import DayOfTheWeek from "octa-front-sdk/dist/enums/DayOfTheWeek";

interface ISimpleWorkHour {
  dayOfWeek: DayOfTheWeek;
  workHours: string;
}

export function formatWorkHours(data: WorkHour[]) {
  const arr = data.sort((a, b) => {
    const daysOfTheWeek = Object.keys(DayOfTheWeek);
    const dayIndexA = daysOfTheWeek.indexOf(a.dayOfWeek);
    const dayIndexB = daysOfTheWeek.indexOf(b.dayOfWeek);

    if (dayIndexA < dayIndexB) return -1;

    if (dayIndexA > dayIndexB) return 1;

    return 0;
  });

  return arr.reduce((acc, curr) => {
    const daysExist = acc.findIndex((item) => item.dayOfWeek === curr.dayOfWeek);

    if (daysExist > -1) {
      const existent = acc[daysExist];

      acc.splice(daysExist, 1, {
        ...existent,
        workHours: `${existent.workHours} | ${curr.start.substring(0, 5)} - ${curr.end.substring(0, 5)}`,
      });
    } else {
      acc.push({
        dayOfWeek: curr.dayOfWeek,
        workHours: `${curr.start.substring(0, 5)} - ${curr.end.substring(0, 5)}`,
      });
    }

    return acc;
  }, [] as ISimpleWorkHour[]);
}
