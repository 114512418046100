import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';

interface IProps {
  dark?: boolean;
  activated?: boolean;
}

const Ball = styled.div<IProps>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${props => (props.dark ? Color.BlueGrey900 : 'white')};
  opacity: ${props => (props.activated ? '1' : '0.5')};
  margin: 0 3px;
`;

const Container = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: center;
`;

const BallPagerStyles = {
  Ball,
  Container,
};

export default BallPagerStyles;
