import styled from 'styled-components';
import DividerStyles from 'octa-front-sdk/dist/components/Divider/styles';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const SignUpCall = styled.p`
  margin-top: 30px;
  color: ${Color.Grey600};
  text-align: center;

  a {
    text-decoration: none;
    color: ${Color.MeuDeliveryCyan};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 400px;

  .forgot-password {
    &,
    &:visited {
      color: ${Color.Grey500};
      font-size: ${FontSize.SmallX};
      text-align: center;
      display: block;
      margin-bottom: 20px;
    }
  }

  ${DividerStyles.Container} {
    margin: 20px 0;
  }
`;

const LoginPageStyles = { SignUpCall, Container };

export default LoginPageStyles;
