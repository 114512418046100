import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';

export const AddresNicknameContainer = styled.div`
h2{
  font-size: 1.15rem;
  font-weight: normal;
  margin-bottom: 1rem;
  color: ${Color.Grey600};
}

.btn-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;

  button {
    width: 100%;
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #fff;
    border: 1px solid ${Color.Grey300};
    color: ${Color.Grey500};
    border-radius: 8px;
    transition-duration: 200ms;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .active {
    border: 1px solid ${Color.MeuDeliveryCyan};
    background-color: ${Color.MeuDeliveryCyan};
    color: #fff;
  }
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 95%;

  @media screen and (min-width: 480px) {
    .input-inline {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 8px;
    }

    .input-inline-reverse {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 8px;
    }
  }
`;

export const ContainerAddressStep = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ContainerHeaderH1 = styled.h1`
  color: ${Color.Grey700};
  font-size: 28px;

  @media screen and (max-width: 480px) {
    font-size: 21px;
  }
`;

export const ContainerHeaderBtnPinMap = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  border-radius: 8px;
  background-color: ${Color.Grey200};
  border: 1px solid ${Color.Grey300};
  color: ${Color.Grey700};
  font-weight: bold;
  padding: 24px;

  cursor: pointer;

  img {
    margin-right: 16px;
    width: 25px;
    color: ${Color.Grey100};
  }

  &:hover {
    background-color: ${Color.Grey400};
    color: #fff;
  }

  &:active {
    scale: 0.98;
  }
`;

export const ContainerHeaderSeparator = styled.div`
  font-size: 14px;
  color: #a8a8b3;
  margin: 18px 0;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    flex: 1;
    height: 1px;
    background: #a8a8b3;
    margin-right: 16px;
  }

  &::after {
    content: '';
    flex: 1;
    height: 1px;
    background: #a8a8b3;
    margin-left: 16px;
  }
`;

export const BtnWrapper = styled.div`
  margin-top: 16px;
  display: block;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  button {
    margin: 0.5rem 0;
  }

  .delete-btn {
    background-color: #fff;
    margin-top: 16px;
    color: ${Color.Red500};
    border: 1px solid ${Color.Red500};
  }
`;

export const BtnCancel = styled.button`
  margin-top: 16px;
  background-color: transparent;
  color: ${Color.Red500};
  border: none;
  cursor: pointer;

  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    scale: 0.98;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ContainerNotFound = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ContainerNotFoundHeader = styled.div`
  align-items: center;
  text-align: center;
  margin: 0 40px;

  color: ${Color.Grey800};
`;

export const ContainerNotFoundHeaderH1 = styled.h1`
  font-size: 28px;
  font-weight: bold;
`;

export const ContainerNotFoundHeaderSpan = styled.span`
  color: ${Color.Grey700};
  line-height: 1.5rem;
`;

export const ContainerNotFoundAddress = styled.div`
  text-align: center;
  border: 1px solid ${Color.Grey400};
  border-radius: 4px;
  background-color: ${Color.Grey100};
  margin-bottom: 16px;
`;

export const ContainerNotFoundAddressStreet = styled.p`
  font-weight: bold;
  font-size: 18px;

  color: ${Color.Grey700};
`;

export const ContainerNotFoundAddressNeighborhood = styled.p`
  font-size: 16px;
  font-weight: 400;

  color: ${Color.Grey700};
`;

export const ContainerNotFoundFooter = styled.div`
  text-align: center;
`;

export const ContainerNotFoundFooterSpan = styled.span`
  color: ${Color.Grey800};
  display: flex;
  line-height: 1.5rem;
  padding-bottom: 8px;
`;

export const ContainerNotFoundFooterWhatsButton = styled.button`
  display: flex;
  justify-content: center;

  text-decoration: none;
  width: 100%;
  background-color: ${Color.Green300};
  color: #fff;
  border: none;
  padding: 16px;
  margin-top: 16px;
  border-radius: 4px;

  cursor: pointer;

  img {
    width: 20px;
    margin-right: 16px;
  }

  &:hover {
    background-color: ${Color.Green100};
    color: ${Color.Green500};
  }
`;
