import { useEffect, useState } from 'react';

import SyncIcon from '../../images/icons/sync-96.png';

import { Container, WarningContent } from './styles';

const VersionWarning = () => {
  const [showPopup, setShowPopup] = useState(false);

  const getGUID = async () => {
    const idToAvoidCache = +new Date();
    const file = await fetch(
      `${process.env.REACT_APP_AZURE_STORAGE_VERSION}version.txt?v=${idToAvoidCache}`,
    );

    const GUID = await file.text();

    return GUID;
  };

  useEffect(() => {
    const checkGUID = async () => {
      const GUID = await getGUID();

      const currentVersionGUID = localStorage.getItem(
        '@PedidoOnline:versionGUID',
      );

      if (currentVersionGUID === GUID || !currentVersionGUID) {
        localStorage.setItem('@PedidoOnline:versionGUID', GUID);

        return;
      }

      setShowPopup(true);
    };

    checkGUID();
  }, []);

  const handlePageLoad = async () => {
    const GUID = await getGUID();
    localStorage.setItem('@PedidoOnline:versionGUID', GUID);

    window.location.replace(window.location.href);
  };

  if (!showPopup) return <></>;

  return (
    <Container>
      <WarningContent>
        <div>
          <img src={SyncIcon} alt="Refresh" />
          <h1>Nova versão do site disponível!</h1>
        </div>
        <p>
          Temos uma nova versão do site para melhorar sua experiência de compra!
        </p>
        <button type="button" onClick={handlePageLoad}>
          Usar Nova Versão
        </button>
      </WarningContent>
    </Container>
  );
};

export default VersionWarning;
