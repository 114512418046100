import {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getErrorResponseFirstMessage } from 'octa-front-sdk';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import { AxiosError, AxiosResponse } from 'axios';
import Toast from 'octa-front-sdk/dist/components/Toast';
import Configs from 'octa-front-sdk/dist/Configs';
import TopNav from '../TopNav';
import BottomNav from '../BottomNav';
import CartPage from '../../pages/cart';
import OrdersPage from '../../pages/orders';
import ProfilePage from '../../pages/profile';
import LoginPage from '../../pages/login';
import SignUpPage from '../../pages/signup';
import AppRouterStyles from './styles';
import AddressesPage from '../../pages/addresses';
import LogoutPage from '../../pages/logout';
import GlobalContext from '../../contexts/GlobalContext';
import Loader from '../Loader';
import DishesPage from '../../pages/dishes';
import { CartItem } from '../../types/CartItem';
import { ProductCustomization } from '../../types/ProductCustomization';
import OrganizationContext from '../../contexts/OrganizationContext';
import NotFound from '../NotFound';
import PasswordRecoveryPage from '../../pages/passwordRecovery';
import { HistoryElement } from '../../types/HistoryElement';
import SignupFacebook from '../../pages/signupFacebook';

import { IOrgCustomInput } from '../../types/OrgCustomInput';

import { Address } from '../../controllers/AddressController';

import { LoyaltyProvider } from '../../hooks/Loyalty';
import { PixPaymentPage } from '../../pages/pixPayment';
import { DeleteAccountPage } from "../../pages/deleteAccount";
import { ConfigsPage } from "../../pages/configs";

interface PaymentModifier {
  id: number;
  modifier: number;
  modifierType: string;
}

const AppRouter = (props: RouteComponentProps) => {
  const { match, location, history } = props;

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [guestUUID, setGuestUUID] = useState('');
  const [paymentModifier, setPaymentModifier] = useState<PaymentModifier>();
  const [tokenFacebook, setTokenFacebook] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [chosenAddress, setChosenAddress] = useState<Address>();
  const [cartItems, setCartItems] = useState<(CartItem & { amount: number })[]>(
    [],
  );
  const [orgCustomInputs, setOrgCustomInputs] = useState<
    Array<IOrgCustomInput>
  >([]);
  const { organizationInfo } = useContext(OrganizationContext);
  const [redirectTarget, setRedirectTarget] = useState<HistoryElement>();

  // TODO: fix redirect on SDK
  // TODO: remove isLoggedIn checks
  const triggerRedirect = useCallback((): void => {
    const destination = redirectTarget;

    if (destination) history.push(destination);

    setRedirectTarget(undefined);
  }, [history, redirectTarget]);

  useEffect(() => {
    RequestHandler.get('/auth/userdata').then(response =>
      setOrgCustomInputs(response.data),
    );
  }, []);

  useEffect((): void => {
    const url = match.url.replace(/\/$/, '');
    const accessToken = localStorage.getItem('accessToken');
    const accessTokenValidTo = localStorage.getItem('accessTokenValidTo');
    const guestUUID = localStorage.getItem('guestUUID');

    if (!accessToken && accessTokenValidTo) {
      return history.push(`${url}/sair`);
    }

    setLoggedIn(!!accessToken);

    if (guestUUID) {
      setGuestUUID(guestUUID);
    }
  }, [history, match.url]);

  useEffect((): void => {
    if (organizationInfo) {
      const storedItems = localStorage.getItem(organizationInfo.urlSuffix);

      if (storedItems) {
        const cartItems = JSON.parse(storedItems);
        const normalizedCartItems = cartItems.map(
          (
            cI: CartItem & { extras: ProductCustomization[] },
            index: number,
          ): CartItem => {
            const extras = new Map();
            const flavors = new Map();

            cI.extras.forEach((e): void => {
              extras.set(e.id, { ...e });
            });
            cI.flavors.forEach((f): void => {
              flavors.set(f.id, { ...f });
            });

            return { ...cI, extras, flavors, id: cI.id ? cI.id : index + 1 };
          },
        );

        setCartItems(normalizedCartItems);
      }
    }
  }, [organizationInfo]);

  useEffect((): void => {
    if (organizationInfo) {
      localStorage.setItem(
        organizationInfo.urlSuffix,
        JSON.stringify(
          cartItems.map(
            (cI): Record<string, any> => ({
              ...cI,
              extras: [...cI.extras.values()],
              flavors: [...cI.flavors.values()],
            }),
          ),
        ),
      );
    }
  }, [cartItems, organizationInfo]);

  useEffect((): void => {
    const storedAddress = localStorage.getItem('chosenAddress');

    if (storedAddress) {
      setChosenAddress(JSON.parse(storedAddress));
    }
  }, []);

  useEffect((): void => {
    if (chosenAddress) {
      localStorage.setItem('chosenAddress', JSON.stringify(chosenAddress));
    } else {
      localStorage.removeItem('chosenAddress');
    }
  }, [chosenAddress, triggerRedirect]);

  useEffect((): void => {
    RequestHandler.interceptors.response.use(
      undefined,
      (error: AxiosError): Promise<void | AxiosResponse> => {
        const isUserPaymentsRequest =
          error.request.responseURL ===
          `${Configs.REACT_APP_API_URL}user/payment`;
        const isLoyaltyRequest =
          error.request.responseURL ===
          `${Configs.REACT_APP_API_URL}loyaltyprogram/organization` ||
          error.request.responseURL ===
          `${Configs.REACT_APP_API_URL}loyaltyprogram/member/points`;

        if (!isUserPaymentsRequest && !isLoyaltyRequest) {
          let toastMessage = 'Ocorreu um erro. Tente novamente.';

          if (error.response && error.response.status === 404) {
            toastMessage = 'Não encontrado.';
          } else if (error.response) {
            // @ts-ignore
            toastMessage = getErrorResponseFirstMessage(error.response);
          }

          setToastMessage(toastMessage);
        }

        return Promise.reject(error);
      },
    );
  }, [setToastMessage]);

  return (
    <GlobalContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        guestUUID,
        paymentModifier,
        setPaymentModifier,
        tokenFacebook,
        setTokenFacebook,
        setGuestUUID,
        isLoading,
        setLoading,
        toastMessage,
        setToastMessage,
        triggerRedirect,
        cartItems,
        setCartItems,
        redirectTarget,
        setRedirectTarget,
        chosenAddress,
        setChosenAddress,
        orgCustomInputs,
        setOrgCustomInputs,
      }}
    >
      <LoyaltyProvider>
        <AppRouterStyles.Container id="main-container">
          <TransitionGroup className="router-container">
            <CSSTransition
              key={location.key}
              classNames="route-fade"
              timeout={300}
            >
              <Switch location={location}>
                <Route exact path={match.path} component={DishesPage} />

                <Route path={`${match.path}/carrinho`} component={CartPage} />

                <Route path={`${match.path}/pedidos`} component={OrdersPage} />

                <Route path={`${match.path}/pagamento/pix`} component={PixPaymentPage} />

                <Route
                  exact
                  path={`${match.path}/perfil`}
                  component={ProfilePage}
                />

                <Route
                  path={`${match.path}/perfil/entrar`}
                  component={LoginPage}
                />

                <Route
                  exact
                  path={`${match.path}/perfil/configuracoes`}
                  component={ConfigsPage}
                />

                <Route
                  exact
                  path={`${match.path}/perfil/apagar`}
                  component={DeleteAccountPage}
                />

                <Route
                  exact
                  path={`${match.path}/perfil/cadastrar`}
                  component={SignUpPage}
                />

                <Route
                  exact
                  path={`${match.path}/perfil/cadastrar/facebook`}
                  component={SignupFacebook}
                />

                <Route
                  exact
                  path={`${match.path}/perfil/enderecos`}
                  component={AddressesPage}
                />

                <Route path={`${match.path}/sair`} component={LogoutPage} />

                <Route
                  exact
                  path={`${match.path}/perfil/recuperar-senha`}
                  component={PasswordRecoveryPage}
                />

                <Route component={NotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>

          <TopNav />

          <BottomNav />

          {isLoading && <Loader />}

          {/* @ts-ignore */}
          <Toast setMessage={setToastMessage} message={toastMessage} />
        </AppRouterStyles.Container>
      </LoyaltyProvider>
    </GlobalContext.Provider>
  );
};

export default withRouter(AppRouter);
