import React, { ReactElement, useContext } from 'react';
import OrganizationContext from '../../../../contexts/OrganizationContext';
import DeliveryContentStyles from './styles';

const PaymentContent = () => {
  const { organizationInfo } = useContext(OrganizationContext);

  if (organizationInfo === undefined) return <></>;

  const { paymentMethods, paymentObservation } = organizationInfo;

  return (
    <>
      <DeliveryContentStyles.Title>
        Pagamentos aceitos na entrega
      </DeliveryContentStyles.Title>

      <DeliveryContentStyles.PaymentInfo>
        {paymentMethods.length > 0 && (
          <DeliveryContentStyles.PaymentMethods>
            {paymentMethods.map(
              ({ name, id }): ReactElement => {
                return <li key={id}>{name}</li>;
              },
            )}
          </DeliveryContentStyles.PaymentMethods>
        )}

        {paymentObservation && (
          <DeliveryContentStyles.PaymentObservation>{`OBS: ${paymentObservation}`}</DeliveryContentStyles.PaymentObservation>
        )}
      </DeliveryContentStyles.PaymentInfo>
    </>
  );
};

export default PaymentContent;
