import React, { createContext } from 'react';
import { OrganizationInfo } from '../types/OrganizationInfo';

export interface IOrganizationContext {
  organizationInfo?: OrganizationInfo;
  setOrganizationInfo: React.Dispatch<
    React.SetStateAction<OrganizationInfo | undefined>
  >;
  isAvailable?: boolean;
  setIsAvailable: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrganizationContext = createContext<IOrganizationContext>({
  setOrganizationInfo: (): void => {},
  setIsAvailable: (): void => {},
});

export default OrganizationContext;
