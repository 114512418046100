import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import { transformColor, MediaQuery } from 'octa-front-sdk';

const CartBar = styled.div`
  display: flex;
  width: 100vw;
  max-width: 400px;
  padding: auto 30px;
  justify-content: space-between;
  line-height: 2rem;
  color: ${Color.White};

  div {
    margin: auto 20px;

    span {
      background: ${transformColor(Color.MeuDeliveryCyan, 0.6)};
      color: ${Color.White};
      border-radius: 100px;
      margin-right: 0.5rem;
      display: inline-flex;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Container = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70px;
  position: fixed;
  bottom: 61px;
  z-index: 10;
  background: ${({ isDisabled }): string =>
    isDisabled
      ? Color.Grey400
      : `linear-gradient(to bottom right, ${transformColor(
          Color.MeuDeliveryCyan,
          1,
        )}, ${transformColor(Color.MeuDeliveryCyan, 0.8)})`};
  cursor: ${({ isDisabled }): string =>
    isDisabled ? 'not-allowed' : 'pointer'};

  span {
    color: ${({ isDisabled }): string =>
      isDisabled ? Color.Grey600 : Color.White};
    text-transform: uppercase;
  }

  ${MediaQuery.BiggerThanSmall} {
    bottom: 0;
    left: 0;
    border-radius: 0;
    height: 60px;
  }
`;

const OrderPlacerStyles = { CartBar, Container };

export default OrderPlacerStyles;
