import { useCallback, useContext } from "react";
import OrganizationContext from "../../../../../contexts/OrganizationContext";
import PaymentOptionsStyles from "../styles";
import { formatPrice } from "../../../../../Helpers";
import { maskMoney } from "octa-front-sdk";
import GlobalContext from "../../../../../contexts/GlobalContext";
import { Radio } from "../../../../Radio";
import Input from "../../../../Input";

interface IProps {
  chosenMethod: string;
  setChosenMethod: (value: string) => void;
  setValueForChange: (value: string) => void;
  valueForChange: string;
}

export function OrgPaymentMethods(props: IProps) {
  const { chosenMethod, setChosenMethod, setValueForChange, valueForChange } = props;

  const { setPaymentModifier } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  // @ts-ignore
  const renderPaymentModifier = useCallback((modifier, type): string => {
    const typeText = modifier < 0 ? 'Desconto' : 'Taxa';
    modifier = Math.abs(modifier);

    const valueModifierFormatted = (modifier * 100).toFixed(2);

    return `${typeText} de ${type === 'Percent' ? `${valueModifierFormatted}%` : formatPrice(modifier)
      }`;
  }, []);

  return (
    <>
      <PaymentOptionsStyles.PaymentMethods>
        {organizationInfo?.paymentMethods.map(
          (method) => {
            const { id, name, paymentModifier } = method;

            return (
              <PaymentOptionsStyles.PaymentMethod key={id}>
                <Radio
                  key={id}
                  name="chosenMethod"
                  value={id.toString()}
                  id={id.toString()}
                  onChange={(e) => {
                    setPaymentModifier(paymentModifier);
                    setChosenMethod(e.target.value);
                  }}
                  label={`${name}`}
                />

                {!!paymentModifier && (
                  <PaymentOptionsStyles.PaymentModifierText
                    isDiscount={paymentModifier.modifier < 0}
                  >
                    {renderPaymentModifier(
                      paymentModifier.modifier,
                      paymentModifier.modifierType,
                    )}
                  </PaymentOptionsStyles.PaymentModifierText>
                )}
              </PaymentOptionsStyles.PaymentMethod>
            );
          },
        )}
      </PaymentOptionsStyles.PaymentMethods>

      {organizationInfo &&
        organizationInfo.paymentMethods.find(
          (method): boolean =>
            method.name.toLocaleLowerCase() === 'dinheiro' &&
            method.id === parseInt(chosenMethod),
        ) && (
          <PaymentOptionsStyles.ChangeContainer>
            <Input
              name="valueForChange"
              label="Troco para:"
              onChange={(e) => setValueForChange(maskMoney(e.target.value || '0'))}
              value={valueForChange}
            />
          </PaymentOptionsStyles.ChangeContainer>
        )}

      {organizationInfo && organizationInfo.paymentObservation && (
        <PaymentOptionsStyles.Observation>
          Obs: {organizationInfo.paymentObservation}
        </PaymentOptionsStyles.Observation>
      )}
    </>
  );
}
