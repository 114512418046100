import styled from 'styled-components';
import Color from "octa-front-sdk/dist/enums/Color";

export const Container = styled.div`
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul li {
    text-align: center;
    padding: 2rem;
    border-bottom: 1px solid ${Color.Grey300};
  }

  ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: ${Color.Grey700};
  }
`;
