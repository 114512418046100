import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';

export const CustomInputContainer = styled.div`
  > * {
    display: block;
  }

  > label {
    text-align: left;
  }

  > input {
    transition: 0.2s;
    width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid ${Color.Grey400};
    background-color: ${Color.White};
    margin-bottom: 5px;

    @media (min-width: 768px) {
      padding-left: 42px;
    }

    &::placeholder {
      color: ${Color.BlueGrey400};
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  > .error-span {
    display: block;
    font-size: 0.85rem;
    color: ${Color.Red400};
  }
`;
