import styled from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";

export const Warning = styled.div`
  background-color: ${Color.Red100};
  border: 1px solid ${Color.Red500};
  border-radius: 8px;
  padding: 16px;

  p {
    margin: 0;
    color: ${Color.Red700};
  }
`;

export const Container = styled.div`
  padding: 1rem;
  max-width: 500px;

  h1 {
    color: ${Color.Grey800}
  }

  > p {
    color: ${Color.Grey600}
  }
`;
