import React, { createContext, useContext, useEffect, useState } from 'react';
import { Popup } from '.';

interface IConfirmData {
  prompt: string;
  isOpen: boolean;
  proceed: null | ((value: unknown) => void);
  cancel: null | (() => void);
}

export interface PopupContextData {
  confirm: IConfirmData;
  setConfirm: React.Dispatch<React.SetStateAction<IConfirmData>>;
}

interface IProps {
  children: React.ReactNode;
}

const PopupContext = createContext<PopupContextData>({} as PopupContextData);

const PopupProvider = ({ children }: IProps) => {
  const [confirm, setConfirm] = useState<IConfirmData>({
    prompt: '',
    isOpen: false,
    proceed: null,
    cancel: null,
  });

  return (
    <PopupContext.Provider value={{ confirm, setConfirm }}>
      {children}

      <Popup />
    </PopupContext.Provider>
  );
};

interface IUsePopup extends PopupContextData {
  isConfirmed: (prompt: string) => Promise<boolean>;
}

function usePopup(): IUsePopup {
  const context = useContext(PopupContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const { confirm, setConfirm } = context;

  const isConfirmed = async (prompt: string) => {
    setNeedsCleanup(true);

    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });

    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return false;
      },
    );
  };

  if (!context) {
    throw new Error('usePopup must be used within an PopupProvider');
  }

  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return { ...context, isConfirmed };
}

export { PopupProvider, usePopup };
