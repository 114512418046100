import React, { ReactElement } from 'react';
import PasswordRecovery from 'octa-front-sdk/dist/components/PasswordRecovery';
import Application from 'octa-front-sdk/dist/enums/Application';
import { useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page';
import PasswordRecoveryPageStyles from './styles';
import PizzaLoader from '../../components/PizzaLoader';

const PasswordRecoveryPage = () => {
  const match = useRouteMatch();

  return (
    <Page title="Recuperar Senha" shouldCenterTitle>
      <PasswordRecoveryPageStyles.Container>
        <PasswordRecovery
          application={Application.App}
          loaderComponent={PizzaLoader}
          suffix={match.url.split('/')[1]}
        />
      </PasswordRecoveryPageStyles.Container>
    </Page>
  );
};

export default PasswordRecoveryPage;
