import styled from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';


const Title = styled.h1`
  color: ${Color.Grey700};
  font-size: ${FontSize.Normal};
`;

const WorkHoursContentStyles = {
  Title,
};

export default WorkHoursContentStyles;
