import React, { ReactNode, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface IProps {
  name: string;
  label: string;
  messageOption?: string;
  children: ReactNode;
}

export default function SelectInput(props: IProps) {
  const { name, label, children } = props;

  const inputRef = useRef<HTMLSelectElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label htmlFor={fieldName}>{label}</label>
      <select id={fieldName} ref={inputRef}>
        {children}
      </select>
    </Container>
  );
}
