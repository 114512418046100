import styled from 'styled-components';
import DividerStyles from 'octa-front-sdk/dist/components/Divider/styles';

const Container = styled.div`
  margin: 0 auto;
  max-width: 400px;

  ${DividerStyles.Container} {
    margin: 20px 0;
  }
`;

const SignUpFacebookPageStyles = { Container };

export default SignUpFacebookPageStyles;
