import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useContext,
} from 'react';
import { maskCPF } from 'octa-front-sdk';

import GlobalContext from '../../contexts/GlobalContext';

import { IOrgCustomInput } from '../../types/OrgCustomInput';

import CustomInput from '../CustomInput';

import { Container } from './styles';

interface IInputValues {
  [key: string]: string;
}

export interface IRenderCustomInputRef {
  getValues: () => void | Array<
    Omit<IOrgCustomInput, 'required' | 'valueMask'>
  >;
}

const RenderOrgCustomInput: React.ForwardRefRenderFunction<
  IRenderCustomInputRef,
  {}
> = (_, ref) => {
  const { orgCustomInputs } = useContext(GlobalContext);

  const [inputValues, setInputValues] = useState<IInputValues>({});
  const [inputErrors, setInputErrors] = useState<Array<string>>([]);

  const currentDate = new Date().toLocaleDateString('fr-CA');

  function validateInputsRequired() {
    const requiredInputs = orgCustomInputs.filter(
      input => input.required === true,
    );

    const inputsWithError = requiredInputs.filter(
      input => !inputValues[input.key] || inputValues[input.key] === '',
    );

    inputsWithError.forEach(input =>
      setInputErrors([...inputErrors, input.key]),
    );

    return inputsWithError.length > 0;
  }

  function transformDataToApi() {
    if (validateInputsRequired()) return;

    const customInputsApiData: Array<
      Omit<IOrgCustomInput, 'required' | 'valueMask'>
    > = [];

    orgCustomInputs.forEach(obj => {
      const data = {
        key: obj.key,
        displayName: obj.displayName,
        value: inputValues[obj.key],
      };

      if (obj.key in inputValues && inputValues[obj.key] !== '')
        return customInputsApiData.push(data);
    });

    return customInputsApiData;
  }

  useImperativeHandle(ref, () => ({ getValues: () => transformDataToApi() }));

  return (
    <Container>
      {orgCustomInputs.map(ud => (
        <>
          {ud.key === 'document' && (
            <CustomInput
              name={ud.key}
              label={ud.displayName}
              required={ud.required}
              onChange={e => {
                const cpfFormatted = maskCPF(e.target.value);
                setInputValues({ ...inputValues, [ud.key]: cpfFormatted });
              }}
              value={inputValues[ud.key]}
              error={
                inputErrors.includes(ud.key) ? 'Campo Obrigatório' : undefined
              }
            />
          )}
          {ud.key === 'birthday' && (
            <CustomInput
              type="date"
              name={ud.key}
              label={ud.displayName}
              required={ud.required}
              min="1910-10-30"
              max={currentDate}
              onChange={e =>
                setInputValues({ ...inputValues, [ud.key]: e.target.value })
              }
              error={
                inputErrors.includes(ud.key) ? 'Campo Obrigatório' : undefined
              }
            />
          )}
        </>
      ))}
    </Container>
  );
};

export default forwardRef(RenderOrgCustomInput);
