import React, {
  ReactElement,
  useEffect,
  useState,
  useContext,
} from 'react';
import Step, {
  withStepProps,
} from 'octa-front-sdk/dist/components/Wizard/Step';
import { Link } from "react-router-dom";
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import { formatDate, getHoursAndMinutesFromDate } from 'octa-front-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard,
  faMapMarkerAlt,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import PizzaLoader from '../../../components/PizzaLoader';
import DetailsStepStyles, { PixPaymentAlert } from './styles';
import { calcOrderItemPrice, formatPrice } from '../../../Helpers';
import OrganizationContext from '../../../contexts/OrganizationContext';
import { Order, ProductOrder } from '../../../types/Order';
import OrderStatus from '../../../enums/OrderStatus';
import DeliveryTime from './DeliveryTime';
import WhatsappLink from '../../../components/WhatsappLink';
import Timeline from './Timeline';
import PasswordRegister from '../../../components/PasswordRegister';
import GlobalContext from '../../../contexts/GlobalContext';
import OnlinePaymentType from "../../../enums/OnlinePaymentType";

interface IProps {
  currentOrderId: number;
}

const formatDateAndTime = (date: Date): string =>
  `${formatDate(date)
    .split('-')
    .reverse()
    .join('/')} — ${getHoursAndMinutesFromDate(date)}`;

const DetailsStep = (props: IProps) => {
  const { currentOrderId } = props;
  const { organizationInfo } = useContext(OrganizationContext);
  const { guestUUID, setToastMessage } = useContext(GlobalContext);

  const [isLoading, setLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order>();
  const [currentStatus, setCurrentStatus] = useState<OrderStatus>();
  const [statusHistory, setStatusHistory] = useState<
    { status: OrderStatus; statusDate: string }[]
  >();
  const [isGuestUser, setIsGuestUser] = useState(false);

  useEffect(() => {
    if (guestUUID) {
      setIsGuestUser(true);
    }
  }, [guestUUID]);

  useEffect(() => {
    async function getOrderData() {
      setLoading(true);
      try {
        const res = await RequestHandler.get(`user/orders/${currentOrderId}`);

        setCurrentOrder(res.data);
        setCurrentStatus(res.data.status);
      } catch (err) {
        setToastMessage("Erro ao buscar dados do pedido")
      } finally {
        setLoading(false);
      }
    }

    getOrderData()
  }, [setLoading, organizationInfo, currentOrderId]);

  useEffect(() => {
    async function updateStatus() {
      const res = await RequestHandler.get(`user/orders/${currentOrderId}/status`);

      setStatusHistory(res.data.statusHistory);
      setCurrentStatus(res.data.status.status);
    }

    const intervalTime = 15 * 1000;
    const loopingStatus = setInterval(updateStatus, intervalTime);

    return (): void => {
      clearInterval(loopingStatus);
    };
  }, [])

  function checkIfModifierIsDiscount(value: number) {
    return value < 0 ? 'Desconto' : 'Taxa';
  }

  function renderModifierValues(modifiedValue: number): ReactElement {
    return (
      <DetailsStepStyles.ChargesAndChange>
        <span>
          {checkIfModifierIsDiscount(modifiedValue)} por forma de pagamento
        </span>

        <DetailsStepStyles.Separator />

        <span>{formatPrice(modifiedValue)}</span>
      </DetailsStepStyles.ChargesAndChange>
    );
  }

  function renderModifierNotation(modifiedValue: number): ReactElement {
    return (
      <DetailsStepStyles.PaymentModifier
        isDiscount={checkIfModifierIsDiscount(modifiedValue) === 'Desconto'}
      >
        {checkIfModifierIsDiscount(modifiedValue)} {formatPrice(modifiedValue)}
      </DetailsStepStyles.PaymentModifier>
    );
  }

  function getItemTotalPrice(products: ProductOrder[]): number {
    return Object.values(products).reduce(
      (sum, { price, amount }) => sum + price * amount,
      0,
    );
  }

  function renderOnlinePayment(type: OnlinePaymentType) {
    if (type === OnlinePaymentType.Pix) return "PIX"

    if (type === OnlinePaymentType.Credit) return "Cartão de crédito"

    return "Cartão de débito"
  }

  const shouldRenderPixAlert = currentOrder?.onlinePayment?.type === OnlinePaymentType.Pix && !currentOrder.isPaid;

  return (
    // TODO: fix close button color
    <DetailsStepStyles.Container>
      <Step>
        {isLoading && <PizzaLoader message="Buscando pedido..." />}

        {currentOrder &&
          (() => {
            const {
              orderDate,
              delivery,
              paymentMethod,
              onlinePayment,
              valueForChange,
              totalValue,
              products,
              estimateDeliveryDate,
              status,
              isDelivery,
              isPaid,
            } = currentOrder;

            const shouldRenderDeliveryTime =
              isDelivery &&
              estimateDeliveryDate &&
              ![OrderStatus.Rejected, OrderStatus.Finished].find(
                (s): boolean => s === status,
              );

            return (
              <>
                <DetailsStepStyles.OrderId>{`Pedido #${currentOrderId}`}</DetailsStepStyles.OrderId>

                <DetailsStepStyles.SectionContent>
                  <DetailsStepStyles.Date>
                    {formatDateAndTime(new Date(orderDate))}
                  </DetailsStepStyles.Date>
                </DetailsStepStyles.SectionContent>

                <DetailsStepStyles.SectionContent>
                  <DetailsStepStyles.SectionTop>
                    <h1>Acompanhe seu pedido</h1>
                  </DetailsStepStyles.SectionTop>

                  <DetailsStepStyles.SectionContent>
                    {organizationInfo &&
                      organizationInfo.ordersCustomMessage && (
                        <DetailsStepStyles.CustomMessage>
                          {organizationInfo.ordersCustomMessage}
                        </DetailsStepStyles.CustomMessage>
                      )}

                    {shouldRenderDeliveryTime && estimateDeliveryDate && (
                      <DeliveryTime
                        date={estimateDeliveryDate}
                        hasDelivery={!!delivery}
                      />
                    )}
                  </DetailsStepStyles.SectionContent>

                  {currentStatus === OrderStatus.Rejected && (
                    <DetailsStepStyles.SectionCard>
                      <DetailsStepStyles.RejectedOrder>
                        <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                        Rejeitado/Cancelado
                      </DetailsStepStyles.RejectedOrder>
                    </DetailsStepStyles.SectionCard>
                  )}

                  {statusHistory &&
                    currentStatus &&
                    currentStatus !== OrderStatus.Rejected && (
                      <Timeline
                        currentStatus={currentStatus}
                        statusHistory={statusHistory}
                        isDelivery={isDelivery}
                        isPaid={isPaid}
                      />
                    )}

                  {shouldRenderPixAlert && <PixPaymentAlert>
                    <Link to={`/${organizationInfo?.urlSuffix}/pagamento/pix?orderId=${currentOrderId}`}>
                      <p>Você ainda realizou o pagamento pix desse pedido, <strong>Clique aqui para ser redirecionado para a página de pagamento pix!</strong></p>
                    </Link>
                  </PixPaymentAlert>}

                  <DetailsStepStyles.SectionCard>
                    <DetailsStepStyles.CardContent>
                      <DetailsStepStyles.SectionTop>
                        <h1>Seu pedido</h1>
                      </DetailsStepStyles.SectionTop>

                      {products && (
                        <DetailsStepStyles.Products>
                          {products.map(
                            (product, i): ReactElement => {
                              return (
                                <DetailsStepStyles.Product key={i}>
                                  <DetailsStepStyles.MainItem>
                                    <DetailsStepStyles.Amount>{`${product.amount}x`}</DetailsStepStyles.Amount>

                                    <div>
                                      <DetailsStepStyles.Name>
                                        {product.name}
                                      </DetailsStepStyles.Name>

                                      <DetailsStepStyles.Separator />

                                      <DetailsStepStyles.Price>
                                        {formatPrice(calcOrderItemPrice(product))}
                                      </DetailsStepStyles.Price>
                                    </div>
                                  </DetailsStepStyles.MainItem>

                                  {product.items && product.items.length > 0 && (
                                    <DetailsStepStyles.Flavours>
                                      {product.items.map(
                                        (item): ReactElement => {
                                          const { id, name } = item;

                                          return (
                                            <DetailsStepStyles.FlavourItem
                                              key={id}
                                            >
                                              <DetailsStepStyles.InfoContainer>
                                                <DetailsStepStyles.Name>
                                                  {name}
                                                </DetailsStepStyles.Name>
                                              </DetailsStepStyles.InfoContainer>
                                            </DetailsStepStyles.FlavourItem>
                                          );
                                        },
                                      )}
                                    </DetailsStepStyles.Flavours>
                                  )}

                                  {product.extras && product.extras.length > 0 && (
                                    <DetailsStepStyles.Extras>
                                      {product.extras.map(
                                        (extra): ReactElement => {
                                          const {
                                            id,
                                            amount = 1,
                                            name,
                                          } = extra;

                                          return (
                                            <DetailsStepStyles.ExtraItem
                                              key={id}
                                            >
                                              <DetailsStepStyles.InfoContainer>
                                                <DetailsStepStyles.Amount
                                                  outline
                                                >
                                                  {`${amount}x`}
                                                </DetailsStepStyles.Amount>

                                                <DetailsStepStyles.Name>
                                                  {name}
                                                </DetailsStepStyles.Name>
                                              </DetailsStepStyles.InfoContainer>
                                            </DetailsStepStyles.ExtraItem>
                                          );
                                        },
                                      )}
                                    </DetailsStepStyles.Extras>
                                  )}
                                </DetailsStepStyles.Product>
                              );
                            },
                          )}
                        </DetailsStepStyles.Products>
                      )}
                    </DetailsStepStyles.CardContent>
                  </DetailsStepStyles.SectionCard>

                  <DetailsStepStyles.SectionCard>
                    <DetailsStepStyles.CardIcon>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </DetailsStepStyles.CardIcon>

                    <DetailsStepStyles.CardContent>
                      <DetailsStepStyles.SectionTop>
                        <h1>Receber em</h1>
                      </DetailsStepStyles.SectionTop>

                      <DetailsStepStyles.Address>
                        {!!delivery && delivery.address.name && (
                          <strong>{`${delivery.address.name} - `} </strong>
                        )}

                        {!!delivery &&
                          `${delivery.address.street}, ${delivery.address.number
                          }${delivery.address.neighbourhood &&
                          `. ${delivery.address.neighbourhood}`
                          }.`}

                        {!delivery &&
                          'Este pedido foi marcado para ser retirado na loja'}
                      </DetailsStepStyles.Address>
                    </DetailsStepStyles.CardContent>
                  </DetailsStepStyles.SectionCard>

                  <DetailsStepStyles.SectionCard>
                    <DetailsStepStyles.CardIcon>
                      <FontAwesomeIcon icon={faCreditCard} />
                    </DetailsStepStyles.CardIcon>

                    <DetailsStepStyles.CardContent>
                      <DetailsStepStyles.SectionTop>
                        <h1>Forma de pagamento</h1>
                      </DetailsStepStyles.SectionTop>

                      <DetailsStepStyles.PaymentMethod>
                        <span>
                          {onlinePayment
                            ? `Pagamento online: ${renderOnlinePayment(onlinePayment.type)}`
                            : paymentMethod.name}
                        </span>
                        {onlinePayment &&
                          !!onlinePayment.modifiedValue &&
                          renderModifierNotation(onlinePayment.modifiedValue)}
                        {!onlinePayment &&
                          !!paymentMethod.modifiedValue &&
                          renderModifierNotation(paymentMethod.modifiedValue)}
                      </DetailsStepStyles.PaymentMethod>
                    </DetailsStepStyles.CardContent>
                  </DetailsStepStyles.SectionCard>

                  <DetailsStepStyles.SectionCard>
                    <DetailsStepStyles.CardContent>
                      {products.length && (
                        <DetailsStepStyles.ChargesAndChange>
                          <span>
                            {products.length > 1
                              ? `${products.length} Itens`
                              : 'Item'}
                          </span>

                          <DetailsStepStyles.Separator />

                          <span>
                            {formatPrice(getItemTotalPrice(products))}
                          </span>
                        </DetailsStepStyles.ChargesAndChange>
                      )}

                      {!!delivery && delivery.deliveryFee > 0 && (
                        <DetailsStepStyles.ChargesAndChange>
                          <span>Taxa de entrega:</span>

                          <DetailsStepStyles.Separator />

                          <span>{formatPrice(delivery.deliveryFee)}</span>
                        </DetailsStepStyles.ChargesAndChange>
                      )}

                      {onlinePayment &&
                        !!onlinePayment.modifiedValue &&
                        renderModifierValues(onlinePayment.modifiedValue)}

                      {!onlinePayment &&
                        !!paymentMethod.modifiedValue &&
                        renderModifierValues(paymentMethod.modifiedValue)}

                      {!!currentOrder.discountCouponId && (
                        <DetailsStepStyles.ChargesAndChange id="coupon">
                          <span>Cupom de Desconto:</span>

                          <DetailsStepStyles.Separator />

                          <span style={{color: 'rgb(22 163 74)'}}>
                            - {formatPrice(currentOrder.discountValue)}
                          </span>
                        </DetailsStepStyles.ChargesAndChange>
                      )}

                      <DetailsStepStyles.ChargesAndChange id="total">
                        <span>Total:</span>

                        <DetailsStepStyles.Separator />

                        <span>{formatPrice(totalValue)}</span>
                      </DetailsStepStyles.ChargesAndChange>

                      {!!valueForChange && (
                        <DetailsStepStyles.ChargesAndChange>
                          <span>Troco para:</span>

                          <DetailsStepStyles.Separator />

                          <span>{formatPrice(valueForChange)}</span>
                        </DetailsStepStyles.ChargesAndChange>
                      )}
                    </DetailsStepStyles.CardContent>
                  </DetailsStepStyles.SectionCard>

                  {organizationInfo && organizationInfo.phone && (
                    <DetailsStepStyles.PhoneContainer>
                      <DetailsStepStyles.SectionTop>
                        <h1>Precisa de ajuda?</h1>
                      </DetailsStepStyles.SectionTop>

                      <WhatsappLink
                        label="Enviar mensagem pelo Whatsapp"
                        phone={organizationInfo.phone}
                        message={`Olá, estou com uma dúvida sobre o pedido ${currentOrderId}.`}
                      />
                    </DetailsStepStyles.PhoneContainer>
                  )}
                </DetailsStepStyles.SectionContent>
              </>
            );
          })()}

        {isGuestUser && !isLoading && (
          <PasswordRegister onClose={(): void => setIsGuestUser(false)} />
        )}
      </Step>
    </DetailsStepStyles.Container>
  );
};

export default withStepProps(DetailsStep);
