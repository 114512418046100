enum OrderStatus {
  New = 'New',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Ready = 'Ready',
  Dispatched = 'Dispatched',
  Finished = 'Finished',
}

export default OrderStatus;
