import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { FormValues } from 'octa-front-sdk/dist/contexts/FormContext';
import Divider from 'octa-front-sdk/dist/components/Divider';
import Button from 'octa-front-sdk/dist/components/Button';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import Checkbox from 'octa-front-sdk/dist/components/Checkbox';
import Form from 'octa-front-sdk/dist/components/Form';
import Input from 'octa-front-sdk/dist/components/Input';
import InputType from 'octa-front-sdk/dist/enums/InputType';
import Color from 'octa-front-sdk/dist/enums/Color';

import FbPixel from '../../../services/FbPixel';

import { Product } from '../../../types/Product';
import { CartItem } from '../../../types/CartItem';
import { Prize } from '../../../types/Prize';
import ProductType from '../../../enums/ProductType';
import ProductPricing from '../../../enums/ProductPricing';

import GlobalContext from '../../../contexts/GlobalContext';
import OrganizationContext from '../../../contexts/OrganizationContext';
import { useLoyalty } from '../../../hooks/Loyalty';

import PizzaLoader from '../../PizzaLoader';
import Counter from '../../Counter';
import ImageCarousel from '../../ImageCarousel';
import { formatPrice } from '../../../Helpers';

import Trophy from '../../../images/icons/trophy.svg';

import AddToCartStepStyles, {
  ButtonsWrapper,
  CustomizationHeaderContainer,
  InfoContainer,
  WarningMsg,
} from './styles';
import { useScroll } from '../../../contexts/ScrollContext';
import { usePopup } from '../../Popup/context';
import Spacer from "../../Spacer";

const helpStringBuilder = (minimum?: number, maximum?: number): string => {
  if (!minimum && !maximum) return '';

  let label = 'Escolha';

  if (minimum) label += ` pelo menos ${minimum}`;

  if (minimum && maximum) label += ',';

  if (maximum) label += ` no máximo ${maximum}`;

  if (minimum === maximum) label = `Escolha ${minimum}`;

  label += '.';

  return label;
};

interface IProps {
  currentDish?: Partial<Product>;
  cartItem?: CartItem;
  closeWizard: () => void;
}

const CartItemStep = (props: IProps) => {
  const { currentDish, cartItem, closeWizard } = props;

  const { cartItems, setCartItems, isLoggedIn } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);
  const { loyaltyProgram, memberPoints, setMemberPoints } = useLoyalty();
  const { setScrollTarget } = useScroll();
  const { isConfirmed } = usePopup();

  const [isLoading, setLoading] = useState(false);
  const [extras, setExtras] = useState<CartItem['extras']>(new Map());
  const [flavors, setFlavors] = useState<CartItem['flavors']>(new Map());
  const [amount, setAmount] = useState(1);
  const [obs, setObs] = useState<string>();
  const [dishDetails, setDishDetails] = useState<Product>();
  const [isPrize, setIsPrize] = useState(false);
  const [priceInPoints, setPriceInPoints] = useState(0);
  const [extrasCost, setExtrasCost] = useState(0);

  useEffect((): void => {
    if (currentDish) {
      let detailsLink =
        currentDish.links &&
        currentDish.links.find((l): boolean => l.rel === 'details');

      if (detailsLink === undefined) {
        const productType =
          currentDish.type === ProductType.SimpleProduct
            ? 'simple'
            : 'composite';

        detailsLink = {
          rel: 'details',
          href: `/api/menu/products/${productType}/${currentDish.id}`,
        };
      }

      if (detailsLink) {
        setLoading(true);

        RequestHandler.get(detailsLink.href.substr(5))
          .then((response): void => {
            setDishDetails({ ...response.data, links: currentDish.links });
          })
          .finally((): void => setLoading(false));
      }
    }
  }, [currentDish]);

  useEffect((): void => {
    if (cartItem) {
      const { amount, extras, flavors, obs } = cartItem;

      setAmount(amount);
      setExtras(extras);
      setFlavors(flavors);
      setObs(obs);
    }
  }, [cartItem]);

  useEffect((): void => {
    if (loyaltyProgram) {
      const prize = loyaltyProgram.prizes.find((prize): Prize | undefined => {
        let p;

        if (
          !!currentDish &&
          currentDish.id !== undefined &&
          prize.productId === currentDish.id.toString()
        ) {
          p = prize;
        }

        return p;
      });

      setIsPrize(!!prize);
      setPriceInPoints(prize ? prize.priceInPoints : 0);
    }
  }, [loyaltyProgram, currentDish]);

  useEffect((): void => {
    let extrasCost = 0;

    extras.forEach((ex): void => {
      extrasCost += ex.price * ex.amount;
    });

    setExtrasCost(extrasCost);
  }, [extras]);

  let totalPrice = 0;
  let amountPriceInPoints = priceInPoints;
  let disableAdd = true;

  // TODO: Refactorar logica de calculo de preço
  if (dishDetails) {
    let hasMinimumExtras = true;

    if (dishDetails.type === ProductType.SimpleProduct) {
      if (dishDetails.wholesalePricing) {
        const prices = dishDetails.wholesalePricing
          .sort((a, b): number => b.price - a.price)
          .filter((wP): boolean => amount > wP.minimumAmount);

        if (prices.length > 0) {
          totalPrice += prices[prices.length - 1].price;
        } else {
          if (dishDetails.promotionalPrice) {
            totalPrice += dishDetails.promotionalPrice;
          } else {
            totalPrice += dishDetails.price;
          }
        }
      } else {
        if (dishDetails.promotionalPrice) {
          totalPrice += dishDetails.promotionalPrice;
        } else {
          totalPrice += dishDetails.price;
        }
      }
    } else {
      switch (dishDetails.pricing) {
        case ProductPricing.Average:
          totalPrice +=
            [...flavors.values()].reduce(
              (sum, current): number => (sum += current.price),
              0,
            ) / flavors.size;
          break;

        case ProductPricing.Maximum:
          totalPrice += Math.max(
            ...[...flavors.values()].map((f): number => f.price),
          );
          break;

        case ProductPricing.Fixed:
          totalPrice = dishDetails.fixedPrice || 0;
          break;
        case ProductPricing.Minimum:
        default:
          const flavorPrices = [...flavors.values()].map(
            (f): number => f.price,
          );
          totalPrice += flavorPrices.length ? Math.min(...flavorPrices) : 0;
          break;
      }
    }

    if (extras) totalPrice += extrasCost;

    if (dishDetails.extraCategories) {
      for (let i = 0; i < dishDetails.extraCategories.length; i++) {
        const category = dishDetails.extraCategories[i];
        const { minimumExtras = 0 } = category;
        const ids = category.extras.map((e): number => e.id);

        const totalAmount = [...extras.values()]
          .filter((e): boolean => ids.includes(e.id))
          .reduce((sum, { amount = 0 }): number => (sum += amount), 0);

        [...extras.values()].map((extraItem): number => {
          return extraItem.amount * extraItem.price;
        });

        if (totalAmount < minimumExtras) {
          hasMinimumExtras = false;

          break;
        }
      }
    }

    const hasMinimumFlavors = dishDetails.minimumProducts
      ? flavors.size >= dishDetails.minimumProducts
      : true;

    disableAdd = !hasMinimumExtras || !hasMinimumFlavors;

    amountPriceInPoints *= amount;
    totalPrice *= amount;
  }

  let flavorsHelp = '';

  if (dishDetails) {
    flavorsHelp = helpStringBuilder(
      dishDetails.minimumProducts,
      dishDetails.maximumProducts,
    );
  }

  const disableRedeem =
    !!memberPoints === false ||
    (!!memberPoints && memberPoints.validPoints < amountPriceInPoints) ||
    (memberPoints && memberPoints.cartPoints < amountPriceInPoints);

  let buttonText = cartItem ? 'Salvar' : 'Adicionar';

  if (totalPrice > 0) buttonText += ` (${formatPrice(totalPrice)})`;

  useLayoutEffect((): void => {
    if (dishDetails) {
      if (
        dishDetails.extraCategories &&
        dishDetails.extraCategories.length > 0
      ) {
        [...dishDetails.extraCategories].forEach((categories): void => {
          const { minimumExtras, maximumExtras } = categories;
          const isOneSelection = !!(minimumExtras === 1 && maximumExtras === 1);

          if ([...extras.keys()].length > 0 && isOneSelection) {
            categories.extras.forEach((catEx): void => {
              if (extras.get(catEx.id)) {
                const el = document.getElementById(`chosenExtra${catEx.id}`);

                if (el) el.click();
              }
            });
          }
        });
      }
    }
  });

  const updateCartItem = useCallback(
    (redeemed?: boolean): void => {
      if (redeemed) {
        if (memberPoints) {
          const memberPointsUpdated = {
            pendingPoints: memberPoints.pendingPoints,
            validPoints: memberPoints.validPoints,
            cartPoints: memberPoints.cartPoints - amountPriceInPoints,
          };

          setMemberPoints(memberPointsUpdated);
        }
      }

      const newCartItems = Array.from(cartItems);
      const id = cartItem
        ? cartItem.id
        : Math.max(...newCartItems.map((cI): number => cI.id), 0) + 1;

      const newCartItem = {
        product: { ...dishDetails, redeemed },
        id,
        extras,
        flavors,
        amount,
        totalPrice,
        obs,
        redeemed,
      };

      if (cartItem) {
        const index = newCartItems.findIndex(
          (cI): boolean => cI.id === cartItem.id,
        );

        newCartItems[index] = newCartItem;
      } else {
        newCartItems.push(newCartItem);

        FbPixel.trackEvent('AddToCart', {
          value: totalPrice.toFixed(2),
          currency: 'BRL',
          content_type: 'product',
          content_ids: [`${(newCartItem.product as Product).id}`],
          contents: [
            {
              id: `${(newCartItem.product as Product).id}`,
              quantity: newCartItem.amount,
            },
          ],
        });
      }

      setCartItems(newCartItems);

      closeWizard();
    },
    [
      amount,
      cartItem,
      cartItems,
      closeWizard,
      dishDetails,
      extras,
      flavors,
      obs,
      setCartItems,
      totalPrice,
      memberPoints,
      setMemberPoints,
      amountPriceInPoints,
    ],
  );

  const removeCartItem = useCallback(
    // @ts-ignore
    async prize => {
      const confirmation = await isConfirmed(
        'Confirmar remoção desse item do carrinho?',
      );

      if (confirmation && cartItem) {
        const index = cartItems.findIndex(
          (cI): boolean => cI.id === cartItem.id,
        );
        const newCartItems = Array.from(cartItems);

        if (cartItem.redeemed) {
          if (memberPoints) {
            const memberPointsUpdated = {
              pendingPoints: memberPoints.pendingPoints,
              validPoints: memberPoints.validPoints,
              cartPoints:
                memberPoints.cartPoints + Number(prize * cartItem.amount),
            };

            setMemberPoints(memberPointsUpdated);
          }
        }

        newCartItems.splice(index, 1);

        setCartItems(newCartItems);

        closeWizard();
      }
    },
    [
      isConfirmed,
      cartItem,
      cartItems,
      setCartItems,
      closeWizard,
      memberPoints,
      setMemberPoints,
    ],
  );

  const capitalize = (text: string) => {
    if (typeof text !== 'string') return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <>
      {isLoading ? (
        <PizzaLoader message="Buscando produto..." />
      ) : (
        <>
          {organizationInfo && dishDetails !== undefined && (
            <>
              <div itemScope itemType="http://schema.org/Product">
                <meta
                  itemProp="brand"
                  content={capitalize(organizationInfo.name)}
                />
                <meta itemProp="name" content={capitalize(dishDetails.name)} />
                <meta
                  itemProp="description"
                  content={capitalize(
                    dishDetails.description || 'Faça seu pedido agora!',
                  )}
                />
                <meta itemProp="productID" content={`${dishDetails.id}`} />
                <meta itemProp="url" content={window.location.href} />
                <meta itemProp="image" content={dishDetails.pictureUrl} />
                <div
                  itemProp="offers"
                  itemScope
                  itemType="http://schema.org/Offer"
                >
                  <link
                    itemProp="availability"
                    href="http://schema.org/InStock"
                  />
                  <link
                    itemProp="itemCondition"
                    href="http://schema.org/NewCondition"
                  />
                  <meta
                    itemProp="price"
                    content={`${dishDetails.displayPrice}`}
                  />
                  <meta itemProp="priceCurrency" content="BRL" />
                </div>
              </div>

              <InfoContainer>
                {dishDetails.pictures.length > 0 && (
                  <ImageCarousel imgArray={dishDetails.pictures} />
                )}

                <h1 className="title">{dishDetails.name}</h1>
                <p className="descrciption">{dishDetails.description}</p>

                {dishDetails.promotionalPrice && (
                  <>
                    <p className="oldPrice">
                      De {formatPrice(dishDetails.displayPrice)}
                    </p>
                    <p className="newPrice">
                      Por {formatPrice(dishDetails.promotionalPrice)}
                    </p>
                  </>
                )}

                {!dishDetails.promotionalPrice && (
                  <p className="price">
                    {dishDetails.isPriceVariable && 'A partir de '}

                    <strong>{formatPrice(dishDetails.displayPrice)}</strong>
                  </p>
                )}

                {isLoggedIn && isPrize && (
                  <small>
                    <AddToCartStepStyles.Icon
                      src={Trophy}
                      alt="Icone de troféu"
                    />{' '}
                    Disponível para resgate por
                    <strong>{` ${priceInPoints} pontos.`}</strong>
                  </small>
                )}

                {dishDetails && dishDetails.forbidsDiscountCoupon && (
                  <WarningMsg>
                    <AddToCartStepStyles.Icon
                      src="https://img.icons8.com/color/48/000000/error.png"
                      alt="Atenção"
                    />
                    Esse produto é inválido para cupons
                  </WarningMsg>
                )}
              </InfoContainer>

              {dishDetails.wholesalePricing && (
                <AddToCartStepStyles.PricesContainer>
                  <ul>
                    {dishDetails.wholesalePricing
                      .sort((a, b): number => b.price - a.price)
                      .map(
                        (wholesalePrice, i): ReactElement => {
                          let label = formatPrice(wholesalePrice.price);

                          if (wholesalePrice.minimumAmount)
                            label += ` acima de ${wholesalePrice.minimumAmount}`;

                          if (wholesalePrice.maximumAmount)
                            label += ` até ${wholesalePrice.maximumAmount}`;

                          label += ' unidades';

                          return (
                            <li key={i}>
                              <span>{label}</span>
                            </li>
                          );
                        },
                      )}
                  </ul>
                </AddToCartStepStyles.PricesContainer>
              )}

              {dishDetails.availableProducts &&
                dishDetails.availableProducts.length > 0 && (
                  <div id="flavors">
                    <CustomizationHeaderContainer>
                      <h2>Sabores</h2>
                      {flavorsHelp && <p>{flavorsHelp}</p>}
                    </CustomizationHeaderContainer>

                    <AddToCartStepStyles.CustomizationContent>
                      <ul>
                        {dishDetails.availableProducts
                          .sort((a, b): number => a.name.localeCompare(b.name))
                          .map(
                            (flavor): ReactElement => {
                              const { id, name, price, description } = flavor;
                              const { maximumProducts } = dishDetails;

                              return (
                                <AddToCartStepStyles.CustomizationItemList
                                  key={id}
                                >
                                  <div>
                                    <AddToCartStepStyles.ExtraName>
                                      {name}
                                    </AddToCartStepStyles.ExtraName>

                                    {description && (
                                      <AddToCartStepStyles.ExtraDescription>
                                        {description}
                                      </AddToCartStepStyles.ExtraDescription>
                                    )}

                                    <AddToCartStepStyles.ExtraPrice>
                                      {dishDetails &&
                                        dishDetails.type !==
                                        ProductType.SimpleProduct &&
                                        dishDetails.pricing !==
                                        ProductPricing.Fixed &&
                                        formatPrice(price)}
                                    </AddToCartStepStyles.ExtraPrice>
                                  </div>

                                  <Checkbox
                                    name={name}
                                    changeHandler={(e): void => {
                                      const event = e as React.ChangeEvent<HTMLInputElement>;
                                      const { checked } = event.target;

                                      if (checked) {
                                        setFlavors(
                                          new Map(
                                            flavors.set(id, { ...flavor }),
                                          ),
                                        );

                                        if (maximumProducts === flavors.size) {
                                          setScrollTarget(`extraCategories-0`);
                                        }
                                      } else {
                                        const newFlavors = new Map(flavors);
                                        newFlavors.delete(id);

                                        setFlavors(newFlavors);
                                      }
                                    }}
                                    checked={!!flavors.get(id)}
                                    isDisabled={
                                      !!maximumProducts &&
                                      !flavors.get(id) &&
                                      flavors.size >= maximumProducts
                                    }
                                  />
                                </AddToCartStepStyles.CustomizationItemList>
                              );
                            },
                          )}
                      </ul>
                    </AddToCartStepStyles.CustomizationContent>
                  </div>
                )}

              {dishDetails.extraCategories &&
                dishDetails.extraCategories
                  .sort((a, b): number => a.name.localeCompare(b.name))
                  .map(
                    (extraCategory, i): ReactElement => {
                      const {
                        name,
                        minimumExtras,
                        maximumExtras,
                      } = extraCategory;
                      const helpString = helpStringBuilder(
                        minimumExtras,
                        maximumExtras,
                      );
                      const extraIds = extraCategory.extras.map(
                        (e): number => e.id,
                      );

                      return (
                        <div
                          id={`extraCategories-${i}`}
                          key={`${extraCategory.name}${i}`}
                        >
                          <CustomizationHeaderContainer>
                            <h2>{name}</h2>
                            {!!helpString && <p>{helpString}</p>}
                          </CustomizationHeaderContainer>

                          <AddToCartStepStyles.CustomizationContent>
                            <Form onSubmit={(): void => {}}>
                              <ul>
                                {extraCategory.extras
                                  .sort((a, b): number =>
                                    a.name.localeCompare(b.name),
                                  )
                                  .map(
                                    (extra): ReactElement => {
                                      const {
                                        id,
                                        name,
                                        price,
                                        description,
                                        unavailable,
                                        pictureUrl,
                                      } = extra;
                                      let amount = 0;
                                      const ex = extras.get(id);

                                      if (ex) amount = ex.amount;

                                      return (
                                        <AddToCartStepStyles.CustomizationItemList
                                          unavailable={unavailable}
                                          key={id}
                                        >
                                          {pictureUrl && (
                                            <AddToCartStepStyles.ExtraPicture>
                                              <AddToCartStepStyles.ExtraPictureImg
                                                src={pictureUrl}
                                                alt={`foto do ${name}`}
                                              />
                                            </AddToCartStepStyles.ExtraPicture>
                                          )}

                                          <div>
                                            <AddToCartStepStyles.ExtraName>
                                              {name}{' '}
                                              {unavailable && '(Indisponível)'}
                                            </AddToCartStepStyles.ExtraName>
                                            {description && (
                                              <AddToCartStepStyles.ExtraDescription>
                                                {description}
                                              </AddToCartStepStyles.ExtraDescription>
                                            )}
                                            <AddToCartStepStyles.ExtraPrice>
                                              {price > 0 && formatPrice(price)}
                                            </AddToCartStepStyles.ExtraPrice>
                                          </div>

                                          <Spacer />

                                          {helpString === 'Escolha 1.' ? (
                                            <AddToCartStepStyles.Radio>
                                              <Input
                                                key={id}
                                                name="chosenExtra"
                                                type={InputType.Radio}
                                                value={id.toString()}
                                                onChange={(): void => {
                                                  extraCategory.extras.map(
                                                    (exCat): boolean =>
                                                      extras.delete(exCat.id),
                                                  );
                                                  setExtras(
                                                    new Map(
                                                      extras.set(id, {
                                                        ...extra,
                                                        amount: 1,
                                                      }),
                                                    ),
                                                  );
                                                  setScrollTarget(
                                                    `extraCategories-${i + 1}`,
                                                  );
                                                }}
                                                disabled={unavailable}
                                              />
                                            </AddToCartStepStyles.Radio>
                                          ) : (
                                            !unavailable && (
                                              <Counter
                                                count={amount}
                                                setCount={(count): void => {
                                                  setExtras(
                                                    new Map(
                                                      extras.set(id, {
                                                        ...extra,
                                                        amount: count,
                                                      }),
                                                    ),
                                                  );

                                                  if (
                                                    maximumExtras !==
                                                    undefined &&
                                                    extras.get(id)?.amount ===
                                                    maximumExtras
                                                  ) {
                                                    setScrollTarget(
                                                      `extraCategories-${i + 1
                                                      }`,
                                                    );
                                                  }
                                                }}
                                                maximum={
                                                  maximumExtras === undefined
                                                    ? undefined
                                                    : maximumExtras -
                                                    [...extras.values()]
                                                      .filter((e): boolean =>
                                                        extraIds.includes(
                                                          e.id,
                                                        ),
                                                      )
                                                      .reduce(
                                                        (
                                                          sum,
                                                          current,
                                                        ): number => {
                                                          if (
                                                            current.id !== id
                                                          )
                                                            sum +=
                                                              current.amount;

                                                          return sum;
                                                        },
                                                        0,
                                                      )
                                                }
                                              />
                                            )
                                          )}
                                        </AddToCartStepStyles.CustomizationItemList>
                                      );
                                    },
                                  )}
                              </ul>
                            </Form>
                          </AddToCartStepStyles.CustomizationContent>
                        </div>
                      );
                    },
                  )}

              <Form
                onSubmit={(): void => {}}
                initialValues={((): FormValues | undefined => {
                  if (obs !== undefined) return { obs };
                })()}
              >
                {dishDetails && !dishDetails.forbidsObs ? (
                  <CustomizationHeaderContainer>
                    <h2>Deseja adicionar observações?</h2>
                  </CustomizationHeaderContainer>
                ) : <></>}

                <div style={{ padding: '0 1rem' }}>
                  {dishDetails && !dishDetails.forbidsObs && (
                    <Input
                      name="obs"
                      placeholder="Alguma observação no seu pedido?"
                      type={InputType.Textarea}
                      value={obs}
                      onChange={(e): void => {
                        setObs(e.target.value);
                      }}
                    />
                  )}

                  {cartItem && cartItem.redeemed ? (
                    <AddToCartStepStyles.RedeemedText>
                      Regatado por pontos
                    </AddToCartStepStyles.RedeemedText>
                  ) : (
                    <AddToCartStepStyles.CounterAndAddContainer>
                      <Counter
                        count={amount}
                        setCount={setAmount}
                        minimum={1}
                        shouldAlwaysShowDecrement
                      />

                      <ButtonsWrapper>
                        {isLoggedIn && isPrize && (
                          <>
                            <Button
                              text={`Resgate por ${amountPriceInPoints} pontos + ${formatPrice(
                                extrasCost,
                              )}`}
                              isDisabled={!!disableRedeem}
                              onClick={(): void => updateCartItem(true)}
                            />

                            <Divider text="ou" barColor={Color.Transparent} />
                          </>
                        )}

                        <Button
                          text={buttonText}
                          isDisabled={disableAdd}
                          onClick={updateCartItem}
                        />
                      </ButtonsWrapper>
                    </AddToCartStepStyles.CounterAndAddContainer>
                  )}
                </div>
              </Form>

              {cartItem && (
                <AddToCartStepStyles.RemoveButton
                  onClick={() => removeCartItem(priceInPoints)}
                >
                  Remover produto do carrinho
                </AddToCartStepStyles.RemoveButton>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CartItemStep;
