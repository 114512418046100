import styled from 'styled-components';

const Container = styled.button`
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const BtnIconStyles = {
  Container,
};

export default BtnIconStyles;
