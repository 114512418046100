import { createGlobalStyle } from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';
import RadioStyles from 'octa-front-sdk/dist/components/Input/Radio/styles';
import CheckboxStyles from 'octa-front-sdk/dist/components/Checkbox/styles';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Poppins", sans-serif;
  }

  body {
    background-color: ${Color.Grey50};
  }

  html,
  body,
  #root {
    height: 100%;
  }

  #root {
    position: relative;
  }

  p, span, a {
    color: ${Color.Grey900};
  }

  p {
    font-size: ${FontSize.Normal};
  }

  .fb-login {
    width: 100%;
    background-color: ${Color.White};
    color: ${Color.FacebookBlue};
    border: 1px solid ${Color.FacebookBlue};
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    font-size: ${FontSize.SmallX};
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: ${FontSize.NormalX};
      margin-right: 10px;
      color: ${Color.White};
    }
  }

  .route-fade-enter {
    opacity: 0;
  }

  .route-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }

  .route-fade-exit {
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .route-fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }

  .wizard-enter-done {
    z-index: 20 !important;
  }

  ${RadioStyles.OuterCircle} {
    stroke: ${Color.Grey800};
    stroke-width: 5;
    r: 45;
  }

  ${CheckboxStyles.Container} {
    input[type="checkbox"] {
      border: 1px solid ${Color.Grey500};
    }
  }
`;

export default GlobalStyle;
