import { AxiosError } from 'axios';

export function handleApiErrors(err: unknown): Array<string> {
  const errReceived = err as AxiosError;
  const errors: Array<string> = [];

  if (errReceived.code !== '500') {
    // @ts-ignore
    Object.keys(errReceived.response?.data).forEach(k => {
      // @ts-ignore
      errors.push(errReceived.response?.data[k][0]);
    });
  } else {
    errors.push('Ops! Houve algum erro interno no servidor.');
  }

  return errors;
}
