const start = (pixelId: string): void => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelId}');
            fbq('track', 'PageView');
            fbq('track', 'ViewContent');
          `;

  document.head.appendChild(script);

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
            <noscript>
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"
              />
            </noscript>
          `;

  document.head.appendChild(noscript);
};

const trackEvent = (event: string, params?: object): void => {
  window.fbq('track', event, params);
};

const FbPixel = { start, trackEvent };

export default FbPixel;
