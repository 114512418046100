import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import { MediaQuery } from 'octa-front-sdk';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';

const WarningMsg = styled.small`
  display: flex !important;
  align-items: center;
  margin: 0 auto 0.6rem auto;

  > img {
    width: 20px;
  }
`;

const Name = styled.h1`
  margin-top: 0;
  font-size: ${FontSize.NormalX};
`;

const Description = styled.p`
  margin-top: 0;
  color: ${Color.Grey700};
`;

const Flavor = styled.p`
  margin-top: 0;
  margin-left: 20px;
  font-size: ${FontSize.SmallX};
  color: ${Color.Grey700};
`;

const Price = styled.p`
  margin-top: auto;
  color: ${Color.Green500};
  font-weight: bold;
  margin-bottom: 0;
`;

const Picture = styled.img<{ hasBadge: boolean }>`
  min-width: 90px;
  min-height: 90px;
  width: 90px;
  height: 90px;
  margin-left: 10px;
  margin-bottom: ${({ hasBadge }): string => (hasBadge ? '20px' : 'initial')};
  border-radius: 5px;

  ${MediaQuery.BiggerThanSmall} {
    min-width: 120px;
    min-height: 120px;
    width: 120px;
    height: 120px;
  }
`;

const ExtrasBadge = styled.span`
  text-align: right;
  margin: auto 0 0 auto;
  background-color: ${Color.Blue500};
  color: ${Color.White};
  padding: 5px;
  border-radius: 5px;
  font-size: ${FontSize.SmallX};
`;

const PictureAndBadgeContainer = styled.div`
  display: flex;
`;

const CartItem = styled.li`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid ${Color.Grey300};
  border-radius: 5px;
  margin-bottom: 0.4rem;
  padding: 10px;
  min-width: 280px;
  flex-basis: 100%;
  cursor: pointer;
  transition: transform 300ms ease;

  &:hover {
    transform: scale(1.05);
  }

  ${MediaQuery.BiggerThanMedium} {
    flex-basis: initial;
    width: calc(50% - 10px);

    &:nth-child(2n) {
      margin-left: 20px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

const CartList = styled.ul`
  margin: 0 0 40px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  & > a {
    display: block;
    margin-top: 30px;
    text-align: center;
  }

  ${ButtonContainer}:first-of-type {
    margin-bottom: 20px;
  }
`;

const CartPageStyles = {
  WarningMsg,
  Name,
  Description,
  Flavor,
  Price,
  Picture,
  ExtrasBadge,
  PictureAndBadgeContainer,
  CartItem,
  CartList,
  Container,
};

export default CartPageStyles;
