import {
  useEffect,
  useState,
  ReactElement,
  useCallback,
  useContext,
} from 'react';
import { getHoursAndMinutesFromDate } from 'octa-front-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RiMapPin2Fill, RiMapPin2Line } from 'react-icons/ri';
import { faCircle, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import OrderStatus from '../../../../enums/OrderStatus';
import { orderStatuses } from '../../../../Helpers';
import TimelineStyles from './styles';
import OrganizationContext from '../../../../contexts/OrganizationContext';
import { MemberPoints } from '../../../../types/MemberPoints';

import { useLoyalty } from '../../../../hooks/Loyalty';

interface IProps {
  statusHistory: { status: OrderStatus; statusDate: string }[];
  currentStatus: OrderStatus;
  isDelivery?: boolean;
  isPaid?: boolean;
}

const Timeline = (props: IProps) => {
  const { statusHistory, currentStatus, isDelivery, isPaid = false } = props;
  const { organizationInfo } = useContext(OrganizationContext);

  const { setMemberPoints } = useLoyalty();

  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  const statuses = orderStatuses(isDelivery);

  const updateMemberPoints = useCallback(async () => {
    if (!organizationInfo) return;

    const newMemberPoints = await RequestHandler.get(
      'loyaltyprogram/member/points',
    )
      .then(({ data }): MemberPoints => data)
      .catch((): void => {});

    if (newMemberPoints) {
      const cartPoints = newMemberPoints.validPoints;
      setMemberPoints({ ...newMemberPoints, cartPoints });
    }
  }, [organizationInfo, setMemberPoints]);

  useEffect((): void => {
    setCurrentStatusIndex(
      Object.keys(OrderStatus).findIndex((s): boolean => s === currentStatus),
    );

    if (OrderStatus.Finished === currentStatus) {
      updateMemberPoints();
    }
  }, [currentStatus, setCurrentStatusIndex, updateMemberPoints]);

  const getTime = useCallback(
    (status: string): ReactElement | void => {
      const orderStatusIndex = statuses.findIndex((s): boolean => s === status);

      if (orderStatusIndex > -1) {
        const orderStatusName = Object.keys(OrderStatus)[orderStatusIndex];

        if (statusHistory) {
          const result = statusHistory.find((s): boolean => {
            return s.status === orderStatusName;
          });

          if (result) {
            return (
              <span>
                {`(${getHoursAndMinutesFromDate(new Date(result.statusDate))})`}
              </span>
            );
          }
        }
      }
    },
    [statusHistory, statuses],
  );

  return (
    <TimelineStyles.Container>
      <TimelineStyles.Content>
        {statusHistory &&
          statuses
            .filter((status): boolean => {
              if (status === 'Rejeitado') return false;

              if (isDelivery) return String(status) !== 'Pronto para retirada';

              return (
                String(status) !== 'Saiu para entrega' &&
                String(status) !== 'Finalizado'
              );
            })
            .map(
              (status, i): ReactElement => {
                const indexOfStatus = statuses.indexOf(status);

                return (
                  <TimelineStyles.Steps
                    key={i}
                    active={isPaid && currentStatusIndex >= indexOfStatus}
                    isPaid={isPaid}
                    currentIndex={i}
                  >
                    <>
                      {currentStatusIndex === indexOfStatus && (
                        <TimelineStyles.MarkerIcon>
                          {isPaid ? <RiMapPin2Fill /> : <RiMapPin2Line />}
                        </TimelineStyles.MarkerIcon>
                      )}

                      <TimelineStyles.IconsContainer
                        spacer={currentStatusIndex !== indexOfStatus}
                      >
                        <TimelineStyles.ProgressBar
                          active={isPaid && currentStatusIndex > indexOfStatus}
                        />

                        <TimelineStyles.CircleIcon
                          className={
                            isPaid && currentStatusIndex === indexOfStatus ? 'pulse' : ''
                          }
                        >
                          <FontAwesomeIcon icon={faCircle} />
                        </TimelineStyles.CircleIcon>
                      </TimelineStyles.IconsContainer>

                      <p>{!isPaid && indexOfStatus === 0 ? 'Aguardando pagamento' : status}</p>

                      {getTime(status)}
                    </>
                  </TimelineStyles.Steps>
                );
              },
            )}
      </TimelineStyles.Content>
    </TimelineStyles.Container>
  );
};

export default Timeline;
