import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';

interface PictureProps {
  showImg: boolean;
}

const Picture = styled.img<PictureProps>`
  width: 100%;
  height: inherit;
  border-radius: 1rem;
  display: ${props => (props.showImg ? 'block' : 'none')};
`;

const Pictures = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  height: 15rem;
  width: 15rem;
  margin: 0 auto;

  &:hover {
    cursor: zoom-in;
  }
`;

const Pager = styled.div`
  position: absolute;
  bottom: -1.5rem;
  width: 100%;
  box-sizing: border-box;
`;

const Button = styled.button`
  transition: 0.4s;
  border: none;
  background-color: transparent;
  position: absolute;
  height: 100%;
  box-sizing: border-box;

  > svg {
    transition: 0.4s;
    background-color: white;
    border-radius: 50%;
    color: ${Color.BlueGrey600};
  }

  &:hover {
    cursor: pointer;

    > svg {
      filter: drop-shadow(0 0 6px grey);
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;

  > .left-btn {
    left: 0;
    padding-right: 1rem;
  }

  > .right-btn {
    right: 0;
    padding-left: 1rem;
  }
`;

const PictureContainerStyles = {
  Picture,
  Pictures,
  Pager,
  Button,
  Container,
};

export default PictureContainerStyles;
