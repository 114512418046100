import styled from 'styled-components';

export const FooterBottomDevLevilo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  span {
    font-size: 14px;
  }

  img {
    width: 120px;
  }
`;

export const FooterBottomAddress = styled.div`
  text-transform: uppercase;

  p {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;

  margin-top: 36px;
`;

export const PaymentsMethods = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0;
  padding: 0;

  h2 {
    font-size: 1rem;
  }

  strong {
    margin: 8px 0 2px 0;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    padding-bottom: 8px;
    font-weight: 300;
    list-style: none;
  }
`;

export const WorkHours = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  h2 {
    font-size: 1rem;
  }

  strong {
    margin-top: 8px;
    font-weight: 500;
    line-height: 21px;
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
`;

export const FooterTop = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  max-width: 800px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const FooterMiddle = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;

`;

export const FooterMiddleItem = styled.div`
  grid-column: span 1 / span 1;
  text-align: center;
  word-break: break-all;
`;

export const Container = styled.footer`
  width: 100%;
  color: #f2f2f2;
  background-color: #313131;
  z-index: 100;
  box-sizing: border-box;
  padding: 24px;

  .main {
    min-width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 0 auto;
  }

  p {
    margin: 0;
    color: #f2f2f2;
  }

  span {
    color: #f2f2f2;
  }
`;
