import {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { AxiosError } from 'axios'
import {
  withStepProps,
  maskPhoneNumber,
  validateRequired,
} from 'octa-front-sdk';
import Form from 'octa-front-sdk/dist/components/Form';
import Input from 'octa-front-sdk/dist/components/Input';
import Step from 'octa-front-sdk/dist/components/Wizard/Step';
import Button from 'octa-front-sdk/dist/components/Button';
import Color from 'octa-front-sdk/dist/enums/Color';
import { v4 as uuidv4 } from 'uuid';
import FormContext, {
  FormValues,
} from 'octa-front-sdk/dist/contexts/FormContext';
import WizardContext from 'octa-front-sdk/dist/contexts/WizardContext';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import ButtonType from 'octa-front-sdk/dist/enums/ButtonType';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PlaceOrderStepStyles from './styles';
import GlobalContext from '../../../contexts/GlobalContext';
import Auth from '../../../services/Auth';
import OrganizationContext from '../../../contexts/OrganizationContext';
import { Address } from '../../../types/Address';
import { DiscountCoupon } from '../../../types/DiscountCoupon';
import { formatPrice, formatPhoneNumber, calcCartItemPrice } from '../../../Helpers';
import CouponType from '../../../enums/CouponType';
import FbPixel from '../../../services/FbPixel';
import Receipt from './Receipt';
import PaymentOptions from './PaymentOptions';
import { OrderOnlinePaymentRequest } from '../../../types/OrderOnlinePaymentRequest';
import { OrderRequest } from '../../../types/OrderRequest';

import Run from "../../../images/icons/run.svg";
import Plus from "../../../images/icons/plus.svg";
import Delivery from "../../../images/icons/moped.svg";

import RenderCustomInput, {
  IRenderCustomInputRef,
} from '../../RenderOrgCustomInput';
import { handleApiErrors } from '../../../utils/handleApiErrors';
import { Radio } from '../../Radio';
import { IDeliveryRegion } from '../../../types/DeliveryRegion';
import { validatePhone } from '../../../utils/validatePhone';

const PlaceOrderStep = () => {
  const renderCustomInputRef = useRef<IRenderCustomInputRef>(null);

  const {
    isLoggedIn,
    setLoggedIn,
    cartItems,
    guestUUID,
    setGuestUUID,
    chosenAddress,
    setChosenAddress,
    setToastMessage,
    setCartItems,
    paymentModifier,
    orgCustomInputs,
  } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);
  const { goToNextStep } = useContext(WizardContext);
  const [shouldRegisterGuest, setShouldRegisterGuest] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [contactInfo, setContactInfo] = useState({ firstName: '', phone: '' });
  const [shouldRenderContactInfo, setShouldRenderContactInfo] = useState(false);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [shouldPickupOrder, setShouldPickupOrder] = useState(false);
  const [pickedNeighbourhood, setPickedNeighbourhood] = useState('');
  const [hasConfirmedAddress, setHasConfirmedAddress] = useState(false);
  const [chosenMethod, setChosenMethod] = useState('');
  const [onlineMethod, setOnlineMethod] = useState<'Pix' | 'Card' | undefined>();
  const [valueForChange, setValueForChange] = useState('R$0,00');
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState<DiscountCoupon>();
  const [discountValue, setDiscountValue] = useState(0);
  const [order, setOrder] = useState<Partial<OrderRequest>>();
  const [total, setTotal] = useState(0);
  const [isSendingOrder, setIsSendingOrder] = useState(false);
  const [neighbourhoodSelected, setNeighbourhoodSelected] = useState<IDeliveryRegion | undefined>();
  const [shouldTrackCheckout, setShouldTrackCheckout] = useState(true);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const history = useHistory();
  const match = useRouteMatch();
  const [isCheckingRadius, setIsCheckingRadius] = useState(false);
  const [isServed, setIsServed] = useState(false);

  const hasDelivery = organizationInfo &&
    (organizationInfo?.neighbourhoods?.length > 0 ||
      organizationInfo?.deliveryRadius?.length > 0 ||
      organizationInfo?.states?.length > 0);

  const cashPayment =
    organizationInfo &&
    organizationInfo.paymentMethods.find(
      (method): boolean =>
        method.name.trim().toLocaleLowerCase() === 'dinheiro',
    );
  const isCashPayment =
    cashPayment && cashPayment.id === parseInt(chosenMethod);

  const shouldRenderPaymentOptions = isLoggedIn && hasConfirmedAddress;

  const changeAddressHandler = (id: number): void => {
    const selectedItem = addresses.find(
      (address): boolean => Number(address.id) === Number(id),
    );

    if (!selectedItem) return;

    setPickedNeighbourhood(selectedItem.neighbourhood || '');
    setChosenAddress(selectedItem);

    if (organizationInfo) {
      const neighbourhoodFound = organizationInfo.neighbourhoods.find(
        (neighbourhood): boolean => {
          return (
            neighbourhood.name.toLowerCase() ===
            selectedItem.neighbourhood?.toLowerCase()
          );
        },
      );

      if (neighbourhoodFound) setNeighbourhoodSelected(neighbourhoodFound);
    }
  };

  const getCartTotal = useCallback((): number => {
    return cartItems.reduce((sum, current): number => sum + calcCartItemPrice(current), 0);
  }, [cartItems]);

  const checkFreeDelivery = useCallback((): boolean => {
    const cartTotal = getCartTotal();
    const total = !discountValue ? cartTotal : cartTotal - discountValue;

    return (
      !!organizationInfo &&
      total >= organizationInfo.freeDeliveryMinimumOrderValue
    );
  }, [discountValue, getCartTotal, organizationInfo]);

  const normalizeValueForChange = useCallback((): number => {
    const cleanedValue = valueForChange.replace(/\D/g, '');

    if (!parseInt(cleanedValue)) return 0;

    return parseFloat(
      `${cleanedValue.substring(
        0,
        cleanedValue.length - 2,
      )}.${cleanedValue.substring(cleanedValue.length - 2)}`,
    );
  }, [valueForChange]);

  const guestRegistrationHandler = useCallback(
    (values: FormValues): Promise<void | Response> | void => {
      if (shouldRegisterGuest) {
        const guestUUID = uuidv4();

        setIsRegistering(true);

        const guestData = {
          userName: guestUUID,
          firstName: values.firstName.toString(),
          phone: values.phone.toString(),
        };

        if (orgCustomInputs) {
          const customData = renderCustomInputRef.current?.getValues();
          // @ts-ignore
          guestData.data = customData;
        }

        return Auth.registerAsGuest(guestData)
          .then((): void => {
            setLoggedIn(true);
            setShouldRenderContactInfo(true);
            setGuestUUID(guestUUID);
            setContactInfo({
              firstName: values.firstName.toString(),
              phone: values.phone.toString(),
            });
            setChosenAddress(undefined);
            setAddresses([]);
          })
          .finally((): void => {
            setIsRegistering(false);
          });
      }
    },
    [
      shouldRegisterGuest,
      orgCustomInputs,
      setLoggedIn,
      setGuestUUID,
      setChosenAddress,
    ],
  );

  const trackPurchase = useCallback((): void => {
    FbPixel.trackEvent('Purchase', {
      value: total.toFixed(2),
      currency: 'BRL',
      content_type: 'product',
      content_ids: [
        cartItems.map(
          (item) => `${organizationInfo?.id}${item.product.id}`,
        ),
      ],
      contents: [
        cartItems.map((item): object => {
          return {
            id: `${organizationInfo?.id}${item.product.id}`,
            quantity: item.amount,
          };
        }),
      ],
    });
  }, [total, cartItems, organizationInfo]);

  const placeOrder = useCallback(
    async (onlinePayment?: OrderOnlinePaymentRequest): Promise<void> => {
      const isValidPayment = ((): boolean => {
        if (organizationInfo) {
          return !(
            isCashPayment &&
            order &&
            order.valueForChange &&
            order.valueForChange < total
          );
        }

        return false;
      })();

      if (!isValidPayment || !organizationInfo) {
        setToastMessage("Valor de pagamento insuficiente.");
        return;
      }

      const payload: Partial<OrderRequest> = { ...order, couponCode };

      if (onlinePayment) {
        delete payload.paymentMethod;
        delete payload.localPayment;

        payload.onlinePayment = onlinePayment;
      }

      setIsSendingOrder(true);

      try {
        let newAddress;
        if (pickedNeighbourhood && chosenAddress) {
          newAddress = {
            ...chosenAddress,
            neighbourhood: pickedNeighbourhood,
          };

          await RequestHandler.put(`user/address/${chosenAddress.id}`, newAddress);
          setChosenAddress(newAddress);
        }

        const response = await RequestHandler.post(`user/orders`, payload);
        setCartItems([]);

        if (!!payload.onlinePayment?.pix?.email) {
          history.push(`/${organizationInfo.urlSuffix}/pagamento/pix?orderId=${response.data.id}`);
          return;
        }

        history.push(
          `/${match.url.split("/")[1]}/pedidos?detalhes=${response.data.id}`
        );
        await trackPurchase();
      } catch (err) {
        setIsSendingOrder(false);
        const errReceived = err as AxiosError;

        // @ts-ignore
        if (errReceived.response?.data.cause) {
          let msg = "";
          // @ts-ignore
          const { code } = errReceived.response.data.cause[0];

          if (code === 401)
            msg =
              "Parece que temos um problema de integração! Entre em contato com o lojista ou use outra forma de pagamento.";

          if (code === 2060) {
            msg = "Consumidor não pode ser o mesmo que o dono do estabelecimento";
          } else if (code === 2001) {
            msg = "Já foi postado o mesmo pedido no último minuto.";
          } else {
            // @ts-ignore
            msg = errReceived.response.data.cause[0].description;
          }

          setToastMessage(msg);
          return;
        }

        const error = handleApiErrors(err);
        setToastMessage(error[0]);
      }
    },
    [
      order,
      organizationInfo,
      setToastMessage,
      chosenAddress,
      isCashPayment,
      pickedNeighbourhood,
      setChosenAddress,
      total,
      couponCode,
      trackPurchase,
      history,
      match.url,
      setCartItems,
    ]
  );

  const renderAddress = useCallback((): ReactElement | void => {
    if (organizationInfo) {
      let { address } = organizationInfo;

      if (!shouldPickupOrder && chosenAddress) {
        const neighbourhood =
          pickedNeighbourhood
            ? pickedNeighbourhood
            : chosenAddress.neighbourhood;

        address = {
          ...chosenAddress,
          neighbourhood,
        };
      }

      return (
        <>
          <p>{[address.street, address.neighbourhood].join(', ')}</p>

          <p>{[address.city, address.state].join(' — ')}</p>

          <p>{address.complement && ` ${address.complement}`}</p>

          <p>{address.reference && `${address.reference}`}</p>

          <p>{address.postalcode && `CEP: ${address.postalcode}`}</p>
        </>
      );
    }
  }, [
    organizationInfo,
    chosenAddress,
    pickedNeighbourhood,
    shouldPickupOrder,
  ]);

  useEffect((): void => {
    const newOrder: Partial<OrderRequest> = {
      paymentMethod: { id: +chosenMethod },
      valueForChange: normalizeValueForChange(),
    };

    if (chosenAddress && !shouldPickupOrder) {
      // @ts-ignore
      newOrder.delivery = { id: chosenAddress.id };
    } else if (shouldPickupOrder) {
      newOrder.delivery = undefined;
    }

    const products: OrderRequest['products'] = [];

    cartItems.forEach((cartItem): void => {
      const { product, amount, obs, redeemed } = cartItem;
      const { id, type } = product;

      const extras = [...cartItem.extras.values()]
        .map(({ id, amount }): { id: number; amount: number } => ({
          id,
          amount,
        }))
        .filter(({ amount }): boolean => !!amount);

      const items = [...cartItem.flavors.values()]
        .map(({ id, amount = 1 }): { id: number; amount: number } => ({
          id,
          amount,
        }))
        .filter(({ amount }): boolean => !!amount);

      if (id && type) {
        const newProduct = { id, type, amount, obs, extras, items, redeemed };

        // @ts-ignore
        if (!newProduct.extras.length) delete newProduct.extras;

        // @ts-ignore
        if (!newProduct.items.length) delete newProduct.items;

        products.push(newProduct);
      }
    });

    newOrder.products = products;

    setOrder(
      (order): Partial<OrderRequest> => {
        const mergedOrder = { ...order, ...newOrder };

        if (!isCashPayment || !mergedOrder.valueForChange)
          delete mergedOrder.valueForChange;

        return mergedOrder;
      },
    );
  }, [
    chosenAddress,
    chosenMethod,
    valueForChange,
    cartItems,
    shouldPickupOrder,
    isCashPayment,
    normalizeValueForChange,
  ]);

  useEffect((): void => {
    if (!shouldPickupOrder && !isLoadingAddresses) {
      if (organizationInfo && !!chosenAddress) {

        setIsCheckingRadius(true);

        RequestHandler.get(`user/address/${chosenAddress.id}/deliverydetails`)
          .then(({ data }): void => {
            const { isServed, delivery } = data;

            if (isServed) {
              const hasFreeDelivery = checkFreeDelivery();

              setDeliveryFee(hasFreeDelivery ? 0 : delivery.deliveryFee);
              setIsServed(true);
            } else if (!isServed) {
              setIsServed(false);
            }
          })
          .finally((): void => {
            setIsCheckingRadius(false);
          });
      }
    }
  }, [chosenAddress]);

  useEffect((): void => {
    if (shouldTrackCheckout && total) {
      FbPixel.trackEvent('InitiateCheckout', {
        value: total.toFixed(2),
        currency: 'BRL',
      });

      setShouldTrackCheckout(false);
    }
  }, [total, shouldTrackCheckout]);

  const getCartTotalForCupom = useCallback((): number => {
    return cartItems.reduce((sum, current): number => {
      let result = 0;
      if (!current.product.forbidsDiscountCoupon)
        result = sum + calcCartItemPrice(current);
      return result;
    }, 0);
  }, [cartItems]);

  useEffect((): void => {
    const newTotal = getCartTotalForCupom();
    const differenceTotal = getCartTotal() - getCartTotalForCupom();
    let newDiscountValue = 0;

    if (discount) {
      if (discount.type === CouponType.Value) {
        newDiscountValue = discount.discount;
      } else {
        newDiscountValue = discount.maxDiscountValue
          ? Math.min(newTotal * discount.discount, discount.maxDiscountValue)
          : newTotal * discount.discount;
      }
    }

    setTotal(Math.max(newTotal - newDiscountValue + differenceTotal, 0));
    setDiscountValue(newDiscountValue);
  }, [getCartTotal, deliveryFee, discount, getCartTotalForCupom]);

  useEffect((): void => {
    const firstName = localStorage.getItem('firstName') || '';
    const phone = localStorage.getItem('phone') || '';

    setContactInfo({ firstName, phone });

    if (!!guestUUID && firstName && phone) {
      setShouldRenderContactInfo(true);
    }
  }, [guestUUID]);

  useEffect((): void => {
    const shouldRegisterGuest = !isLoggedIn && !guestUUID;

    setShouldRegisterGuest(shouldRegisterGuest);
  }, [isLoggedIn, guestUUID]);

  useEffect((): void => {
    const hasDeliverRadius =
      organizationInfo && organizationInfo.deliveryRadius.length;

    if (isLoggedIn && !shouldRegisterGuest && !addresses.length) {
      setIsLoadingAddresses(true);

      RequestHandler.get('user/address')
        .then((response): void => {
          if (hasDeliverRadius) {
            setAddresses(
              response.data.filter((a: Address): boolean => !!a.location),
            );
          } else {
            setAddresses(response.data);
          }
        })
        .finally((): void => setIsLoadingAddresses(false));
    }
  }, [
    setIsLoadingAddresses,
    isLoggedIn,
    shouldRegisterGuest,
    organizationInfo,
    setChosenAddress,
    chosenAddress,
    addresses.length,
  ]);

  useEffect((): void => {
    let neighbourhood;

    if (organizationInfo) {
      neighbourhood = organizationInfo.neighbourhoods.find((obj) => {
        if (!obj.name || !pickedNeighbourhood) return;

        return (
          obj.name.toLocaleUpperCase() ===
          pickedNeighbourhood.toLocaleUpperCase()
        );
      });
    }

    setNeighbourhoodSelected(neighbourhood);
  }, [getCartTotal, organizationInfo, pickedNeighbourhood]);

  // TODO: refatorar essa função para ser mais clara e descritiva
  function checkIfRadiusOrNeighbourhood(): boolean {
    const allItemsAreReedemed = cartItems.every(item => item.redeemed === true);
    const total = getCartTotal();
    const minimumOrderValue = organizationInfo?.minimumOrderValue || 0;

    if (!organizationInfo) return false;

    if (organizationInfo.deliveryRadius.length > 0) return true;

    if (
      allItemsAreReedemed &&
      minimumOrderValue === 0
    ) {
      return true;
    }


    if (minimumOrderValue <= total) {
      return true;
    }

    if (shouldPickupOrder) return true;

    return false;
  }

  const renderPaymentInfo = useCallback((): ReactElement => {

    const realDeliveryFee = shouldPickupOrder ? 0 : deliveryFee;

    const rows = [
      { label: 'Subtotal', value: getCartTotal() },
      { label: 'Desconto', value: discountValue },
      { label: 'Taxa de entrega', value: realDeliveryFee },
    ];

    const valueForChange = normalizeValueForChange();

    let paymentModifierValue = 0;

    if (paymentModifier && onlineMethod === 'Card') {
      paymentModifierValue =
        paymentModifier.modifierType === 'Percent'
          ? (total / 100) * paymentModifier.modifier * 100
          : paymentModifier.modifier;

      rows.push({
        label: `${paymentModifier.modifier < 0 ? 'Desconto' : 'Taxa'
          } por forma de pagamento`,
        value: paymentModifierValue,
      });
    }

    if (valueForChange > 0)
      rows.push({ label: 'Troco para', value: valueForChange });

    rows.push({
      label: 'Total',
      value:
        total +
        paymentModifierValue +
        (discount && discount.freeDelivery ? 0 : realDeliveryFee),
    });

    return (
      <>
        {rows.map(
          (r, i): ReactElement => {
            return (
              <PlaceOrderStepStyles.PaymentInfoRow key={i}>
                <span>{r.label}</span>

                <span>{formatPrice(r.value)}</span>
              </PlaceOrderStepStyles.PaymentInfoRow>
            );
          },
        )}
      </>
    );
  }, [
    deliveryFee,
    discountValue,
    getCartTotal,
    total,
    normalizeValueForChange,
    paymentModifier,
    discount,
    shouldPickupOrder,
    onlineMethod,
  ]);

  const canConfirmAddress =
    (!isLoadingAddresses && isServed && !shouldPickupOrder) || (organizationInfo?.allowStorePickup && !hasDelivery);

  //Hack: octa-front-sdk button component does not declare support for jsx elements to be passed as children
  const newAddressButtonText = (
    <>
      <PlaceOrderStepStyles.Icon
        style={{ filter: 'invert(100%)' }}
        src={Plus}
        alt="Plus Icon"
      />
      Adicionar endereço de entrega
    </>
  ) as unknown as string

  return (
    <Step>
      <PlaceOrderStepStyles.Container>
        {organizationInfo && (
          <>
            <h1>Fazer pedido</h1>

            <PlaceOrderStepStyles.Card>
              <h1>Produtos</h1>

              <Receipt />
            </PlaceOrderStepStyles.Card>

            {shouldRenderContactInfo && (
              <PlaceOrderStepStyles.Card>
                <h1>{contactInfo.firstName}</h1>

                <p>{contactInfo.phone}</p>

                <Button
                  text="Alterar"
                  color={Color.MeuDeliveryCyan}
                  onClick={(): void => {
                    setShouldRenderContactInfo(false);
                    setLoggedIn(false);
                    setGuestUUID('');
                    setHasConfirmedAddress(false);
                  }}
                />
              </PlaceOrderStepStyles.Card>
            )}

            {shouldRegisterGuest && (
              <>
                <PlaceOrderStepStyles.SectionTop>
                  <h1>Dados para contato</h1>
                </PlaceOrderStepStyles.SectionTop>

                <PlaceOrderStepStyles.SectionContent>
                  <Form
                    onSubmit={guestRegistrationHandler}
                    initialValues={{ ...contactInfo }}
                  >
                    <Input
                      name="firstName"
                      label="Nome*"
                      material
                      onChange={(e): void =>
                        setContactInfo({
                          ...contactInfo,
                          firstName: e.target.value,
                        })
                      }
                      validator={validateRequired}
                    />

                    <Input
                      name="phone"
                      label="Telefone*"
                      material
                      onChange={(e): void =>
                        setContactInfo({
                          ...contactInfo,
                          phone: e.target.value,
                        })
                      }
                      validator={validatePhone}
                      mask={maskPhoneNumber}
                    />

                    {orgCustomInputs && (
                      <RenderCustomInput ref={renderCustomInputRef} />
                    )}

                    {process.env.REACT_APP_IS_APPLE ? (
                      <PlaceOrderStepStyles.PhoneWarning>
                        Precisamos do telefone para entrar em contato somente em
                        caso de dúvida sobre o pedido
                      </PlaceOrderStepStyles.PhoneWarning>
                    ) : <></>}

                    {/* @ts-ignore */}
                    <FormContext.Consumer>
                      {({ hasErrors }): ReactElement => (
                        <Button
                          text={`${isRegistering ? 'Salvando' : 'Confirmar'
                            } dados`}
                          isDisabled={isRegistering || hasErrors}
                          color={Color.MeuDeliveryCyan}
                        />
                      )}
                    </FormContext.Consumer>
                  </Form>
                </PlaceOrderStepStyles.SectionContent>
              </>
            )}

            {hasConfirmedAddress && (
              <>
                <PlaceOrderStepStyles.Card>
                  <>
                    <h1>Endereço</h1>

                    {renderAddress()}
                  </>
                </PlaceOrderStepStyles.Card>

                <PlaceOrderStepStyles.Card>
                  <h1>Pagamento</h1>

                  {renderPaymentInfo()}
                </PlaceOrderStepStyles.Card>
              </>
            )}

            {isLoggedIn && !hasConfirmedAddress && (
              <>
                <PlaceOrderStepStyles.SectionTop>
                  <h1>Dados de entrega</h1>
                </PlaceOrderStepStyles.SectionTop>

                <PlaceOrderStepStyles.SectionContent>
                  {!organizationInfo.allowStorePickup && hasDelivery && (
                    <PlaceOrderStepStyles.HeaderSwitch>
                      Somente delivery
                    </PlaceOrderStepStyles.HeaderSwitch>
                  )}

                  {organizationInfo.allowStorePickup && !hasDelivery && (
                    <PlaceOrderStepStyles.HeaderSwitch>
                      Somente retirada na loja
                    </PlaceOrderStepStyles.HeaderSwitch>
                  )}

                  {organizationInfo.allowStorePickup && hasDelivery && (
                    <PlaceOrderStepStyles.Switch
                      className="switch"
                      htmlFor="AllowStorePickup"
                      isPickup={shouldPickupOrder}
                    >
                      <input
                        type="checkbox"
                        name="AllowStorePickup"
                        id="AllowStorePickup"
                        onChange={(): void =>
                          setShouldPickupOrder(val => !val)
                        }
                      />
                      <span className="option delivery-span">
                        <img
                          className="delivery-icon"
                          src={Delivery}
                          alt="Delivery Icon"
                        />
                        Delivery
                      </span>
                      <span className="option pickup-span">
                        <img
                          className="pickup-icon"
                          src={Run}
                          alt="Retirar Icon"
                        />
                        Retirar na loja
                      </span>
                      <div className="knob" />
                    </PlaceOrderStepStyles.Switch>
                  )}

                  {organizationInfo.allowStorePickup && (
                    <PlaceOrderStepStyles.OrganizationAddress>
                      <strong>Endereço da loja:</strong>

                      <span>
                        {` ${organizationInfo.address.street}, ${organizationInfo.address.number
                          }. ${organizationInfo.address.neighbourhood &&
                          organizationInfo.address.neighbourhood
                          }.`}
                      </span>
                    </PlaceOrderStepStyles.OrganizationAddress>
                  )}
                </PlaceOrderStepStyles.SectionContent>

                {hasDelivery && (
                  <PlaceOrderStepStyles.SectionContent>
                    {isLoadingAddresses && <p>Carregando endereços...</p>}
                    {!shouldPickupOrder && !isLoadingAddresses && addresses && (
                      <ul>
                        {addresses.map(
                          (address) => {
                            const {
                              id,
                              name,
                              street,
                              number,
                              neighbourhood,
                            } = address;

                            const chosen = chosenAddress
                              ? id === chosenAddress.id
                              : false;

                            return (
                              <PlaceOrderStepStyles.RadioInputAddress key={id}>
                                <Radio
                                  id={id!.toString()}
                                  value={id!.toString()}
                                  name="addressSaved"
                                  disabled={shouldPickupOrder}
                                  checked={chosen}
                                  onChange={(e) =>
                                    changeAddressHandler(
                                      parseInt(e.target.value),
                                    )
                                  }
                                  label={
                                    <>
                                      {name && <strong>{` ${name} - `} </strong>}
                                      {` ${street}, ${number}${neighbourhood && `. ${neighbourhood}`
                                        }.`}
                                    </>
                                  }
                                />
                              </PlaceOrderStepStyles.RadioInputAddress>
                            );
                          },
                        )}
                      </ul>
                    )}
                  </PlaceOrderStepStyles.SectionContent>
                )}

                <PlaceOrderStepStyles.SectionContent>
                  {hasDelivery && !shouldPickupOrder && !isLoadingAddresses && (
                    <>
                      <Button
                        type={addresses.length > 0 ? ButtonType.Outline : ButtonType.Solid}
                        text={newAddressButtonText}
                        color={Color.MeuDeliveryCyan}
                        onClick={(): void => {
                          goToNextStep();
                        }}
                      />
                    </>
                  )}

                  {/* TODO: memoizar getCartTotal */}
                  {neighbourhoodSelected &&
                    neighbourhoodSelected.minimumOrderValue &&
                    neighbourhoodSelected.minimumOrderValue >
                    getCartTotal() ? (
                    <PlaceOrderStepStyles.WarningText>
                      A sua região de entrega exige um pedido mínimo de{' '}
                      {formatPrice(neighbourhoodSelected.minimumOrderValue)}
                    </PlaceOrderStepStyles.WarningText>
                  ) : null}

                  {!isLoadingAddresses && isCheckingRadius && (
                    <p>Verificando área de entrega...</p>
                  )}

                  {chosenAddress?.neighbourhood && !canConfirmAddress && !shouldPickupOrder && (
                    <PlaceOrderStepStyles.WarningText>
                      Região ainda não cadastrada. Por favor, confirme em nosso
                      whatsapp {organizationInfo && formatPhoneNumber(organizationInfo.phone)} a
                      disponibilidades de entrega.
                    </PlaceOrderStepStyles.WarningText>
                  )}

                  {organizationInfo.allowStorePickup && shouldPickupOrder && (
                    <Button
                      text="Confirmar retirada no local"
                      color={Color.MeuDeliveryCyan}
                      onClick={(): void => {
                        setHasConfirmedAddress(true);
                      }}
                    />
                  )}

                  {checkIfRadiusOrNeighbourhood() &&
                    !isCheckingRadius &&
                    canConfirmAddress && (
                      <Button
                        text="Confirmar dados de entrega"
                        color={Color.MeuDeliveryCyan}
                        onClick={(): void => {
                          setHasConfirmedAddress(true);
                        }}
                      />
                    )}
                </PlaceOrderStepStyles.SectionContent>
              </>
            )}

            {shouldRenderPaymentOptions && (
              <>
                <PlaceOrderStepStyles.SectionTop>
                  <h1>Formas de pagamento</h1>
                </PlaceOrderStepStyles.SectionTop>

                <PlaceOrderStepStyles.SectionContent>
                  <PaymentOptions
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    setDiscount={setDiscount}
                    deliveryFee={deliveryFee}
                    cartTotal={getCartTotal()}
                    chosenMethod={chosenMethod}
                    setChosenMethod={setChosenMethod}
                    setOnlineMethod={setOnlineMethod}
                    valueForChange={valueForChange}
                    setValueForChange={setValueForChange}
                    placeOrder={placeOrder}
                    isSendingOrder={isSendingOrder}
                    setIsSendingOrder={setIsSendingOrder}
                  />
                </PlaceOrderStepStyles.SectionContent>
              </>
            )}
          </>
        )}
      </PlaceOrderStepStyles.Container>
    </Step>
  );
};

export default withStepProps(PlaceOrderStep);
