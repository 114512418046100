import React, { ReactElement, useContext } from 'react';

import { formatPrice } from '../../../Helpers';

import { Product } from '../../../types/Product';

import { Ribbon, PhotoContainer, Card, SimpleCard } from './styles';
import OrganizationContext from "../../../contexts/OrganizationContext";
import { isNil } from 'lodash';

interface IProps {
  dish: Product;
  onClickCard: () => void;
  hasPoints: boolean;
}

const DishCard = ({
  dish,
  onClickCard,
  hasPoints,
}: IProps) => {
  const {
    name,
    description,
    pictures,
    pictureUrl,
    displayPrice,
    promotionalPrice,
    isPriceVariable,
    unavailable,
  } = dish;

  const { organizationInfo } = useContext(OrganizationContext);

  function handleCardClick(): void {
    if (unavailable) return;

    onClickCard();
  }

  let mainPicture;

  // eslint-disable-next-line array-callback-return
  const allPictures = pictures.map(picture => {
    if (picture.url !== null) return picture;
  });

  // eslint-disable-next-line prefer-destructuring
  if (allPictures) mainPicture = allPictures[0];

  const IS_CARD_COMPACT = organizationInfo?.productsDisplay === "Compact"

  return IS_CARD_COMPACT ? (
    <SimpleCard onClick={handleCardClick} unavailable={unavailable}>
      <figure>
        {mainPicture?.url && (
          <img src={mainPicture?.url} alt={name} loading="lazy" />
        )}
      </figure>

      <h2>{name}</h2>

      {isPriceVariable && !promotionalPrice && (
        <small className="start-at">A partir de</small>
      )}

      {!promotionalPrice && (
        <p className="price">{formatPrice(displayPrice)}</p>
      )}

      {promotionalPrice && (
        <div className="promotion">
          <p className="from">
            De <strong>{formatPrice(displayPrice)}</strong>
          </p>
          <p className="for">
            Por <strong>{formatPrice(promotionalPrice)}</strong>
          </p>
        </div>
      )}
    </SimpleCard>
  ) : (
    <Card onClick={handleCardClick} unavailable={unavailable}>
      {unavailable && <Ribbon unavailable={unavailable}>Indisponível</Ribbon>}

      {hasPoints && !unavailable && <Ribbon>Resgatar</Ribbon>}

      <div className="infos">
        <div>
          <h2 className="title">{name}</h2>

          {description && <p className="desc">{description}</p>}
        </div>

        <div>
          {isPriceVariable && isNil(promotionalPrice) && (
            <small className="start-at">A partir de</small>
          )}

          {isNil(promotionalPrice) && (
            <p className="price">{formatPrice(displayPrice)}</p>
          )}

          {!isNil(promotionalPrice) && (
            <div className="promotion">
              <p className="from">
                De <strong>{formatPrice(displayPrice)}</strong>
              </p>
              <p className="for">
                Por <strong>{formatPrice(promotionalPrice)}</strong>
              </p>
            </div>
          )}
        </div>
      </div>

      {mainPicture?.url && (
        <PhotoContainer>
          <img src={mainPicture.url} alt={name} loading="lazy" />
        </PhotoContainer>
      )}
    </Card>
  );
};

export default DishCard;
