import React, { ReactElement } from 'react';
import { getHoursAndMinutesFromDate } from 'octa-front-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import DeliveryTimeStyles from './styles';

const roundTime = (date: Date): Date => {
  const hours = date.getHours();
  const minutes = Math.round((hours * 60 + date.getMinutes()) / 10) * 10;
  date.setHours(Math.floor(minutes / 60));
  date.setMinutes(minutes % 60);

  return date;
};

interface IProps {
  date: string;
  hasDelivery?: boolean;
}

const DeliveryTime = (props: IProps) => {
  const { hasDelivery } = props;
  const date = new Date(props.date);

  return (
    <DeliveryTimeStyles.Container>
      <DeliveryTimeStyles.Icon>
        <FontAwesomeIcon icon={faClock} />
      </DeliveryTimeStyles.Icon>

      <DeliveryTimeStyles.TextContent>
        <p>{hasDelivery ? 'Entrega prevista' : 'Estará pronto'} até:</p>

        <strong>{getHoursAndMinutesFromDate(roundTime(date))}</strong>
      </DeliveryTimeStyles.TextContent>
    </DeliveryTimeStyles.Container>
  );
};

export default DeliveryTime;
