// @ts-nocheck

import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import Form from 'octa-front-sdk/dist/components/Form';
import Input from 'octa-front-sdk/dist/components/Input';
import {
  validatePassword,
  maskPhoneNumber,
  validateName,
  cleanPhoneNumber,
} from 'octa-front-sdk';
import InputType from 'octa-front-sdk/dist/enums/InputType';
import FormContext from 'octa-front-sdk/dist/contexts/FormContext';
import Button from 'octa-front-sdk/dist/components/Button';
import {
  Link,
  withRouter,
  RouteComponentProps,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Color from 'octa-front-sdk/dist/enums/Color';
import Divider from 'octa-front-sdk/dist/components/Divider';
import { AxiosResponse } from 'axios';
import GlobalContext from '../../contexts/GlobalContext';
import Auth from '../../services/Auth';

import { validateEmail } from '../../utils/validateEmail';

import Loader from '../../components/Loader';
import Page from '../../components/Page';
import RenderOrgCustomInput, {
  IRenderCustomInputRef,
} from '../../components/RenderOrgCustomInput';
import { validatePhone } from '../../utils/validatePhone';

import OrganizationContext from '../../contexts/OrganizationContext';

import SignUpPageStyles from './styles';

const SignUpPage = (props: RouteComponentProps) => {
  const customInputRef = useRef<IRenderCustomInputRef>(null);
  const history = useHistory();
  const {
    setLoggedIn,
    triggerRedirect,
    guestUUID,
    setGuestUUID,
    setToastMessage,
  } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  const [isLoading, setLoading] = useState();
  const [user, setUser] = useState({
    firstName: '',
    email: '',
    phone: '',
    password: '',
  });
  const { match } = props;
  const { state } = useLocation();

  useEffect((): void => {
    const firstName = localStorage.getItem('firstName');
    const phone = localStorage.getItem('phone');

    if (firstName && phone) {
      setUser({
        firstName,
        phone,
        email: '',
        password: '',
      });
    }
  }, []);

  const shouldPromote = !!state && state.shouldPromote;

  if (shouldPromote) document.body.style.overflow = 'unset';

  const userWasCreatedSuccessfully = (): void => {
    setLoggedIn(true);

    triggerRedirect();

    if (shouldPromote) {
      localStorage.removeItem('guestUUID');

      setGuestUUID(undefined);
    }

    setToastMessage('Cadastro realizado!');
    if (organizationInfo) history.push(`/${organizationInfo.urlSuffix}`);
  };

  return (
    <Page title="Cadastrar" shouldCenterTitle>
      <SignUpPageStyles.Container>
        {isLoading && <Loader />}

        <Form
          onSubmit={(values): void => {
            setLoading(true);

            const customInputsApiData = customInputRef.current?.getValues();

            if (!customInputsApiData)
              return setToastMessage('Preencha todos os campos corretamente!');

            let { firstName, email, phone, password } = values;

            firstName = firstName.toString().trim();
            email = email.toString().trim();
            phone = phone.toString();
            password = password.toString();

            setUser({
              firstName,
              email,
              phone,
              password: '',
            });

            const payload = {
              firstName,
              userName: email,
              email,
              phone: cleanPhoneNumber(phone.toString()),
              password,
              data: customInputsApiData,
            };

            ((): Promise<AxiosResponse> =>
              shouldPromote
                ? Auth.promoteGuest({
                  simplifiedUserName: guestUUID,
                  ...payload,
                })
                : Auth.register(payload))()
              .then(userWasCreatedSuccessfully)
              .finally((): void => setLoading(false));
          }}
          initialValues={{ ...user }}
        >
          <Input name="firstName" label="Nome" validator={validateName} />

          <Input name="email" label="Email" validator={validateEmail} />

          <Input
            name="phone"
            label="Telefone"
            type={InputType.Telephone}
            validator={validatePhone}
            mask={maskPhoneNumber}
          />

          <RenderOrgCustomInput ref={customInputRef} />

          {process.env.REACT_APP_IS_APPLE && (
            <SignUpPageStyles.PhoneWarning>
              Precisamos do telefone para entrar em contato somente em caso de
              dúvida sobre o pedido
            </SignUpPageStyles.PhoneWarning>
          )}

          <Input
            name="password"
            label="Senha"
            validator={validatePassword}
            type={InputType.Password}
          />

          <FormContext.Consumer>
            {({ hasErrors }): ReactElement => (
              <Button
                text="Cadastrar"
                isDisabled={hasErrors}
                color={Color.MeuDeliveryCyan}
              />
            )}
          </FormContext.Consumer>
        </Form>

        <SignUpPageStyles.LoginCall>
          Já tem uma conta? <br />{' '}
          <Link to={`/${match.url.split('/')[1]}/perfil/entrar`}>Entrar</Link>
        </SignUpPageStyles.LoginCall>
      </SignUpPageStyles.Container>
    </Page>
  );
};

export default withRouter(SignUpPage);
