import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { Container, Label } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  hint?: string;
  placeholder?: string;
  mask?: (value: string) => string;
}

export default function Input({
  name,
  label,
  placeholder,
  mask,
  hint,
  ...rest
}: IProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasValue, setHasValue] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputMask = (value: string) => {
    if (inputRef.current && mask) {
      inputRef.current.value = mask(value);
    }
  };

  const handleInputBlur = useCallback(() => {
    setHasValue(!!inputRef.current?.value);
    if (placeholder) setHasValue(true);
  }, [placeholder]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        setHasValue(!!value);
        ref.current.value = value;
      },
      clearValue: ref => {
        setHasValue(false);
        ref.current.value = '';
      },
    });

    handleInputBlur();
  }, [fieldName, handleInputBlur, registerField]);

  return (
    <Container hasValue={!!hasValue} hasError={!!error}>
      <input
        id={fieldName}
        ref={inputRef}
        onChange={o => handleInputMask(o.target.value)}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...rest}
      />

      <Label hasError={!!error} htmlFor={fieldName}>
        {label}
      </Label>
      {error && <span className='error'>{error}</span>}
      {hint && <span className='hint'>{hint}</span>}
    </Container>
  );
}
