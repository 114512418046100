import React, { useContext } from 'react';

import FbPixel from '../../services/FbPixel';

import { useLoyalty } from '../../hooks/Loyalty';

import { Product } from '../../types/Product';
import { Prize } from '../../types/Prize';

import { Container } from './styles';

import DishCard from './DishCard';
import OrganizationContext from "../../contexts/OrganizationContext";

interface IProps {
  dishes: Product[];
  openWizard: () => unknown;
  setCurrentDish: React.Dispatch<
    React.SetStateAction<Partial<Product> | undefined>
  >;
}

interface IDishesOrdered {
  [key: string]: Product[];
}

const Dishes = (props: IProps) => {
  const { dishes, openWizard, setCurrentDish } = props;

  const { organizationInfo } = useContext(OrganizationContext)
  const { loyaltyProgram, memberPoints } = useLoyalty();

  const result: IDishesOrdered = dishes.reduce((r, a) => {
    r[a.category] = r[a.category] || [];
    r[a.category].push(a);
    return r;
  }, Object.create(null));

  const IS_CARD_COMPACT = organizationInfo?.productsDisplay === "Compact"

  return (
    <>
      {Object.keys(result).map((category, index) => (
        <Container id={category} key={index} isCompact={IS_CARD_COMPACT}>
          <div className="list-header">
            <h3>{category}</h3>
          </div>

          <ul>
            {result[category].map(dish => {
              const { id, category } = dish;

              let prizeIndex;
              let hasPoints = false;

              if (!!loyaltyProgram && !!memberPoints) {
                prizeIndex = loyaltyProgram.prizes.findIndex(
                  (prize: Prize): boolean => parseInt(prize.productId) === id,
                );

                if (prizeIndex > -1) {
                  hasPoints =
                    memberPoints.validPoints >=
                    loyaltyProgram.prizes[prizeIndex].priceInPoints;
                }
              }

              return (
                <DishCard
                  onClickCard={(): void => {
                    FbPixel.trackEvent('ViewContent', {
                      content_ids: [dish.id],
                      content_type: 'product',
                      value: dish.displayPrice.toFixed(2),
                      currency: 'BRL',
                    });

                    setCurrentDish({ ...dish });

                    openWizard();
                  }}
                  dish={dish}
                  hasPoints={hasPoints}
                />
              );
            })}
          </ul>
        </Container>
      ))}
    </>
  );
};

export default Dishes;
