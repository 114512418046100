import { InputHTMLAttributes } from 'react';

import { CustomInputContainer } from './style';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  error?: string;
  label?: string;
}

const CustomInput = ({ error, name, label, ...rest }: IProps) => {
  return (
    <CustomInputContainer>
      {label && <label htmlFor={name}>{label}</label>}
      <input id={name} {...rest} />
      {error && <span className="error-span">{error}</span>}
    </CustomInputContainer>
  );
};

export default CustomInput;
