import { useContext, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page';
import LoginPageStyles from './styles';
import GlobalContext from '../../contexts/GlobalContext';
import LoginForm from '../../components/LoginForm';

const LoginPage = (props: RouteComponentProps) => {
  const { triggerRedirect, redirectTarget } = useContext(GlobalContext);

  const { history, match } = props;

  const onLogin = useCallback((): void => {
    if (redirectTarget) {
      triggerRedirect();
    } else {
      history.push(`/${match.url.split('/')[1]}`);
    }
  }, [redirectTarget, history, match.url, triggerRedirect]);

  return (
    <Page title="Entrar" shouldCenterTitle>
      <LoginPageStyles.Container>
        <LoginForm onLogin={onLogin} />
      </LoginPageStyles.Container>
    </Page>
  );
};

export default LoginPage;
