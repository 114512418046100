import styled from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';

const Title = styled.h1`
  color: ${Color.Grey700};
  font-size: ${FontSize.Normal};
`;

const FreeDeliveryMessage = styled.div`
  background-color: ${Color.Blue100};
  border: 1px solid ${Color.Blue200};
  border-radius: 5px;
  color: ${Color.Blue700};
  padding: 10px;
  margin-bottom: 20px;
  font-size: ${FontSize.Small};
  text-align: center;
`;

const NeighbourhoodsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid ${Color.Grey300};

  thead,
  tr {
    border: 0 solid transparent;
  }

  thead {
    border-bottom-width: 10px;
  }

  tr {
    border-bottom-width: 5px;
  }

  th,
  td {
    color: ${Color.Grey700};
    width: 50%;

    &:last-of-type {
      text-align: right;
    }
  }

  th {
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
    font-size: ${FontSize.SmallX};
  }

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  table:last-of-type{
    border-bottom: none;
  }

  > div {
    margin: 1.5rem 0;
  }
`

const DeliveryContentStyles = {
  Title,
  FreeDeliveryMessage,
  NeighbourhoodsTable,
  Container
};

export default DeliveryContentStyles;
