import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  ReactElement,
  useEffect,
} from 'react';

import {
  geocodeByAddress,
  getLatLng,
  geocodeByLatLng,
} from 'react-google-places-autocomplete';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import Configs from 'octa-front-sdk/dist/Configs';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import WizardContext from 'octa-front-sdk/dist/contexts/WizardContext';
import { withStepProps } from 'octa-front-sdk/dist/components/Wizard/Step';

import { maskPostalCode } from 'octa-front-sdk';
import GlobalContext from '../../../contexts/GlobalContext';
import OrganizationContext from '../../../contexts/OrganizationContext';

import { Address } from '../../../types/Address';
import { IDeliveryRegion } from '../../../types/DeliveryRegion';

import { calcCartItemPrice } from "../../../Helpers";

import Input from '../../Input';
import BtnWithLoad from '../../BtnWithLoad';
import SelectInput from '../../SelectInput';

import TargetIcon from '../../../assets/target-icon.png';
import WhatsAppIcon from '../../../assets/whatsapp-icon.svg';

import statesPick from '../../../utils/statesPick';

import {
  Container,
  ContainerAddressStep,
  ContainerHeader,
  ContainerHeaderH1,
  BtnWrapper,
  BtnCancel,
  ContainerHeaderSeparator,
  ContainerHeaderBtnPinMap,
  ContainerNotFound,
  ContainerNotFoundHeader,
  ContainerNotFoundHeaderH1,
  ContainerNotFoundHeaderSpan,
  ContainerNotFoundAddress,
  ContainerNotFoundAddressStreet,
  ContainerNotFoundAddressNeighborhood,
  ContainerNotFoundFooter,
  ContainerNotFoundFooterSpan,
  ContainerNotFoundFooterWhatsButton,
  AddresNicknameContainer,
} from './styles';
import { usePopup } from '../../Popup/context';
import { handleApiErrors } from '../../../utils/handleApiErrors';
import { MdEdit, MdHome, MdWork } from 'react-icons/md';

interface IProps {
  currentAddress?: Address;
  onSave?: (newAddress?: Address) => void;
  onRemove?: () => void;
  isEditing?: boolean;
  shouldGoBackOnSave?: boolean;
  address?: Address;
}

interface IPositionProps {
  coords: {
    latitude: number;
    longitude: number;
  };
}

interface Coords {
  lat: number;
  lng: number;
}

const AddressStep = (props: IProps) => {
  const { isEditing, shouldGoBackOnSave, onRemove, onSave, address } = props;

  const formRef = useRef<FormHandles>(null);

  const { organizationInfo } = useContext(OrganizationContext);
  const { goToPreviousStep } = useContext(WizardContext);
  const {
    cartItems,
    chosenAddress,
    setChosenAddress,
    setToastMessage,
  } = useContext(GlobalContext);
  const { isConfirmed } = usePopup();

  const [showNotFound, setShowNotFound] = useState(false);
  const [addressNotFound, setAddressNotFound] = useState<Address>();
  const [coords, setCoords] = useState<Coords | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState(false);
  const [neighbourhoodSelected, setNeighbourhoodSelected] = useState<
    IDeliveryRegion | undefined
  >();
  const [nickname, setNickname] = useState('Casa');

  const [postalCode, setPostalCode] = useState('');

  const hasNeighbourhood = organizationInfo!.neighbourhoods!.length > 0;
  const hasDeliveryRadius = organizationInfo!.deliveryRadius.length > 0;

  const withoutAccents = (data: string) => {
    return data
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .toLowerCase();
  };

  const getCoordsByGoogle = useCallback(
    async (address: string): Promise<Coords> => {
      let coordinates: Coords = { lat: -14.206914, lng: -51.915471 };

      if (hasDeliveryRadius && coords) coordinates = coords;

      if (hasDeliveryRadius && !coords) {
        const geocodeResponse = await geocodeByAddress(address);

        const { lat, lng } = await getLatLng(geocodeResponse[0]);

        coordinates = { lat, lng };
      }

      return coordinates;
    },
    [coords, hasDeliveryRadius],
  );

  const getAddressValue = (addressComponent: any, addressName: string) => {
    return addressComponent.find((c: { types: string | string[] }): boolean =>
      c.types.includes(addressName),
    );
  };

  const formatResponseIntoAddress = (data: any) => {
    const address: Address = {};

    if (data.formatted_address)
      address.freeformAddress = data.formatted_address;

    if (data.address_components) {
      const street = getAddressValue(data.address_components, 'route');
      const number = getAddressValue(data.address_components, 'street_number');
      const neighbourhood = getAddressValue(
        data.address_components,
        'sublocality_level_1',
      );
      const state = getAddressValue(
        data.address_components,
        'administrative_area_level_1',
      );
      const city = getAddressValue(
        data.address_components,
        'administrative_area_level_2',
      );
      const postalcode = getAddressValue(
        data.address_components,
        'postal_code',
      );

      if (street) address.street = street.long_name;
      if (number) address.number = number.long_name;
      if (neighbourhood) address.neighbourhood = neighbourhood.long_name;
      if (state) address.state = state.short_name;
      if (city) address.city = city.long_name;
      if (postalcode) address.postalcode = postalcode.long_name;
    }

    address.country = 'BR';
    address.location = {
      latitude: data.geometry.location.lat,
      longitude: data.geometry.location.lng,
    };

    return address;
  };

  const handleGetUserPosition = () => {
    if (!process.env.REACT_APP_IS_APPLE && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: IPositionProps) => {
        const userPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        setCoords(userPosition);

        try {
          geocodeByLatLng(userPosition).then(response => {
            const formatted = formatResponseIntoAddress(response[0]);

            const neighbourhoodFound = checkIfNeighbourhoodMatch(
              formatted.neighbourhood,
            );

            if (neighbourhoodFound)
              formatted.neighbourhood = neighbourhoodFound.name;

            formatted.complement = '';
            formatted.reference = '';
            formatted.postalcode = '';

            formRef.current?.setData(formatted);
          });
        } catch (error) {
          const messageToastError = 'Não foi possível pegar a sua localização.';

          setToastMessage(messageToastError);
        }
      });
    } else {
      const messageToastError =
        'Necessário habilitar a geolocalização em seu navegador.';

      setToastMessage(messageToastError);
    }
  };

  const validateField = (value: string, fieldName: string, msg: string) => {
    if (!value) formRef.current?.setFieldError(fieldName, msg);

    return !!value;
  };

  const validateFormData = useCallback((formData: Address) => {
    let isValid = true;

    isValid = validateField(formData.name!, 'name', 'Informar o apelido');
    isValid = validateField(formData.street!, 'street', 'Informar a rua');
    isValid = validateField(formData.number!, 'number', 'Informar o número');
    isValid = validateField(formData.city!, 'city', 'Informar a cidade');
    isValid = validateField(formData.state!, 'state', 'Informar o estado');
    isValid = validateField(
      formData.neighbourhood!,
      'neighbourhood',
      'Informar o bairro',
    );

    return isValid;
  }, []);

  const renderSelectNeighbourhood = () => {
    const showNeighbourhoodSelect = organizationInfo!.neighbourhoods.length > 0;

    return showNeighbourhoodSelect ? (
      <SelectInput name="neighbourhood" label="Bairro*">
        <option value="" disabled selected>
          Selecione o bairro
        </option>
        {organizationInfo?.neighbourhoods
          .sort((a, b): number => a.name.localeCompare(b.name))
          .map(
            (n, i): ReactElement => (
              <option key={`option-${i}`} value={n.name}>
                {n.name}
              </option>
            ),
          )}
      </SelectInput>
    ) : (
      <Input name="neighbourhood" label="Bairro*" />
    );
  };

  const checkIfNeighbourhoodMatch = (
    neighbourhoodReceived: string | undefined,
  ) => {
    if (hasNeighbourhood && neighbourhoodReceived) {
      const { neighbourhoods } = organizationInfo!;

      const neighbourhoodWithoutAccents = withoutAccents(neighbourhoodReceived);

      const matched = neighbourhoods.find(nb => {
        const nbWithoutAccents = withoutAccents(nb.name);

        return nbWithoutAccents === neighbourhoodWithoutAccents;
      });

      if (matched) setNeighbourhoodSelected(matched);

      return matched;
    }
  };

  const handleChangeAddress = () => {
    setShowNotFound(false);
  };

  const checkIfIsServed = useCallback(
    async (data: Address, coordinates: Coords) => {
      const response = await RequestHandler.post(
        'user/address/deliverydetails',
        {
          neighbourhood:
            neighbourhoodSelected?.name || data.neighbourhood || 'sem bairro',
          state: data.state,
          location: {
            longitude: coordinates.lng,
            latitude: coordinates.lat,
          },
        },
      );

      return response.data.isServed;
    },
    [neighbourhoodSelected?.name],
  );

  const handleSendOrderToWhatsApp = async () => {
    const total = cartItems.reduce((sum, current): number => sum + calcCartItemPrice(current), 0);

    let orders = cartItems.reduce((orderInfo, current) => {
      return (orderInfo += `${current.amount}x - ${current.product.name},`);
    }, '');

    const ordersSeparator = orders.split(',');
    orders = ordersSeparator.join('\n');

    const phone = `55${organizationInfo?.phone}`;

    const address = `${addressNotFound!.street}, ${addressNotFound!.number}, ${addressNotFound!.neighbourhood
      } - ${addressNotFound!.state}`;

    const separator = '------------------------------';
    const messageHello = `Olá, ${organizationInfo?.name}!\nEu tentei realizar um pedido, mas não consegui pelo site.\n\n`;
    const messageAddress = `Poderiam confirmar se vocês entregam no meu endereço?!\n*${address}*\n\n${separator}\n\n`;
    const messageOrders = `_Itens do meu pedido:_\n\n${orders}\n${separator}\n\n`;
    const messageTotal = `Valor total do pedido: R$ ${total}`;

    let message = `${messageHello}${messageAddress}${messageOrders}${messageTotal}`;

    message = window.encodeURIComponent(message);

    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=${message}`,
      '_blank',
      'rel=”noreferrer noopener”',
    );
  };

  useEffect(() => {
    if (address) {
      if (address.postalcode) {
        const postalCodeFormatted = maskPostalCode(address.postalcode);

        setPostalCode(postalCodeFormatted);

        address.postalcode = postalCodeFormatted;
      }

      if (address.name) {
        setNickname(address.name);
      }

      formRef.current?.setData(address);
    }
  }, [address]);

  const handleSubmit = useCallback(
    async (data: Address) => {
      const formattedData = { ...data, name: data.name || nickname };

      if (!validateFormData(formattedData)) return;

      setIsLoading(true);

      try {
        const addressUser = `${data.number} ${data.street} ${data.city} ${data.state}`;

        const coordinates = await getCoordsByGoogle(addressUser);

        const isServed = await checkIfIsServed(data, coordinates);

        if (!isServed) {
          setAddressNotFound({ ...data });
          setShowNotFound(true);
        }

        // @ts-ignore
        formattedData.postalCode = postalCode?.replace(/\.|-/g, '');
        formattedData.country = 'BR';
        formattedData.location = {
          latitude: coordinates.lat,
          longitude: coordinates.lng,
        };

        if (isServed) {
          const response = await RequestHandler.request({
            method: isEditing ? 'PUT' : 'POST',
            url: isEditing ? `user/address/${address?.id}` : 'user/address',
            baseURL: Configs.REACT_APP_API_URL,
            data: formattedData,
          });

          const { id } = response.data;

          if (onSave) onSave({ ...data, id: id || address!.id });

          if (shouldGoBackOnSave) goToPreviousStep();
        }
      } catch (error) {
        const errs = handleApiErrors(error);

        if (errs.length > 0) {
          setToastMessage(errs[0]);
        }

        setToastMessage('Não foi possível salvar o seu endereço.');
      } finally {
        setIsLoading(false);
      }
    },
    [
      validateFormData,
      getCoordsByGoogle,
      checkIfIsServed,
      postalCode,
      isEditing,
      address,
      onSave,
      shouldGoBackOnSave,
      goToPreviousStep,
      setToastMessage,
      nickname,
    ],
  );

  const removeAddress = async () => {
    const confirmation = await isConfirmed('Confirmar remoção de endereço?');
    if (confirmation) {
      const { id } = address!;

      setIsLoadingDeleteBtn(true);

      RequestHandler.delete(`user/address/${id}`)
        .then((): void => {
          const storedAddress = localStorage.getItem('chosenAddress');

          if (id && storedAddress && JSON.parse(storedAddress).id === +id)
            localStorage.removeItem('chosenAddress');
        })

        .finally((): void => {
          setIsLoadingDeleteBtn(false);
          if (onRemove) onRemove();
        });

      if (chosenAddress && JSON.stringify(chosenAddress.id) === address!.id)
        setChosenAddress(undefined);
    }
  };

  const onBlurZipCode = async (value: string) => {
    const zipCode = value?.replace(/[^0-9]/g, '');

    if (zipCode?.length !== 8) return;

    fetch(`https://prd-cep-api.azure-api.net/api/address/${zipCode}`)
      .then(res => res.json())
      .then(data => {
        const addressFormatted = {
          name: '',
          postalcode: maskPostalCode(zipCode),
          street: data.street,
          number: '',
          complement: '',
          neighbourhood: data.neighbourhood,
          reference: '',
          city: data.city,
          state: data.state,
        };

        const neighbourhoodFound = checkIfNeighbourhoodMatch(
          addressFormatted.neighbourhood,
        );

        if (neighbourhoodFound)
          addressFormatted.neighbourhood = neighbourhoodFound.name;

        formRef.current?.setData(addressFormatted);
      });
  };

  const handlePostalcode = (value: string) => {
    const postalCodeFormatted = maskPostalCode(value);

    setPostalCode(postalCodeFormatted);
  };

  return (
    <Container>
      {showNotFound ? (
        <ContainerNotFound>
          <ContainerNotFoundHeader>
            <ContainerNotFoundHeaderH1>
              Ops! <br /> Não encontramos o seu endereço!
            </ContainerNotFoundHeaderH1>
            <ContainerNotFoundHeaderSpan>
              Confira se está tudo certinho e clique em <br />
              <strong>alterar endereço</strong> caso precise editar
            </ContainerNotFoundHeaderSpan>
          </ContainerNotFoundHeader>

          <ContainerNotFoundAddress>
            {addressNotFound ? (
              <>
                <ContainerNotFoundAddressStreet>
                  {addressNotFound.street}, {addressNotFound.number}
                </ContainerNotFoundAddressStreet>
                <ContainerNotFoundAddressNeighborhood>
                  {addressNotFound.neighbourhood}
                </ContainerNotFoundAddressNeighborhood>
              </>
            ) : (
              <h1>Não foi possível pegar o seu endereço.</h1>
            )}
          </ContainerNotFoundAddress>

          <BtnWithLoad
            onClick={handleChangeAddress}
            type="submit"
            name="Alterar endereço"
          />

          <ContainerNotFoundFooter>
            <ContainerNotFoundFooterSpan>
              Não conseguiu encontrar o seu endereço? <br /> Calma, nós vamos te
              ajudar! <br /> Envie o seu pedido clicando no botão abaixo para
              confirmarmos se seu endereço é atendido por nosso restaurante.
            </ContainerNotFoundFooterSpan>
            <ContainerNotFoundFooterWhatsButton
              onClick={handleSendOrderToWhatsApp}
              type="button"
            >
              <img src={WhatsAppIcon} alt="Ícone WhatsApp" />
              Enviar WhatsApp
            </ContainerNotFoundFooterWhatsButton>
          </ContainerNotFoundFooter>
        </ContainerNotFound>
      ) : (
        <ContainerAddressStep>
          <ContainerHeader>
            <div>
              <ContainerHeaderH1>
                Insira o endereço de entrega
              </ContainerHeaderH1>
            </div>

            {!process.env.REACT_APP_IS_APPLE && (
              <ContainerHeaderBtnPinMap
                onClick={handleGetUserPosition}
                type="button"
              >
                <img src={TargetIcon} alt="Ícone Localização" />
                Usar localização do celular
              </ContainerHeaderBtnPinMap>
            )}

            <ContainerHeaderSeparator>
              ou digite o endereço abaixo:
            </ContainerHeaderSeparator>
          </ContainerHeader>

          <Form ref={formRef} onSubmit={handleSubmit} noValidate placeholder={''}>
            <Input
              name="postalcode"
              label="CEP (Opcional)"
              value={postalCode}
              onChange={e => handlePostalcode(e.target.value)}
              onBlur={e => {
                onBlurZipCode(e.target.value);
              }}
            />

            <Input name="street" label="Rua*" />

            <div className="input-inline">
              <Input name="number" label="Número*" />
              <Input name="complement" label="Complemento (Opcional)" />
            </div>
            <div className="input-inline-reverse">
              <Input name="reference" label="Ponto de referência (Opcional)" />
              {renderSelectNeighbourhood()}
            </div>
            <div className="input-inline-reverse">
              <Input name="city" label="Cidade*" />
              <SelectInput name="state" label="Estado*">
                <option value="" disabled selected>
                  UF
                </option>
                {statesPick.map(
                  (state, index): ReactElement => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ),
                )}
              </SelectInput>
            </div>
            <AddresNicknameContainer>
              <h2>Deseja favoritar o endereço como:</h2>
              <div className='btn-container'>
                <button
                  type='button'
                  className={nickname === "Casa" ? "active" : ""}
                  onClick={() => setNickname('Casa')}>
                  <MdHome size='2rem' />Casa
                </button>
                <button
                  type='button'
                  className={nickname === "Trabalho" ? "active" : ""}
                  onClick={() => setNickname('Trabalho')}>
                  <MdWork size='2rem' />Trabalho
                </button>
                <button
                  type='button'
                  className={(nickname !== "" && nickname !== 'Casa' && nickname !== "Trabalho") ? "active" : ""}
                  onClick={() => setNickname('Outros')}>
                  <MdEdit size='2rem' />Outro
                </button>
              </div>

              {(nickname !== "" && nickname !== 'Casa' && nickname !== "Trabalho") &&
                (<div>
                  <Input name="name"
                    defaultValue={nickname}
                    label="Apelido do endereço*" />
                </div>)
              }
            </AddresNicknameContainer>
            <BtnWrapper>
              <BtnWithLoad
                type="submit"
                name={`${isEditing ? 'Editar' : 'Entregar neste'} endereço`}
                loading={isLoading}
              />

              {address ? (
                <BtnWithLoad
                  type="button"
                  name="Apagar endereço"
                  className="delete-btn"
                  onClick={removeAddress}
                  loading={isLoadingDeleteBtn}
                />
              ) : (
                <BtnCancel type="button" onClick={() => goToPreviousStep()}>
                  Cancelar
                </BtnCancel>
              )}
            </BtnWrapper>
          </Form>
        </ContainerAddressStep>
      )}
    </Container>
  );
};

export default withStepProps(AddressStep);
