import styled from 'styled-components';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

const Date = styled.h1`
  margin: 0 0 15px;
  font-size: ${FontSize.NormalX};
`;

const Product = styled.li`
  color: ${Color.Grey700};
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductsList = styled.ul`
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
`;

const Price = styled.span`
  color: ${Color.Green500};
  font-weight: bold;
`;

const Status = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${Color.Grey700};
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const Card = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(0, 0, 0, 0.16);
  padding: 10px;
  cursor: pointer;
  transition: transform 300ms ease;
  background-color: ${Color.White};

  &:hover {
    transform: scale(1.025);
  }

  ${MediaQuery.BiggerThanSmall} {
    flex-basis: 45%;
    margin-bottom: 5%;
  }
`;

const List = styled.ul`
  padding: 0;
  list-style: none;

  ${MediaQuery.BiggerThanSmall} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  ${ButtonContainer} {
    margin-top: 30px;
  }
`;

const OrdersPageStyles = {
  Date,
  Product,
  ProductsList,
  Price,
  Status,
  BottomWrapper,
  Card,
  List,
  Container,
};

export default OrdersPageStyles;
