import { useContext } from "react";
import { maskCardNumber, maskExpirationDate, validateCardNumber, validateCVV, validateEmail, validateExpirationDate, validateRequired } from "octa-front-sdk";


import { ICardInfo } from "..";

import OrganizationContext from "../../../../../contexts/OrganizationContext";
import PaymentOptionsStyles from "../styles";
import Input from "../../../../Input";
import { Radio } from "../../../../Radio";

interface IProps {
  setOnlinePaymentType: (value: 'Pix' | 'Card' | undefined) => void;
  onlinePaymentType: 'Pix' | 'Card' | undefined;
}

export function OnlinePaymentMethods({ setOnlinePaymentType, onlinePaymentType }: IProps) {
  const { organizationInfo } = useContext(OrganizationContext);

  const organizationOnlinePayments = organizationInfo?.paymentConfiguration?.paymentMethods?.map(pm => pm.paymentMethod);

  const emailValidator = (value: string) => onlinePaymentType === 'Pix' ? validateEmail(value) : undefined

  return (
    <PaymentOptionsStyles.PaymentMethods>
      {organizationOnlinePayments?.includes('Pix') && (
        <PaymentOptionsStyles.OnlinePaymentMethod>
          <div>
            <Radio
              label="Pagar com PIX"
              name="onlinePaymentType"
              value='Pix'
              id='Pix'
              onChange={() =>
                setOnlinePaymentType('Pix')
              }
              checked={onlinePaymentType === 'Pix'}
            />
          </div>

          {
            onlinePaymentType === 'Pix' && (
              <div>
                <Input
                  type="email"
                  name="pixEmail"
                  label="Email do pagador"
                />
              </div>
            )
          }
        </PaymentOptionsStyles.OnlinePaymentMethod>
      )}

      {organizationOnlinePayments?.includes('Card') && (<PaymentOptionsStyles.OnlinePaymentMethod>
        <Radio
          name="onlinePaymentType"
          value='Card'
          id='Card'
          onChange={(e) => setOnlinePaymentType('Card')}
          label={`Pagar com cartão de crédito`}
          checked={onlinePaymentType === 'Card'}
        />

        {onlinePaymentType === "Card" && (
          <div>
            <Input
              name="holderName"
              label="Nome do titular"
            />

            <Input
              type="email"
              name="email"
              label="Email do titular"
            />

            <Input
              name="cardNumber"
              label="Número do cartão"
            />

            <PaymentOptionsStyles.InputsWrapper>
              <Input
                name="expirationDate"
                label="Validade"
                mask={maskExpirationDate}
                hint="MM/AA"
              />

              <Input
                name="cvv"
                label="CVV"
              />
            </PaymentOptionsStyles.InputsWrapper>
          </div>
        )}
      </PaymentOptionsStyles.OnlinePaymentMethod>)}


    </PaymentOptionsStyles.PaymentMethods>
  );
}
