import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';

const Slice = styled.div`
  width: 50%;
  height: 50%;
  background-color: ${Color.Grey300};
  border: 1px solid ${Color.White};
  box-sizing: border-box;

  &:nth-of-type(1) {
    border-radius: 100% 0 0 0;
  }

  &:nth-of-type(2) {
    border-radius: 0 100% 0 0;
    animation: slide 1000ms ease infinite;

    @keyframes slide {
      0% {
        transform: translate(0, 0);
      }

      50% {
        transform: translate(10%, -10%);
      }

      100% {
        transform: translate(0, 0);
      }
    }
  }

  &:nth-of-type(3) {
    border-radius: 0 0 0 100%;
  }

  &:nth-of-type(4) {
    border-radius: 0 0 100% 0;
  }
`;

const Pizza = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  flex-wrap: wrap;
  animation: spin 5000ms ease infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-top: 10%;

  p {
    color: ${Color.Grey400};
  }
`;

const PizzaLoaderStyles = { Slice, Pizza, Container };

export default PizzaLoaderStyles;
