import { useEffect, useState, ReactElement } from 'react';
import { Normalize } from 'styled-normalize';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import { AxiosRequestConfig } from 'axios';
import Configs from 'octa-front-sdk/dist/Configs';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import GlobalStyle from './assets/GlobalStyle';
import AppRouter from './components/AppRouter';
import { OrganizationInfo } from './types/OrganizationInfo';
import OrganizationContext from './contexts/OrganizationContext';
import PizzaLoader from './components/PizzaLoader';
import NotFound from './components/NotFound';
import FbPixel from './services/FbPixel';
import GoogleAnalytics from './services/GoogleAnalytics';

import ImageFullscreen from './components/ImageFullscreen';
import { InitialPopup } from './components/InitialPopup';
import VersionWarning from './components/VersionWarning';
import { PopupProvider } from './components/Popup/context';

interface PopupIProps {
  date: string;
  url: string;
  id: number | string;
}

interface IConfig {
  facebookPixelId: string;
  googleTagManager?: string;
}

const App = () => {
  const [suffix, setSuffix] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>();
  const [configs, setConfigs] = useState<IConfig>();
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect((): void => {
    if (organizationInfo) {
      RequestHandler.interceptors.request.use(
        // @ts-ignore
        (config: AxiosRequestConfig): AxiosRequestConfig => {
          if (config.baseURL === Configs.REACT_APP_API_URL) {
            config.headers = {
              ...config.headers,
              'X-Org-Id': organizationInfo.id,
            };
          }

          return config;
        },
      );
    }
  }, [organizationInfo]);

  useEffect((): void => {
    const element = document.getElementById('manifest-file');

    if (organizationInfo && element) {
      const { name, headerColor, logoUrl, facebookAppId } = organizationInfo;
      const manifest = {
        short_name: name,
        name: `${name} | Levilos`,
        icons: [
          {
            src: 'favicon.ico',
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
          },
          {
            src: logoUrl,
            type: 'image/png',
            sizes: '192x192',
          },
          {
            src: logoUrl,
            type: 'image/png',
            sizes: '512x512',
          },
        ],
        start_url: `./${organizationInfo.urlSuffix}`,
        display: 'standalone',
        theme_color: headerColor,
        background_color: headerColor,
      };
      const blob = new Blob([JSON.stringify(manifest)], {
        type: 'application/json',
      });

      if (element) {
        element.setAttribute('href', URL.createObjectURL(blob));
      }

      // TODO: move to SDK
      const gtmTag = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
      if (gtmTag) GoogleAnalytics.start(gtmTag);

      if (configs && configs.facebookPixelId)
        FbPixel.start(configs.facebookPixelId);

      if (configs && configs.googleTagManager)
        TagManager.initialize({
          gtmId: configs.googleTagManager,
        });

      if (facebookAppId) FbPixel.start(facebookAppId);
    }
  }, [organizationInfo, configs]);

  useEffect((): void => {
    const pathname = decodeURI(window.location.pathname.split('/')[1]);

    if (pathname) {
      setSuffix(pathname);
      setLoading(true);

      Promise.all([
        RequestHandler.get(`organization/${pathname}`).then(
          (response): void => {
            const organizationInfo = { ...response.data };

            setOrganizationInfo(organizationInfo);

            TagManager.initialize({
              gtmId: organizationInfo.googleTagManager,
            });

            const favicon = document.getElementById('favicon');
            favicon?.remove();
          },
        ),
        RequestHandler.get(
          `organization/${pathname}/status`,
        ).then((response): void => setIsAvailable(response.data.isAvailable)),
        RequestHandler.get('config/').then((response): void =>
          setConfigs(response.data),
        ),
      ]).finally((): void => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <PopupProvider>
      <OrganizationContext.Provider
        value={{
          organizationInfo,
          setOrganizationInfo,
          isAvailable,
          setIsAvailable,
        }}
      >
        <Helmet>
          <link rel="icon" href={organizationInfo?.logoUrl} />
          <script
            type="text/javascript"
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places`}
          />
        </Helmet>

        <InitialPopup />

        {organizationInfo && <VersionWarning />}

        <Normalize />

        <GlobalStyle />

        {isLoading ? (
          <PizzaLoader message="Buscando estabelecimento..." />
        ) : (
          <BrowserRouter>
            <Switch>
              {organizationInfo && (
                <Route path={`/${suffix}`}>
                  <AppRouter />
                </Route>
              )}

              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        )}
      </OrganizationContext.Provider>
    </PopupProvider>
  );
};

export default App;
