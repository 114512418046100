import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import PictureContainerStyles from './styles';

import BallPager from '../BallPager';
import ImageFullscreen from '../ImageFullscreen';

interface IProps {
  imgArray: { index: number; url: string }[];
}

const ImageCarousel = ({ imgArray }: IProps) => {
  const photosArray = imgArray.filter(item => {
    return item && item.url;
  });

  const [currentImg, setCurrentImg] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  function nextPic() {
    if (currentImg === imgArray.length - 1) return setCurrentImg(0);

    setCurrentImg(currentImg + 1);
  }

  function backPic() {
    if (currentImg === 0) return setCurrentImg(imgArray.length - 1);

    setCurrentImg(currentImg - 1);
  }

  function OpenFullscreen() {
    setIsFullscreen(!isFullscreen);
  }

  if (photosArray.length === 0) return <></>;

  return (
    <PictureContainerStyles.Container>
      {photosArray.length > 1 && (
        <PictureContainerStyles.Button onClick={backPic} className="left-btn">
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </PictureContainerStyles.Button>
      )}
      <PictureContainerStyles.Pictures onClick={OpenFullscreen}>
        {photosArray.map((img, index) => (
          <PictureContainerStyles.Picture
            key={img.index}
            showImg={index === currentImg}
            src={img.url}
            alt="Display Banner"
          />
        ))}
      </PictureContainerStyles.Pictures>
      {photosArray.length > 1 && (
        <>
          <PictureContainerStyles.Button
            onClick={nextPic}
            className="right-btn"
          >
            <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
          </PictureContainerStyles.Button>
          <PictureContainerStyles.Pager>
            <BallPager
              numberOfBalls={photosArray.length}
              activated={currentImg}
              dark
            />
          </PictureContainerStyles.Pager>
        </>
      )}
      {/* TODO: Unterstood why it doesn't work from inside */}
      {isFullscreen && (
        <ImageFullscreen
          handleClose={OpenFullscreen}
          currentImg={currentImg}
          imgArr={photosArray}
        />
      )}
    </PictureContainerStyles.Container>
  );
};

export default ImageCarousel;
