import styled, { css } from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import CheckboxStyles from 'octa-front-sdk/dist/components/Checkbox/styles';
import InputStyles from 'octa-front-sdk/dist/components/Input/styles';
import DividerStyles from 'octa-front-sdk/dist/components/Divider/styles';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import { Container as CounterContainer } from '../../Counter/styles';
import PizzaLoaderStyles from '../../PizzaLoader/styles';

interface IPropsListItem {
  unavailable?: boolean;
}

export const WarningMsg = styled.small`
  display: flex !important;
  align-items: center;
  margin-top: 0.3rem;

  > img {
    width: 20px;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: ${Color.Grey600};
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
  padding: 0 20px;
  margin: 20px 0;
  box-sizing: border-box;

  > .title {
    font-size: 2rem;
    font-weight: 500;
    color: #263238;
    margin: 0 0 1rem 0;
    text-align: center;
  }

  > .descrciption {
    font-size: 1rem;
    font-weight: normal;
    color: #455a64;
    margin: 0 0 1rem 0;
    text-align: center;
  }

  > .oldPrice {
    margin-top: 0.4rem;
    font-size: 0.75rem;
    text-decoration: line-through;
    color: #455a64;
    text-align: center;
  }

  > .newPrice {
    margin-top: 0.2rem;
    font-size: 1rem;
    color: #263238;
    font-weight: bold;
    text-align: center;
  }

  > .price {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.85rem;
    color: #455a64;

    > strong {
      margin-top: 0.4rem;
      font-size: 1rem;
      color: #263238;
    }
  }

  & > small {
    display: block;
    clear: both;
    color: ${Color.Grey600};
  }
`;

const PricesContainer = styled.div`
  margin: 0 20px 20px;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    span {
      color: ${Color.Green500};
      font-weight: bold;
    }

    li:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

export const CustomizationHeaderContainer = styled.div`
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  background-color: #cfd8dc;

  > h2 {
    color: #263238;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }

  > h2 ~ p {
    margin: 1rem 0 0 0;
  }

  > p {
    font-weight: 400;
    color: #455a64;
    font-size: 0.85rem;
    margin: 0;
  }
`;

const ExtraName = styled.p`
  color: ${Color.Grey700};
  font-weight: bold;
  margin-top: 10px;
`;

const ExtraDescription = styled.p`
  color: ${Color.Grey600};
  margin-top: 10px;
`;

const ExtraPrice = styled.p`
  color: ${Color.Green500};
  font-weight: bold;
`;

const Radio = styled.div`
  div:first-child {
    width: 30px;
  }
`;

const CustomizationItemList = styled.li<IPropsListItem>`
  display: flex;
  gap: 1rem;
  align-items: center;
  min-height: 70px;
  ${props =>
    props.unavailable &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}

  &:not(:first-of-type) {
    border-top: 1px solid ${Color.Grey300};
  }

  ${CheckboxStyles.Container} {
    width: 20px;
    height: 20px;

    input[type='checkbox'] {
      border: 2px solid #b0bec5;
    }

    input:checked {
      border: 2px solid ${Color.MeuDeliveryCyan};
      background-color: ${Color.MeuDeliveryCyan} !important;
    }
  }
`;

const CustomizationContent = styled.div`
  margin: 0 20px;

  ul {
    padding: 0;
    list-style: none;
    margin-top: 0;
  }

  ${CheckboxStyles.Container} {
    min-width: 20px;
    min-height: 20px;

    input[type='checkbox'] {
      border: 2px solid #b0bec5;
    }

    input:checked {
      border: 2px solid ${Color.MeuDeliveryCyan};
      background-color: ${Color.MeuDeliveryCyan} !important;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  flex: 1;

  ${DividerStyles.Container} {
    margin: 10px 0;
  }

  ${ButtonContainer} {
    background-color: #23999e;
    color: #fff;
    border-radius: 0.75rem;
    transition: 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    &:disabled {
      background-color: #b0bec5;
      color: #263238;
      opacity: 0.8;
    }
  }
`;

const CounterAndAddContainer = styled.div`
  margin: auto 20px 40px;
  display: flex;
  align-items: flex-end;

  ${CounterContainer} {
    margin-right: 1rem;
  }
`;

const RemoveButton = styled.button`
  margin: 0 auto 40px;
  border: none;
  background: none;
  color: red;
  text-decoration: underline;
`;

const Icon = styled.img`
  width: 1rem;
  margin-right: 5px;
`;

const RedeemedText = styled.p`
  color: ${Color.Grey600};
  text-align: center;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${InputStyles.Container} {
    font-size: ${FontSize.Normal};
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  ${PizzaLoaderStyles.Container} {
    margin-top: 50px;
  }
`;

const ExtraPicture = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
`;

const ExtraPictureImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const AddToCartStepStyles = {
  WarningMsg,
  InfoContainer,
  PricesContainer,
  CloseButtonContainer,
  ExtraName,
  ExtraDescription,
  ExtraPrice,
  Radio,
  CustomizationItemList,
  CustomizationContent,
  CounterAndAddContainer,
  RemoveButton,
  Icon,
  RedeemedText,
  Container,
  ExtraPicture,
  ExtraPictureImg,
};

export default AddToCartStepStyles;
