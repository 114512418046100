import styled from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';

const Title = styled.h1`
  color: ${Color.Grey700};
  font-size: ${FontSize.Normal};
`;

const Address = styled.p`
  color: ${Color.Grey700};
  margin-bottom: 40px;
  white-space: normal;
`;

const PaymentMethods = styled.ul`
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {
    color: ${Color.Grey700};
    background-color: ${Color.Grey300};
    padding: 3px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
  }
`;

const PaymentObservation = styled.p`
  margin: 0;
  font-weight: bold;
  color: ${Color.Grey700};
`;

const PaymentInfo = styled.div`
  margin-bottom: 40px;
`;

const DeliveryContentStyles = {
  Title,
  Address,
  PaymentMethods,
  PaymentObservation,
  PaymentInfo,
};

export default DeliveryContentStyles;
