import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import * as Yup from 'yup';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import dayjs from 'dayjs';
import { Form } from '@unform/web';

import OrganizationContext from '../../../../contexts/OrganizationContext';
import { calcCartItemPrice, formatPrice} from '../../../../Helpers';
import CouponType from '../../../../enums/CouponType';
import { DiscountCoupon } from '../../../../types/DiscountCoupon';
import PaymentOptionsStyles from './styles';
import { OrderOnlinePaymentRequest } from '../../../../types/OrderOnlinePaymentRequest';
import { CardInfo } from '../../../../types/CardInfo';
import GlobalContext from '../../../../contexts/GlobalContext';

import { MP } from '../../../../services/MercadoPago';
import { OrgPaymentMethods } from './OrgPaymentMethods';
import { OnlinePaymentMethods } from './OnlinePaymentMethods';
import Input from '../../../Input';
import BtnWithLoad from '../../../BtnWithLoad';
import { validateForm } from '../../../../utils/validateForm';
import { handleValidationErrors } from '../../../../utils/handleValidationErrors';
import { FormHandles } from '@unform/core';

interface IProps {
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>;
  setDiscount: React.Dispatch<React.SetStateAction<DiscountCoupon | undefined>>;
  deliveryFee: number;
  cartTotal: number;
  chosenMethod: string;
  setChosenMethod: React.Dispatch<React.SetStateAction<string>>;
  setOnlineMethod: React.Dispatch<React.SetStateAction<'Pix' | 'Card' | undefined>>;
  valueForChange: string;
  setValueForChange: React.Dispatch<React.SetStateAction<string>>;
  placeOrder: (onlinePayment?: OrderOnlinePaymentRequest | undefined) => Promise<void>;
  isSendingOrder: boolean;
  setIsSendingOrder: React.Dispatch<boolean>;
}

export interface ICardInfo {
  holderName?: string;
  cardNumber?: string;
  expirationDate?: string;
  cvv?: string;
  email?: string;
}

export interface IForm extends ICardInfo {
  pixEmail?: string;
  valueForChange?: string;
}

interface IIsoExpirationDate {
  expirationDateIso: string;
  year: string;
  month: string;
}

interface ITokenizeCard {
  id: string;
  last_four_digits: string;
  brand: string;
  validUntilDate: string;
}

function PaymentOptions(props: IProps) {
  const {
    couponCode,
    setCouponCode,
    setDiscount,
    deliveryFee,
    chosenMethod,
    setChosenMethod,
    setValueForChange,
    valueForChange,
    placeOrder,
    isSendingOrder,
    setIsSendingOrder,
    cartTotal,
    setOnlineMethod
  } = props;

  const formRef = useRef<FormHandles>(null);
  const { organizationInfo } = useContext(OrganizationContext);
  const { cartItems, setPaymentModifier, setToastMessage } = useContext(
    GlobalContext,
  );

  const HAS_ONLINE_PAYMENT = organizationInfo?.onlinePaymentStatus === 'Activated' && !!organizationInfo?.paymentConfiguration?.paymentMethods && organizationInfo?.paymentConfiguration?.paymentMethods.length > 0;
  const HAS_PAYMENT_METHODS = organizationInfo?.paymentMethods && organizationInfo?.paymentMethods.length > 0;

  const organizationOnlinePayments = organizationInfo?.paymentConfiguration?.paymentMethods?.map(pm => pm.paymentMethod);

  let INITIAL_STATE_ONLINE_PAYMENTS: 'Pix' | 'Card' | undefined = undefined;

  if (HAS_ONLINE_PAYMENT && organizationOnlinePayments) {
    if (organizationOnlinePayments?.includes('Pix')) {
      INITIAL_STATE_ONLINE_PAYMENTS = 'Pix';
    } else if (organizationOnlinePayments.length > 0 && organizationOnlinePayments.includes('Card')) {
      INITIAL_STATE_ONLINE_PAYMENTS = 'Card';
    }
  }

  const [currentTab, setCurrentTab] = useState(0);
  const [shouldRenderCouponSection, setShouldRenderCouponSection] = useState(
    false,
  );
  const [isCheckingCoupon, setIsCheckingCoupon] = useState(false);
  const [couponMessage, setCouponMessage] = useState<JSX.Element | undefined>();
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [onlinePaymentType, setOnlinePaymentType] = useState<'Pix' | 'Card' | undefined>(INITIAL_STATE_ONLINE_PAYMENTS);
  useEffect(() => setOnlineMethod(onlinePaymentType), [onlinePaymentType]);

  const tabs = ['Pagar pelo app', 'Outras formas de pagamento'];

  const getCartTotalForCupom = useCallback((): number => {
    return cartItems.reduce((sum, current): number => {
      if (!current.product.forbidsDiscountCoupon)
        return sum + calcCartItemPrice(current);
      return sum;
    }, 0);
  }, [cartItems]);

  const checkAndApplyCoupon = useCallback((): void => {
    setIsCheckingCoupon(true);

    RequestHandler.get(`coupons/valid?code=${couponCode}`)
      .then((response): void => {
        const discount: DiscountCoupon = response.data;
        const totalValideForDiscount = getCartTotalForCupom();

        if (totalValideForDiscount === 0)
          return setCouponMessage(
            <>Nenhum item do carrinho é válido para cupom!</>,
          );

        if (
          discount.minOrderValue &&
          totalValideForDiscount + deliveryFee < discount.minOrderValue
        ) {
          setIsCodeValid(false);

          setCouponMessage(
            <>
              Válido apenas para compras a partir de{' '}
              <strong>{formatPrice(discount.minOrderValue)}</strong>.
            </>,
          );
        } else {
          setIsCodeValid(true);
          setDiscount(discount);

          setCouponMessage(
            <>
              Você ganhou{' '}
              {!!discount.discount && (
                <>
                  <strong>
                    {discount.type === CouponType.Value
                      ? `${formatPrice(discount.discount)}`
                      : `${discount.discount * 100}%`}

                    {!!discount.maxDiscountValue &&
                      ` (limite de ${formatPrice(discount.maxDiscountValue)})`}
                  </strong>{' '}
                  de desconto
                </>
              )}
              {!!discount.discount && discount.freeDelivery && ' e '}
              {discount.freeDelivery && <strong>frete grátis</strong>}.
            </>,
          );
        }
      })
      .catch((): void => {
        setIsCodeValid(false);
        setCouponMessage(<>Código inválido.</>);
      })
      .finally((): void => setIsCheckingCoupon(false));
  }, [couponCode, deliveryFee, setDiscount, getCartTotalForCupom]);

  const createISOExpirationDate = useCallback((expirationDate: string): IIsoExpirationDate => {
    const expirationDateArr = expirationDate!.split('/');
    const month = expirationDateArr[0];
    const year = expirationDateArr[1];

    const expirationDateIso = dayjs()
      .date(1)
      .year(Number(year) + 2000)
      .month(Number(month) - 1)
      .format();

    return {
      expirationDateIso,
      year,
      month,
    };
  }, []);

  const tokenizeCard = useCallback(
    async (
      cardInfo: CardInfo,
    ): Promise<ITokenizeCard> => {
      const { cardNumber, cvv, expirationDate, holderName, email } = cardInfo;

      if (!email || !cardNumber || !cvv || !expirationDate || !holderName)
        throw setToastMessage('Preencha todos os campos necessários');

      const mercadoPagoId: string = organizationInfo!.paymentConfiguration!
        .mercadoPago!.publicKey!;

      const { expirationDateIso, year, month } = createISOExpirationDate(expirationDate);

      if (dayjs(expirationDateIso).isBefore(dayjs()))
        throw setToastMessage('Data de validade do cartão está errada');

      const onlinePaymentService = MP(mercadoPagoId);

      const resultToken = await onlinePaymentService.createCardToken({
        cardNumber,
        cardholderName: holderName,
        cardExpirationMonth: month,
        cardExpirationYear: year,
        securityCode: cvv,
      });

      const paymentMethodsResult = await onlinePaymentService.getPaymentMethods(
        {
          bin: resultToken.first_six_digits,
        },
      );

      let brand = 'unknown';

      if (paymentMethodsResult.results.length > 0) {
        brand = paymentMethodsResult.results[0].name;
      }

      return {
        id: resultToken.id,
        last_four_digits: resultToken.last_four_digits,
        brand,
        validUntilDate: expirationDateIso
      };
    },
    [createISOExpirationDate, organizationInfo, setToastMessage],
  );

  useEffect((): void => {
    if (shouldRenderCouponSection) {
      const input = document.getElementById('couponCode');

      if (input) {
        input.addEventListener('keydown', (e): void => {
          e.key === 'Enter' && e.preventDefault();
        });
      }
    }
  }, [shouldRenderCouponSection]);

  useEffect((): void => {
    if (organizationInfo?.onlinePaymentStatus === "Activated") {
      if (
        organizationInfo &&
        organizationInfo.paymentConfiguration &&
        organizationInfo.paymentConfiguration.paymentModifier
      ) {
        const onlinePaymentModifier = {
          id: organizationInfo.paymentConfiguration.paymentModifier.id,
          modifier:
            organizationInfo.paymentConfiguration.paymentModifier.modifier,
          modifierType:
            organizationInfo.paymentConfiguration.paymentModifier.modifierType,
        };

        setPaymentModifier(onlinePaymentModifier);
      } else {
        setPaymentModifier(undefined);
      }
      setChosenMethod('');
    }
  }, [currentTab, setChosenMethod, organizationInfo, setPaymentModifier]);


  const shouldRenderOnlinePayment = HAS_ONLINE_PAYMENT && currentTab === 0;
  const shouldRenderOrgPayment = HAS_ONLINE_PAYMENT ? currentTab === 1 : currentTab === 0;

  const checkout = async (data: IForm) => {
    if (HAS_ONLINE_PAYMENT && currentTab === 0 && onlinePaymentType === "Card") {
      const expirationDateRegexValidation = /^(1[0-2]|0[1-9]|\d)\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d)$/;
      await validateForm(data, {
        holderName: Yup.string().required('Nome do titular é obrigatório'),
        email: Yup.string().email('Digite um email válido').required('Email do titular é obrigatório'),
        cardNumber: Yup.string().required('Numero do cartão é obrigatório'),
        expirationDate: Yup
          .string()
          .matches(expirationDateRegexValidation, 'Data não é valida')
          .required('Data de validade é obrigatório'),
        cvv: Yup.string().min(3, "CVV inválido").max(4, "CVV inválido").required('CVV é obrigatório')
      })

      const { cardNumber, cvv, email, expirationDate, holderName } = data;

      const cardToken = await tokenizeCard({
        cardNumber: cardNumber!.replace(/\s/g, ''),
        cvv: cvv!,
        email: email!,
        expirationDate: expirationDate!,
        holderName: holderName!,
      });

      await placeOrder({
        card: {
          externalToken: cardToken.id,
          cardHolderEmail: email!,
          truncatedNumber: cardToken.last_four_digits,
          validUntil: cardToken.validUntilDate,
          brand: cardToken.brand || 'unknown',
        },
      });
    } else if (HAS_ONLINE_PAYMENT && currentTab === 0 && onlinePaymentType === "Pix") {
      await validateForm(data, {
        pixEmail: Yup.string().email('Digite um email válido').required('Email do titular é obrigatório'),
      })

      await placeOrder({
        pix: {
          email: data.pixEmail!,
        }
      });
    } else {
      const value = valueForChange.replace(/\D/g, '');

      const cleanedValue = parseFloat(
        `${value.substring(
          0,
          value.length - 2,
        )}.${value.substring(value.length - 2)}`,
      );

      if (cleanedValue !== 0 && cleanedValue < cartTotal) {
        setToastMessage('Valor de troco é menor que o total do pedido');
        setIsSendingOrder(false);
        return;
      }

      await placeOrder();
    }
  }

  const handleSubmit = async (data: IForm) => {
    formRef.current?.setErrors({});

    setIsSendingOrder(true);

    try {
      await checkout(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = handleValidationErrors(err);

        return formRef.current?.setErrors(errors);
      }
    } finally {
      setIsSendingOrder(false);
    }
  }

  return (
    <PaymentOptionsStyles.Container>
      <Form
        ref={formRef}
        key={`${currentTab}`}
        onSubmit={handleSubmit}
        placeholder={''}
      >
        <PaymentOptionsStyles.Card>
          {HAS_ONLINE_PAYMENT &&
            HAS_PAYMENT_METHODS && (
              <PaymentOptionsStyles.TabButtonsWrapper>
                {tabs.map(
                  (t, i): ReactElement => {
                    const className = currentTab === i ? 'active' : '';

                    return (
                      <PaymentOptionsStyles.TabButton
                        key={i}
                        className={className}
                        onClick={(): void => setCurrentTab(i)}
                        type="button"
                      >
                        {t}
                      </PaymentOptionsStyles.TabButton>
                    );
                  },
                )}
              </PaymentOptionsStyles.TabButtonsWrapper>
            )}

          <PaymentOptionsStyles.Content>
            {shouldRenderOnlinePayment &&
              <OnlinePaymentMethods
                onlinePaymentType={onlinePaymentType}
                setOnlinePaymentType={setOnlinePaymentType}
              />
            }

            {shouldRenderOrgPayment &&
              <OrgPaymentMethods
                chosenMethod={chosenMethod}
                setChosenMethod={setChosenMethod}
                setValueForChange={setValueForChange}
                valueForChange={valueForChange}
              />
            }
          </PaymentOptionsStyles.Content>
        </PaymentOptionsStyles.Card>

        {shouldRenderCouponSection ? (
          <PaymentOptionsStyles.CouponSection>
            <>
              <Input
                label="Código"
                name="couponCode"
                onChange={(e): void => setCouponCode(e.target.value)}
              />

              <BtnWithLoad
                name={'Validar cupom'}
                onClick={checkAndApplyCoupon}
                loading={isCheckingCoupon}
                disabled={isCheckingCoupon}
                variant="secondary"
              />
            </>

            {couponMessage && (
              <PaymentOptionsStyles.CouponMessage
                className={isCodeValid ? 'valid' : 'invalid'}
              >
                {couponMessage}
              </PaymentOptionsStyles.CouponMessage>
            )}

            <PaymentOptionsStyles.TextButton
              type="button"
              onClick={(): void => {
                setCouponCode('');
                setDiscount(undefined);
                setCouponMessage(undefined);
                setShouldRenderCouponSection(false);
              }}
            >
              Remover cupom
            </PaymentOptionsStyles.TextButton>
          </PaymentOptionsStyles.CouponSection>
        ) : (
          <PaymentOptionsStyles.TextButton
            type="button"
            onClick={(): void => setShouldRenderCouponSection(true)}
          >
            Adicionar cupom
          </PaymentOptionsStyles.TextButton>
        )}

        <BtnWithLoad
          name={'Realizar pedido'}
          disabled={isSendingOrder}
          loading={isSendingOrder}
        />
      </Form>
    </PaymentOptionsStyles.Container>
  );
}

export default PaymentOptions;
