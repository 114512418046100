import PizzaLoaderStyles from './styles';

interface IProps {
  message?: string;
}

const PizzaLoader = (props: IProps) => {
  const { message = 'Carregando...' } = props;

  return (
    <PizzaLoaderStyles.Container>
      <PizzaLoaderStyles.Pizza>
        <PizzaLoaderStyles.Slice />

        <PizzaLoaderStyles.Slice />

        <PizzaLoaderStyles.Slice />

        <PizzaLoaderStyles.Slice />
      </PizzaLoaderStyles.Pizza>

      <p>{message}</p>
    </PizzaLoaderStyles.Container>
  );
};

export default PizzaLoader;
