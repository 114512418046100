// @ts-nocheck

import { ButtonHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import BtnIconStyles from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconProp;
  color: string;
  size?:
  | 'lg'
  | 'xs'
  | 'sm'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
  | undefined;
}

const BtnIcon = ({
  icon,
  color,
  size,
  children,
  ...rest
}: IProps) => {
  return (
    <BtnIconStyles.Container {...rest}>
      {icon && (
        <FontAwesomeIcon size={size || 'lg'} icon={icon} color={color} />
      )}
      {children}
    </BtnIconStyles.Container>
  );
};

export default BtnIcon;
