const missingDDDs = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "20",
  "23",
  "25",
  "26",
  "29",
  "30",
  "36",
  "39",
  "40",
  "50",
  "52",
  "56",
  "57",
  "58",
  "59",
  "60",
  "70",
  "72",
  "76",
  "78",
  "80",
  "90"
]


export function validatePhone(value: string) {
  const cleanValue = value.replace(/\D/g, '');
  const regExp = /^\(?[1-9]{2}\)?[\s-]?[\s9]?[0-9]{4}-?[0-9]{4}$/g;

  if (cleanValue.length === 0) {
    return 'Telefone é obrigatório';
  }

  if (!regExp.test(cleanValue)) {
    return 'Telefone inválido';
  }

  const ddd = cleanValue.substring(0, 2);

  if (missingDDDs.includes(ddd)) {
    return 'DDD inválido';
  }

  if (cleanValue.length === 11 && cleanValue.substring(2, 3) !== '9') {
    return 'Telefone inválido';
  }

  return;
};
