import { ReactElement, useEffect, useState, useCallback } from 'react';

import { RiSearchLine } from 'react-icons/ri';
import { IoIosCloseCircle } from 'react-icons/io';

import Color from 'octa-front-sdk/dist/enums/Color';

import BtnIcon from '../../../components/BtnIcon';

import {
  Category,
  CategoriesList,
  CategoriesListInputFilter,
  Container,
} from './styles';
import CustomInput from '../../../components/CustomInput';
import { useScroll } from '../../../contexts/ScrollContext';

interface IProps {
  categories: string[];
  nameFiltered: (name: string) => void;
}

let lastScrollTop = 0;

const CategoriesFilter = (props: IProps) => {
  const { categories, nameFiltered } = props;

  const { setScrollTarget } = useScroll();

  const [activeIndex, setActiveIndex] = useState(0);
  const [filteredByName, setFilteredByName] = useState('');
  const [isInputApearing, setIsInputApearing] = useState(false);

  useEffect(() => {
    const main = document.getElementById('main-container');
    const nextCategory = document.getElementById(categories[activeIndex + 1]);
    const preciousCategory = document.getElementById(
      categories[activeIndex - 1],
    );

    const onScroll = () => {
      let isScrollingUp = false;

      if (lastScrollTop > main!.scrollTop) {
        isScrollingUp = true;
      }

      if (!isScrollingUp && main!.scrollTop >= nextCategory!.offsetTop - 100) {
        setActiveIndex(val => val + 1);
      }

      if (preciousCategory) {
        if (
          isScrollingUp &&
          main!.scrollTop <= preciousCategory.offsetTop + 100
        ) {
          setActiveIndex(val => val - 1);
        }
      }

      lastScrollTop = main!.scrollTop;
    };

    main!.addEventListener('scroll', onScroll, { passive: true });

    return () => main!.removeEventListener('scroll', onScroll);
  }, [activeIndex, categories]);

  const scrollToCategory = useCallback(
    (index: number, name?: string): void => {
      if (name) setScrollTarget(name);

      setActiveIndex(index);
    },
    [setScrollTarget],
  );

  function filterByName(value: string): void {
    nameFiltered(value);
    setFilteredByName(value);
  }

  return (
    <Container id="filter">
      <CategoriesListInputFilter showInput={isInputApearing}>
        <BtnIcon
          type="button"
          onClick={() => setIsInputApearing(val => !val)}
          color={Color.BlueGrey600}
        >
          <RiSearchLine size={30} color="#313131" />
        </BtnIcon>

        <div className="search-input">
          <CustomInput
            name="nameFilter"
            onChange={e => {
              nameFiltered(e.target.value);
              setFilteredByName(e.target.value);
            }}
            value={filteredByName}
            placeholder="Buscar no cardápio"
          />
          <BtnIcon
            type="button"
            onClick={() => {
              setIsInputApearing(false);
              filterByName('');
            }}
            color={Color.BlueGrey600}
          >
            <IoIosCloseCircle size={30} color="#313131" />
          </BtnIcon>
        </div>
      </CategoriesListInputFilter>
      <CategoriesList>
        {categories.map(
          (category, index): ReactElement => {
            return (
              <Category
                key={index}
                className={
                  index === activeIndex ? 'categorie active' : 'categorie'
                }
                onClick={(): void => scrollToCategory(index, category)}
              >
                {category}
              </Category>
            );
          },
        )}
      </CategoriesList>
    </Container>
  );
};

export default CategoriesFilter;
