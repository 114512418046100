import { useContext } from "react";
import { Link } from "react-router-dom";

import {
  faMapMarkerAlt,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "octa-front-sdk/dist/enums/Color";
import OrganizationContext from "../../contexts/OrganizationContext";
import { Container } from "./styles";

export const ConfigsPage = () => {
  const { organizationInfo } = useContext(OrganizationContext);
  const url = organizationInfo?.urlSuffix;

  return (
    <div>
      <h1>Configurações</h1>
      <Container>
        <ul>
          <li>
            <Link to={`/${url}/pedidos`}>
              <FontAwesomeIcon icon={faShoppingCart} />Meus pedidos
            </Link>
          </li>
          <li>
            <Link to={`/${url}/perfil/enderecos`}><FontAwesomeIcon icon={faMapMarkerAlt} />Meus endereço</Link>
          </li>
          <li>
            <Link to={`/${url}/perfil/apagar`} style={{ color: Color.Red500 }}>
              Apagar conta
            </Link>
          </li>
        </ul>
      </Container>
    </div>
  )
}
