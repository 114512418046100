import React from 'react';
import { usePopup } from './context';
import { Container, Overlay, PopupContainer } from './styles';

export function Popup() {
  const { confirm } = usePopup();
  const { prompt, proceed, cancel, isOpen } = confirm;

  if (!isOpen) return <></>;

  return (
    <Container>
      <Overlay type="button" onClick={() => cancel!()} />
      <PopupContainer>
        <p>{prompt}</p>
        <div className="popup-actions">
          <button
            className="cancel-btn"
            type="button"
            onClick={() => cancel!()}
          >
            Cancelar
          </button>
          <button
            className="confirmation-btn"
            type="button"
            onClick={() => proceed!(true)}
          >
            Confirmar
          </button>
        </div>
      </PopupContainer>
    </Container>
  );
}
