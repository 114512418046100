import styled, { css} from "styled-components";
import {animated} from "react-spring";
import Color from "octa-front-sdk/dist/enums/Color";

export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: rgba(15, 23, 42, 0.3);
`;

const ListStyle = css`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      color: ${Color.Grey600};
      padding: 0.5rem 0 0.5rem 0.5rem;
    }

    .active {
      color: ${Color.White};
      background-color: #313131;
      border-radius: 6px;
    }
  }
`;

export const SidebarContainer = styled(animated.div)`
  width: 80vw;
  height: 100vh;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 30;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  padding: 1rem;
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    h2 {
      margin: 0;
      color: ${Color.Grey800};
    }
  }

  ${ListStyle};

  @media (min-width: 426px) {
    width: 20vw;
  }
`;

export const AsideList = styled.aside`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 64px;

  div {
    display: none;
    padding: 1rem 1rem 1rem 1rem;
    width: fit-content;
    border-right: 1px solid ${Color.Grey300};

    ${ListStyle};

    @media (min-width: 1080px) {
      display: block;
      padding: 1rem 2rem 1rem 1rem;
    }

    @media (min-width: 1440px) {
      display: block;
      padding: 1rem 5rem 1rem 1rem;
    }
  }

`;


export const BtnContainer = styled(animated.div)`
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  display: grid;
  place-items: center;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.25)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  cursor: pointer;
  transition: 0.2s;
  z-index: 10;

  &:hover {
    filter: brightness(0.7);
  }

  @media (min-width: 1080px) {
    display: none;
    top: 15px;
    left: 35px;
  }
`;
