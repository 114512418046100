import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const Icon = styled.div`
  color: ${Color.Grey400};
  font-size: ${FontSize.Big};
  margin-right: 10px;
`;

const TextContent = styled.div`
  p {
    color: ${Color.Grey600};
    margin-bottom: 5px;
  }

  strong {
    font-size: ${FontSize.NormalX};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const DeliveryTimeStyles = { Icon, TextContent, Container };

export default DeliveryTimeStyles;
