import {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import OrganizationCardStyles from './styles';
import OrganizationContext from '../../contexts/OrganizationContext';

const OrganizationCard = (
  props: RouteComponentProps
) => {
  const { match } = props;
  const { organizationInfo } = useContext(OrganizationContext);
  const [scrollScreen, setscrollScreen] = useState(false);
  const topOffset = 55;

  const scrollHandler = useCallback((): void => {
    const scrollPosition = document.documentElement.scrollTop;

    scrollPosition > topOffset ? setscrollScreen(true) : setscrollScreen(false);
  }, [setscrollScreen]);

  useEffect((): (() => void) => {
    document.addEventListener('scroll', scrollHandler);

    return (): void => document.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <Link to={match.url}>
      {organizationInfo && (
        <OrganizationCardStyles.Container>
          <OrganizationCardStyles.Content hasScrolled={scrollScreen}>
            <OrganizationCardStyles.Logo
              src={organizationInfo.logoUrl}
              hasScrolled={scrollScreen}
            />

            <OrganizationCardStyles.TextContainer>
              <OrganizationCardStyles.Name>
                {organizationInfo.name}
              </OrganizationCardStyles.Name>

              <OrganizationCardStyles.Back hasScrolled={scrollScreen}>
                <FontAwesomeIcon icon={faAngleLeft} /> Voltar
              </OrganizationCardStyles.Back>
            </OrganizationCardStyles.TextContainer>
          </OrganizationCardStyles.Content>
        </OrganizationCardStyles.Container>
      )}
    </Link>
  );
};

export default withRouter(OrganizationCard);
