import { WorkDaysList } from "../../../../components/WorkDaysList";

import WorkHoursContentStyles from './styles';

const WorkHoursContent = () => {
  return (
    <>
      <WorkHoursContentStyles.Title>
        Horários de funcionamento
      </WorkHoursContentStyles.Title>

      <WorkDaysList />
    </>
  );
};

export default WorkHoursContent;
