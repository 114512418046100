import { ReactElement, useContext } from 'react';
import Icon from 'octa-front-sdk/dist/components/Icon';
import IconName from 'octa-front-sdk/dist/enums/IconName';
import {
  withRouter,
  RouteComponentProps,
  Link,
  matchPath,
} from 'react-router-dom';
import Color from 'octa-front-sdk/dist/enums/Color';
import BottomNavStyles from './styles';
import GlobalContext from '../../contexts/GlobalContext';
import Badge from '../Badge';

type CustomLink = {
  label: string;
  path: string;
  icon: IconName;
  activeIcon: IconName;
};

const BottomNav = (props: RouteComponentProps) => {
  const { cartItems } = useContext(GlobalContext);
  const { location, match } = props;
  const url = match.url.replace(/\/$/, '');
  const navLinks: CustomLink[] = [
    {
      label: 'Início',
      path: url,
      icon: IconName.Home,
      activeIcon: IconName.HomeFilled,
    },
    {
      label: 'Carrinho',
      path: `${url}/carrinho`,
      icon: IconName.Cart,
      activeIcon: IconName.CartFilled,
    },
    {
      label: 'Pedidos',
      path: `${url}/pedidos`,
      icon: IconName.Receipt,
      activeIcon: IconName.ReceiptFilled,
    },
    {
      label: 'Perfil',
      path: `${url}/perfil`,
      icon: IconName.Person,
      activeIcon: IconName.PersonFilled,
    },
  ];

  return (
    <BottomNavStyles.Container>
      <BottomNavStyles.LinksList>
        {navLinks.map(
          (navLink): ReactElement => {
            const matchObj = matchPath(location.pathname, navLink.path);
            const isActive =
              navLink.path === match.url
                ? !!matchObj && matchObj.isExact
                : !!matchObj;

            return (
              <li key={navLink.label}>
                <Link className={isActive ? 'active' : ''} to={navLink.path}>
                  <Icon
                    name={isActive ? navLink.activeIcon : navLink.icon}
                    color={isActive ? Color.MeuDeliveryCyan : Color.Grey500}
                  />

                  <span>{navLink.label}</span>

                  {navLink.label === 'Carrinho' && cartItems.length > 0 && (
                    <Badge content={cartItems.length.toString()} />
                  )}
                </Link>
              </li>
            );
          },
        )}
      </BottomNavStyles.LinksList>
    </BottomNavStyles.Container>
  );
};

export default withRouter(BottomNav);
