import styled from 'styled-components';
import PasswordRecoveryStyles from 'octa-front-sdk/dist/components/PasswordRecovery/styles';
import { MediaQuery } from 'octa-front-sdk';

const Container = styled.div`
  ${PasswordRecoveryStyles.Container} {
    margin: 0 auto;
    max-width: 400px;
    padding: 0;

    ${MediaQuery.BiggerThanSmall} {
      margin-top: 40px;
    }
  }

  ${PasswordRecoveryStyles.Content} {
    & > form {
      margin: 0;
    }
  }
`;

const PasswordRecoveryPageStyles = {
  Container,
};

export default PasswordRecoveryPageStyles;
