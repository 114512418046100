import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  border: none;
  color: ${Color.Grey700};
  background: ${Color.Grey300};
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: ${Color.White};
  border-radius: 15px;
  padding: 20px 10px 20px 20px;
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 240px;
  width: calc(100vw - 40px);
  max-width: 480px;
  max-height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.main`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 160px);
  padding: 0 10px 0 0;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    margin-right: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f0f0f7;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c1bccc;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #9c98a6;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.BiggerThanSmall} {
    flex-direction: row;
  }

  & > button {
    border: none;
    background-color: transparent;
    font-size: ${FontSize.Small};
    color: #313131;
    padding: 0;
    text-align: left;
    font-weight: 500;

    cursor: pointer;

    & > svg {
      &:first-child {
        margin-right: 5px;
      }

      &:last-of-type {
        margin-left: 8px;

        @media (min-width: 425px) {
          margin-left: 24px;
          width: 24px;
          height: 24px;
        }
      }
    }

    ${MediaQuery.BiggerThanSmall} {
      font-size: 14px;
    }
  }
`;

const BtnWithIcon = styled.button`
  display: flex;
  align-items: center;
`;

const IconSvg = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
`;

const ModalButtonStyles = {
  CloseButton,
  Modal,
  ModalContent,
  Overlay,
  Container,
  BtnWithIcon,
  IconSvg,
};

export default ModalButtonStyles;
