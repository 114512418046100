import styled from 'styled-components';

import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import { MediaQuery } from 'octa-front-sdk';

interface IShouldRenderLoyaltyData {
  shouldRenderLoyaltyData: boolean;
}

export const Logo = styled.img`
  width: 90px;
  min-width: 90px;
  height: 90px;
  align-self: flex-start;
  margin-right: 5px;
  border-radius: 10px;
  background-color: ${Color.White};

  box-shadow: 1px 9px 12px 2px rgba(0, 0, 0, 0.16),
    4px 10px 10px -4px rgba(0, 0, 0, 0.16);

  ${MediaQuery.BiggerThanSmall} {
    min-width: 160px;
    min-height: 160px;
    width: 160px;
    height: 160px;
  }

  ${MediaQuery.BiggerThanMedium} {
    margin-right: 20px;
  }

  @media (min-width: 1024px) {
    margin-right: 4px;

    min-width: 136px;
    min-height: 136px;
    width: 136px;
    height: 136px;
  }
`;

export const StatusLabel = styled.span<{ isOpen: boolean }>`
  font-size: ${FontSize.Small};
  color: ${({ isOpen }): string => (isOpen ? Color.Green500 : Color.Red500)};
  height: 40%;

  ${MediaQuery.BiggerThanSmall} {
    font-size: 14px;
    height: unset;
    cursor: pointer;
  }
`;

export const ModalsRight = styled.div<IShouldRenderLoyaltyData>`
  display: ${props => (props.shouldRenderLoyaltyData ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  border-left: 1px solid ${Color.Grey400};
  position: relative;
  height: 80px;

  @media (min-width: 375px) {
    padding: 0px 8px 0px 32px;
  }

  @media (min-width: 768px) {
    padding: 0px 8px 0px 40px;
  }

  @media (min-width: 1024px) {
    padding: 0px 8px 0px 20px;
  }

  .modals-r {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      color: #313131;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin-top: 8px;
      color: #313131;
      border: 1px solid #313131;
      border-radius: 8px;
      padding: 8px 4px;
      font-size: 14px;
      background-color: #fff;

      svg {
        margin-right: 8px;
      }

      @media (min-width: 768px) {
        padding: 8px;
      }
    }
  }
`;

export const ModalsLeft = styled.div<IShouldRenderLoyaltyData>`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding-right: 6px;
  height: 80px;

  white-space: nowrap;

  @media (min-width: 768px) {
    padding: 8px 0px 4px 8px;
  }

  @media (min-width: 1024px) {
    padding: 0 16px 0 16px;
    display: flex;
    justify-content: center;
    border-left: 1px solid ${Color.Grey400};
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  svg {
    margin-right: 6px;
  }
`;

export const Modals = styled.div`
  color: #313131;
  padding: 16px 0 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`;

export const Description = styled.p`
  margin: 0 0 6px 0;
  color: #6c6c6c;
  line-height: 1.24rem;
  font-weight: 400;
  font-size: 12px;

  position: relative;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;

  @media (min-width: 768px) {
    -webkit-line-clamp: 3;
  }

  @media (min-width: 1024px) {
    min-width: 250px;
    max-width: 250px;
    padding: 0 20px 0 14px;
  }
`;

export const Name = styled.h1`
  font-size: ${FontSize.Normal};
  margin: 8px 0 0 0;
  color: #313131;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.2rem;

  @media (min-width: 576px) {
    margin-top: 46px;
  }

  @media (min-width: 768px) {
    margin-top: 8px;
  }

  @media (min-width: 1024px) {
    padding: 0 8px 0 14px;
    margin-bottom: 7px;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0 0 8px;
  flex-basis: 100%;

  ${MediaQuery.BiggerThanMedium} {
    margin: 0;
    order: 1;
    flex: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 7rem 1rem 0.5rem 1rem;
  box-sizing: border-box;

  div {
    flex: 1;
  }

  .content-header {
    display: flex;
    flex-direction: row;
    position: relative;

    ${MediaQuery.BiggerThanMedium} {
      margin-top: 8px;
    }

    @media (min-width: 1024px) {
      margin: 0;
      align-items: center;
    }
  }

  ${MediaQuery.BiggerThanMedium} {
    padding: 16px;
    background-color: #f7f8f9;
    border-radius: 8px;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 800px;
    height: 10rem;

    background: #ffffff;
    border: 0.5px solid #d7d7d7;
  }
`;

export const Background = styled.div<{ color: Color; headerUrl: string }>`
  background: ${({ headerUrl, color }): string =>
    headerUrl ? `url(${headerUrl}) center center no-repeat` : `${color}`};
  background-size: cover;
  width: 100%;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;

  ${MediaQuery.BiggerThanSmall} {
    height: 240px;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  position: relative;
  background-color: #f7f8f9;

  ${MediaQuery.BiggerThanSmall} {
    position: initial;
    min-width: initial;
    max-width: 800px;
  }

  ${MediaQuery.BiggerThanMedium} {
    margin-top: 90px;
  }
`;
