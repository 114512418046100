import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const Label = styled.span`
  color: ${Color.White};
  margin: auto 10px auto auto;
  font-size: ${FontSize.NormalX};
`;

const Container = styled.a`
  background-color: ${Color.Green500};
  display: inline-block;
  min-width: 200px;
  color: ${Color.White};
  text-decoration: none;
  border-radius: 10px;
  align-items: center;
  display: inline-flex;
  padding: 10px 30px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(255, 255, 255, 0.16);
`;

const WhatsappLinkStyles = { Label, Container };

export default WhatsappLinkStyles;
