import React, {useCallback, useEffect, useRef, useState} from "react";
import Color from "octa-front-sdk/dist/enums/Color";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useChain, useSpring, useSpringRef} from "react-spring";

import BtnIcon from "../../../components/BtnIcon";

import {BtnContainer, SidebarContainer, Overlay, AsideList} from "./styles";
import {useScroll} from "../../../contexts/ScrollContext";

interface IProps {
  categories: string[];
}

let lastScrollTop = 0;

export function CategoriesSidebar({categories}: IProps) {
  const {setScrollTarget} = useScroll();

  const [isOpen, setIsOpen] = useState(false);
  const [renderComponent, setRenderComponent] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const overlayRef = useSpringRef();
  const overlayProps = useSpring({
    ref: overlayRef,
    opacity: isOpen ? 1 : 0,
    delay: 200,
    reverse: !isOpen,
    onStart: () => {
      if (isOpen) setRenderComponent(true);
    },
    onResolve: () => {
      if (!isOpen) setRenderComponent(false);
    },
  });

  const sidebarRef = useSpringRef();
  const sidebarProps = useSpring({
    ref: sidebarRef,
    left: isOpen ? '0%' : '-100%',
    delay: 200,
    reverse: !isOpen,
  });

  const btnRef = useSpringRef();
  const btnProps = useSpring({
    ref: sidebarRef,
    opacity: isOpen ? 0 : 1,
    delay: 100,
    reverse: !isOpen,
  });

  useChain([overlayRef, sidebarRef, btnRef], [0, 0.05]);

  useEffect(() => {
    const main = document.getElementById('main-container');
    const nextCategory = document.getElementById(categories[activeIndex + 1]);
    const preciousCategory = document.getElementById(
      categories[activeIndex - 1],
    );

    const onScroll = () => {
      let isScrollingUp = false;

      if (lastScrollTop > main!.scrollTop) {
        isScrollingUp = true;
      }

      if (!isScrollingUp && main!.scrollTop >= nextCategory!.offsetTop - 100) {
        setActiveIndex(val => val + 1);
      }

      if (preciousCategory) {
        if (
          isScrollingUp &&
          main!.scrollTop <= preciousCategory.offsetTop + 100
        ) {
          setActiveIndex(val => val - 1);
        }
      }

      lastScrollTop = main!.scrollTop;
    };

    main!.addEventListener('scroll', onScroll, { passive: true });

    return () => main!.removeEventListener('scroll', onScroll);
  }, [activeIndex, categories]);

  const scrollToCategory = useCallback(
    (index: number, name?: string): void => {
      if (name) {
        setScrollTarget(name);
        setIsOpen(false);
      }

      setActiveIndex(index);
    },
    [setScrollTarget],
  );

  return (
    <>
      <BtnContainer style={btnProps}>
        <BtnIcon icon={faBars} color={Color.Grey600} onClick={() => setIsOpen(true)}/>
      </BtnContainer>

      <AsideList>
        <div>
          <ul>
            {categories.map((category, index) => (
              <li key={category}
                  className={index === activeIndex ? 'active' : ''}
                  onClick={() => scrollToCategory(index, category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>
      </AsideList>

      {renderComponent && (
        <>
          <Overlay style={overlayProps}/>
          <SidebarContainer ref={ref} style={sidebarProps}>
            <div className="header">
              <h2>Categorias</h2>
              <BtnIcon icon={faTimes} color={Color.Grey600} onClick={() => setIsOpen(false)}/>
            </div>
            <ul>
              {categories.map((category, index) => (
                <li key={category}
                    className={index === activeIndex ? 'active' : ''}
                    onClick={() => scrollToCategory(index, category)}
                >
                  {category}
                </li>
              ))}
            </ul>
          </SidebarContainer>
        </>
      )}
    </>
  );
}
