// @ts-nocheck

import { ReactElement, useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'octa-front-sdk/dist/components/Button';
import Color from 'octa-front-sdk/dist/enums/Color';
import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import Input from 'octa-front-sdk/dist/components/Input';
import InputType from 'octa-front-sdk/dist/enums/InputType';
import Form from 'octa-front-sdk/dist/components/Form';
import IconName from 'octa-front-sdk/dist/enums/IconName';
import Icon from 'octa-front-sdk/dist/components/Icon';
import Wizard from 'octa-front-sdk/dist/components/Wizard';
import GlobalContext from '../../contexts/GlobalContext';
import AddressStep from '../../components/Steps/AddressStep';
import { Address } from '../../types/Address';
import AddressesPageStyles from './styles';
import Page from '../../components/Page';

const AddressesPage = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const {
    chosenAddress,
    setChosenAddress,
    setLoading,
    isLoggedIn,
    triggerRedirect,
  } = useContext(GlobalContext);
  const [isWizardOpen, setWizardOpen] = useState();
  const [steps, setSteps] = useState<ReactElement[]>([]);

  useEffect((): void => {
    const storedAddress = localStorage.getItem('chosenAddress');

    if (storedAddress) {
      setChosenAddress(JSON.parse(storedAddress));
    }
  }, [setChosenAddress]);

  useEffect((): void => {
    if (chosenAddress && addresses) {
      localStorage.setItem('chosenAddress', JSON.stringify(chosenAddress));
    }
  }, [chosenAddress, triggerRedirect, addresses]);

  useEffect((): void => {
    if (isLoggedIn) {
      setLoading(true);

      RequestHandler.get('user/address')
        .then((response): void => {
          setAddresses(response.data);
        })
        .finally((): void => setLoading(false));
    }
  }, [setLoading, isLoggedIn]);

  useEffect((): void => {
    if (addresses.length === 1) {
      const el = document.getElementById(`chosenAddress${addresses[0].id}`);

      if (el) el.click();
    }
  }, [addresses]);

  return (
    <>
      <Page title="Endereços" isPrivate>
        <AddressesPageStyles.Container>
          {addresses.length ? (
            <Form
              onSubmit={(): void => {}}
              initialValues={{
                chosenAddress:
                  chosenAddress && chosenAddress.id
                    ? chosenAddress.id.toString()
                    : '',
              }}
            >
              <AddressesPageStyles.AddressList>
                {addresses.map(
                  (address): ReactElement => {
                    const { id, name, street, number, neighbourhood } = address;

                    return (
                      <li key={id}>
                        <Input
                          key={id}
                          name="chosenAddress"
                          type={InputType.Radio}
                          value={id.toString()}
                          onChange={(e): void => {
                            setChosenAddress(
                              addresses.find(
                                (address): boolean =>
                                  address.id === parseInt(e.target.value),
                              ),
                            );

                            triggerRedirect();
                          }}
                        />

                        <span
                          className={
                            chosenAddress && id === chosenAddress.id
                              ? 'active'
                              : ''
                          }
                        >
                          {name && <strong>{`${name} - `} </strong>}
                          {`${street}, ${number}${neighbourhood && `. ${neighbourhood}`
                            }.`}
                        </span>

                        <div
                          onClick={(): void => {
                            setSteps([
                              <AddressStep
                                address={address}
                                isEditing
                                shouldRenderIndicator={false}
                                shouldRenderBackButton={false}
                                shouldHideBottomWaves
                                onSave={(newAddress): void => {
                                  if (newAddress) {
                                    const index = addresses.findIndex(
                                      (a): boolean => a.id === newAddress.id,
                                    );

                                    if (index > -1) {
                                      const newAddresses = Array.from(
                                        addresses,
                                      );

                                      newAddresses.splice(index, 1, newAddress);

                                      setAddresses(newAddresses);

                                      setChosenAddress(newAddress);
                                    }
                                  }

                                  setWizardOpen(false);
                                }}
                                onRemove={(): void => {
                                  setAddresses(
                                    addresses.filter(
                                      (a): boolean => a.id !== id,
                                    ),
                                  );

                                  if (chosenAddress && chosenAddress.id === id)
                                    setChosenAddress(undefined);

                                  setWizardOpen(false);
                                }}
                              />,
                            ]);

                            setWizardOpen(true);
                          }}
                        >
                          <Icon name={IconName.Pencil} size={20} />
                        </div>
                      </li>
                    );
                  },
                )}
              </AddressesPageStyles.AddressList>
            </Form>
          ) : (
            <p>Você ainda não tem um endereço de entrega cadastrado.</p>
          )}

          {/* TODO: use company color */}
          <Button
            text="Cadastrar endereço"
            color={Color.MeuDeliveryCyan}
            onClick={(): void => {
              setSteps([
                <AddressStep
                  shouldRenderIndicator={false}
                  shouldRenderBackButton={false}
                  shouldHideBottomWaves
                  onSave={(newAddress): void => {
                    if (newAddress) {
                      setAddresses([...addresses, newAddress]);

                      setChosenAddress(newAddress);
                    }

                    setWizardOpen(false);
                  }}
                />,
              ]);

              setWizardOpen(true);
            }}
          />
        </AddressesPageStyles.Container>
      </Page>

      <Wizard
        shouldRender={isWizardOpen}
        closeWizard={(): void => setWizardOpen(false)}
        steps={steps}
      />
    </>
  );
};

export default withRouter(AddressesPage);
