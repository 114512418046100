// @ts-nocheck

import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import RequestHandler from 'octa-front-sdk/dist/RequestHandler';
import { parseQueryString } from 'octa-front-sdk';

import Page from '../../components/Page';
import Dishes from '../../components/Dishes';
import OrderPlacer from '../../components/OrderPlacer';
import CartItemStep from '../../components/Dishes/CartItemStep';
import Footer from '../../components/Footer';
import Sidebar, { ISidebarRef } from '../../components/Sidebar';

import FilteredView from './FilteredView';

import { Product } from '../../types/Product';

import OrganizationContext from '../../contexts/OrganizationContext';
import GlobalContext from '../../contexts/GlobalContext';
import { ScrollProvider } from '../../contexts/ScrollContext';

import OrgBanner from './OrgBanner';
import CategoriesFilter from './CategoriesFilter';

import DishesPageStyles, { Grid } from './styles';
import { CategoriesSidebar } from "./CategoriesSidebar";

const DishesPage = (props: RouteComponentProps) => {
  const { location } = props;

  const sidebarRef = useRef<ISidebarRef>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [dishes, setDishes] = useState<Product[]>([]);
  const [dishesFiltered, setDishesFiltered] = useState<Product[]>([]);
  const [showAllDishes, setShowAllDishes] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [currentDish, setCurrentDish] = useState<Partial<Product>>();
  const { setLoading, cartItems } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  useEffect((): void => {
    const dishId = parseQueryString(location.search).produto;

    if (dishId && dishes) {
      const index = dishes.findIndex(
        (dish): boolean => dish.id === parseInt(dishId),
      );

      if (index > -1) {
        setCurrentDish(dishes[index]);

        sidebarRef.current?.toggle();
      }
    }
  }, [location.search, dishes]);

  useEffect((): void => {
    const isWizardOpen = sidebarRef.current?.isOpen();

    if (isWizardOpen !== undefined && !isWizardOpen) {
      window.history.pushState(null, '', '?');
    }
  }, [sidebarRef]);

  useEffect((): void => {
    if (organizationInfo) {
      setLoading(true);

      RequestHandler.defaults.headers.common = {
        ...RequestHandler.defaults.headers.common,
        'X-Org-Id': organizationInfo.id,
      };

      RequestHandler.get(`menu/products`)
        .then((response): void => setDishes(response.data))
        .finally((): void => setLoading(false));
    }
  }, [setLoading, organizationInfo]);

  useEffect((): void => {
    const categories = Array.from(
      new Set(dishes.map((p): string => p.category)),
    );

    setCategories(categories);
  }, [dishes]);

  useEffect((): void => {
    if (currentDish) {
      window.history.pushState(null, '', `?produto=${currentDish.id}`);
    }
  }, [currentDish]);

  function getDishNameFiltered(name: string): void {
    const currentDishes = dishes;

    if (name === '') return setShowAllDishes(true);

    const searchLowerCase = name.toLocaleLowerCase();
    const searchNormalized = searchLowerCase
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    setShowAllDishes(false);

    const filteredDishes = currentDishes.filter((dishe): Product | null => {
      const nameLowercase = dishe.name.toLocaleLowerCase();
      const nameNormalized = nameLowercase
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return nameNormalized.includes(searchNormalized) ? dishe : null;
    });

    setDishesFiltered([...filteredDishes]);
  }

  const SHOW_CATEGORIES_SIDEBAR = organizationInfo.menuPosition === "Vertical" && categories.length > 0;
  const SHOW_CATEGORIES_FILTER = organizationInfo.menuPosition === "Horizontal" && categories.length > 0;

  return (
    <>
      <ScrollProvider scrollContainerRef={contentRef}>
        <DishesPageStyles.Container>
          <Page title="Catálogo" shouldRenderTitle={false}>
            <OrgBanner />

            {SHOW_CATEGORIES_FILTER && (
              <CategoriesFilter
                categories={categories}
                nameFiltered={getDishNameFiltered}
              />
            )}

            <Grid>
              <div className='menu'>
                {SHOW_CATEGORIES_SIDEBAR && <CategoriesSidebar categories={categories} />}
              </div>

              <div className='dishes'>
                <div ref={contentRef} id="dishes">
                  {!showAllDishes && (
                    <FilteredView
                      filteredArray={dishesFiltered}
                      openWizard={() => sidebarRef.current?.toggle()}
                      setCurrentDish={setCurrentDish}
                    />
                  )}

                  {showAllDishes && (
                    <Dishes
                      dishes={dishes}
                      openWizard={() => sidebarRef.current?.toggle()}
                      setCurrentDish={setCurrentDish}
                    />
                  )}
                </div>
              </div>
              <div className='placeholder' />
            </Grid>


            <Footer />
          </Page>

          {cartItems.length > 0 && <OrderPlacer />}
        </DishesPageStyles.Container>
      </ScrollProvider>

      <Sidebar ref={sidebarRef} showHeader>
        <CartItemStep
          currentDish={currentDish}
          closeWizard={() => sidebarRef.current?.setIsOpen(false)}
        />
      </Sidebar>
    </>
  );
};

export default withRouter(DishesPage);
