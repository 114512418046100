import styled, {css} from 'styled-components';
import {animated} from 'react-spring';

const FULLSCREEN_STYLE = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const SidebarContent = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 140;
  min-height: 100%;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  }

  .content {
    position: relative;
    background-color: red;
  }

  @media (min-width: 768px) {
    max-width: 30rem;

    .main .content {
      height: 85vh;
    }
  }

  @media (min-width: 1025px) {
    max-width: 35rem;
  }
`;

export const Overlay = styled.button`
  ${FULLSCREEN_STYLE}
  z-index: 130;
  background: transparent;
`;

export const Container = styled(animated.div)`
  ${FULLSCREEN_STYLE}
  background-color: rgba(15, 23, 42, 0.3);
  z-index: 120;
`;
