import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

const Container = styled.div`
  width: 18px;
  height: 18px;
  padding: 1px;
  border-radius: 100%;
  font-size: ${FontSize.SmallX};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.White};
  background-color: ${Color.Red500};
`;

const BadgeStyles = { Container };

export default BadgeStyles;
