import NotFoundStyles from './styles';

const NotFound = () => {
  return (
    <NotFoundStyles.Container>
      <NotFoundStyles.Picture src="imgs/logo.png" />

      <h1>Ops! Estabelecimento não encontrado!</h1>

      <h2>
        Tem certeza que digitou o nome certo? Que tal recomendar a Levilo pra
        este estabelecimento então?
      </h2>

      <a href="https://levilo.com.br">Visite nossa página</a>
    </NotFoundStyles.Container>
  );
};

export default NotFound;
