import styled from "styled-components";
import FontSize from "octa-front-sdk/dist/enums/FontSize";
import Color from "octa-front-sdk/dist/enums/Color";

export const Container = styled.div<{isDarkMode: boolean}>`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;

      span {
        font-size: ${FontSize.Small};
        color: ${(props) => props.isDarkMode ? "#f2f2f2" : Color.Grey700};
      }
    }
  }
`
