import React, { ReactElement, useContext, useCallback } from 'react';
import ReceiptStyles from './styles';

import GlobalContext from '../../../../contexts/GlobalContext';
import OrganizationContext from '../../../../contexts/OrganizationContext';
import { useLoyalty } from '../../../../hooks/Loyalty';

import { calcCartItemPrice, formatPrice } from '../../../../Helpers';
import { Prize } from '../../../../types/Prize';

import TrashCan from '../../../../images/icons/delete-bin-48.png';
import WarningSign from '../../../../images/icons/error-48.png';
import { usePopup } from '../../../Popup/context';

const Receipt = () => {
  const { cartItems, setCartItems } = useContext(GlobalContext);
  const { loyaltyProgram, memberPoints, setMemberPoints } = useLoyalty();
  const { isConfirmed } = usePopup();

  const getPrize = useCallback(
    (id: number | undefined) => {
      let result;

      if (loyaltyProgram) {
        result = loyaltyProgram.prizes.find((prize): Prize | undefined => {
          let p;

          if (id !== undefined && prize.productId === id.toString()) {
            p = prize;
          }

          return p;
        });
      }

      return result && result.priceInPoints;
    },
    [loyaltyProgram],
  );

  const removeCartItem = useCallback(
    async (id: number) => {
      const confirmation = await isConfirmed(
        'Confirmar remoção desse item do carrinho?',
      );

      if (confirmation) {
        const index = cartItems.findIndex((cI): boolean => cI.id === id);
        const newCartItems = Array.from(cartItems);
        const prize = getPrize(cartItems[index].product.id) || 0;

        if (cartItems[index].redeemed) {
          if (memberPoints) {
            const memberPointsUpdated = {
              pendingPoints: memberPoints.pendingPoints,
              validPoints: memberPoints.validPoints,
              cartPoints:
                memberPoints.cartPoints +
                Number(prize * cartItems[index].amount),
            };

            setMemberPoints(memberPointsUpdated);
          }
        }

        newCartItems.splice(index, 1);

        setCartItems(newCartItems);
      }
    },
    [
      isConfirmed,
      cartItems,
      getPrize,
      setCartItems,
      memberPoints,
      setMemberPoints,
    ],
  );

  return (
    <ReceiptStyles.Container>
      {cartItems.length ? (
        <>
          <ReceiptStyles.Products>
            {cartItems.map(
              (cartItem, index): ReactElement => {
                const {
                  product,
                  extras,
                  flavors,
                  totalPrice,
                  amount = 1,
                  redeemed,
                } = cartItem;
                const { name } = product;

                return (
                  <ReceiptStyles.Product key={index}>
                    <ReceiptStyles.QuantityContainer>
                      <span>{`${amount}x`}</span>
                    </ReceiptStyles.QuantityContainer>
                    <ReceiptStyles.ProductContent>
                      <button onClick={() => removeCartItem(cartItem.id)}>
                        <img src={TrashCan} alt="Apagar Produto" />
                      </button>
                      <span className="title">{name}</span>
                      {product && product.forbidsDiscountCoupon && (
                        <span className="warning">
                          <img src={WarningSign} alt="Atenção" />
                          Esse produto é inválido para cupom
                        </span>
                      )}
                      {flavors && flavors.size > 0 && (
                        <>
                          {[...flavors.values()].map(flavor => {
                            const { name, amount = 1 } = flavor;

                            if (amount === 0) return null;

                            return <span>{name}</span>;
                          })}
                        </>
                      )}
                      {extras && extras.size > 0 && (
                        <>
                          {[...extras.values()].map(extra => {
                            const { name, amount = 1 } = extra;

                            if (amount === 0) return null;

                            return <span>{`${amount}x - ${name}`}</span>;
                          })}
                        </>
                      )}
                      <span className="price">
                        {formatPrice(calcCartItemPrice(cartItem))}
                      </span>
                    </ReceiptStyles.ProductContent>
                  </ReceiptStyles.Product>
                );
              },
            )}
          </ReceiptStyles.Products>
        </>
      ) : (
        <p>Seu carrinho está vazio.</p>
      )}
    </ReceiptStyles.Container>
  );
};

export default Receipt;
