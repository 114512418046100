import styled from 'styled-components';

import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  min-width: 80px;
  position: relative;

  label {
    margin-bottom: 5px;
    background-color: ${Color.White};
    font-size: ${FontSize.Small};
    top: -8px;
    left: 10px;
  }

  label,
  ::after {
    position: absolute;
    color: ${Color.Grey600};
  }

  select {
    background-color: ${Color.White};
    border: 1px solid ${Color.Grey400};
    border-radius: 8px;
    padding: 8px;
    height: 48px;

    &:disabled {
      background-color: ${Color.Grey200};
    }

    &:focus {
      border: 2px solid ${Color.MeuDeliveryCyan};
    }
  }
`;
