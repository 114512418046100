// @ts-nocheck

import { useContext } from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import GlobalContext from '../../contexts/GlobalContext';
import Auth from '../../services/Auth';

import { useLoyalty } from '../../hooks/Loyalty';

const LogoutPage = (props: RouteComponentProps) => {
  const { match } = props;
  const { setLoggedIn, setGuestUUID } = useContext(GlobalContext);
  const { setMemberPoints } = useLoyalty();

  Auth.logout();
  setLoggedIn(false);
  setGuestUUID(undefined);
  setMemberPoints(undefined);

  const redirectUrl = `/${match.url.split('/')[1]}`;

  return <Redirect to={redirectUrl} />;
};

export default withRouter(LogoutPage);
