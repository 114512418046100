import styled from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import InputStyles from 'octa-front-sdk/dist/components/Input/styles';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import CheckboxStyles from 'octa-front-sdk/dist/components/Checkbox/styles';
import { ButtonContainer } from 'octa-front-sdk/dist/components/Button/styles';
import { MediaQuery } from 'octa-front-sdk';
import FormStyles from 'octa-front-sdk/dist/components/Form/styles';
import RadioStyles from 'octa-front-sdk/dist/components/Input/Radio/styles';

type PaymentModifierType = {
  isDiscount?: boolean;
};

const TabButton = styled.button`
  background: ${Color.Grey200};
  flex: 1;
  padding: 15px 0;
  border: none;
  border-bottom: 1px solid ${Color.Grey300};
  border-left: 1px solid ${Color.Grey300};
  cursor: pointer;

  &:first-of-type {
    border-right: 1px solid ${Color.Grey300};
  }

  &.active {
    background: none;
    border: none;
  }
`;

const TabButtonsWrapper = styled.div`
  display: flex;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${InputStyles.Container} {
    flex: 1;

    &:first-of-type {
      margin-right: 20px;
    }
  }
`;

const CheckboxWrapper = styled.div`
  font-size: ${FontSize.SmallX};
  display: flex;
  align-items: center;

  ${CheckboxStyles.Container} {
    margin-right: 10px;
  }
`;

const PaymentModifierText = styled.span<PaymentModifierType>`
  color: ${props => (props.isDiscount ? Color.Green500 : Color.Red900)};
  font-size: 0.8rem;
  font-weight: bold;
`;

const OnlinePaymentMethod = styled.li`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  justify-content: space-between;
  padding-bottom: 20px;

  &:not(:first-of-type) {
    border-top: 1px solid ${Color.Grey300};
    padding-top: 20px;
  }

  label {
    margin-bottom: 0;
    font-weight: normal;
  }

  ${RadioStyles.InnerCircle} {
    fill: ${Color.MeuDeliveryCyan};
  }
`;

const PaymentMethod = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  &:not(:first-of-type) {
    border-top: 1px solid ${Color.Grey300};
    padding-top: 20px;
  }

  label {
    margin-bottom: 0;
    font-weight: normal;
  }

  ${RadioStyles.InnerCircle} {
    fill: ${Color.MeuDeliveryCyan};
  }
`;

const PaymentMethods = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ChangeContainer = styled.div`
  ${InputStyles.Container} {
    display: flex;

    ${InputStyles.InputContainer} {
      width: 120px;
      margin-left: 20px;
    }
  }
`;

const Observation = styled.strong`
  font-size: ${FontSize.NormalX};
  margin-top: 15px;
  display: block;
`;

const Content = styled.div`
  padding: 15px;
`;

const Card = styled.div`
  border-radius: 5px;
  border: 1px solid ${Color.Grey300};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(0, 0, 0, 0.16);
  overflow: hidden;
`;

const TextButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  margin: 2rem 0;
  text-decoration: underline;
`;

const CouponMessage = styled.p`
  margin-top: 20px;

  &.valid {
    &,
    strong {
      color: ${Color.Green500};
    }
  }

  &.invalid {
    &,
    strong {
      color: ${Color.Red500};
    }
  }

  ${MediaQuery.BiggerThanSmall} {
    margin-top: 0;
  }
`;

const CouponSection = styled.div`
  margin-top: 30px;

  ${ButtonContainer} {
    width: 100%;
    margin: 0 0 30px;
  }

  ${MediaQuery.BiggerThanSmall} {
    ${FormStyles.Form} {
      display: flex;

      ${InputStyles.Container}, ${ButtonContainer} {
        flex: 1;
      }

      ${ButtonContainer} {
        height: 40px;
        margin-top: 23px;
        margin-left: 20px;
      }
    }
  }
`;

const Container = styled.div`
  & > ${FormStyles.Form} > ${ButtonContainer} {
    margin-top: 40px;
  }
`;

const PaymentOptionsStyles = {
  OnlinePaymentMethod,
  TabButton,
  TabButtonsWrapper,
  InputsWrapper,
  CheckboxWrapper,
  PaymentModifierText,
  PaymentMethod,
  PaymentMethods,
  ChangeContainer,
  Observation,
  Content,
  Card,
  TextButton,
  CouponMessage,
  CouponSection,
  Container,
};

export default PaymentOptionsStyles;
