import React, { ReactElement, useContext } from 'react';
import OrganizationContext from '../../../../contexts/OrganizationContext';
import { formatPrice } from '../../../../Helpers';
import RadiusDeliveryContent from '../RadiusDeliveryContent';
import DeliveryContentStyles from './styles';

const DeliveryContent = () => {
  const { organizationInfo } = useContext(OrganizationContext);

  if (organizationInfo === undefined) return <></>;

  const {
    freeDeliveryMinimumOrderValue,
    allowStorePickup,
    address,
    paymentMethods,
    paymentObservation,
  } = organizationInfo;

  let freeDeliveryMessage;

  if (freeDeliveryMinimumOrderValue !== undefined) {
    freeDeliveryMessage = 'Entrega GRÁTIS';

    if (freeDeliveryMinimumOrderValue > 0) {
      freeDeliveryMessage += ` a partir de ${formatPrice(
        freeDeliveryMinimumOrderValue,
      )}`;
    }
  }

  return (
    <>
      {allowStorePickup && (
        <>
          <DeliveryContentStyles.Title>
            Endereço para retirar pedidos
          </DeliveryContentStyles.Title>

          <DeliveryContentStyles.Address>
            {address.street && `${address.street}, `}
            {address.number && `${address.number}. `}
            {address.neighbourhood && `${address.neighbourhood} - `}
            {address.city && `${address.city}.`}
          </DeliveryContentStyles.Address>
        </>
      )}

      <DeliveryContentStyles.Title>
        Pagamentos aceitos na entrega
      </DeliveryContentStyles.Title>

      <DeliveryContentStyles.PaymentInfo>
        {paymentMethods.length > 0 && (
          <DeliveryContentStyles.PaymentMethods>
            {paymentMethods.map(
              ({ name, id }): ReactElement => {
                return <li key={id}>{name}</li>;
              },
            )}
          </DeliveryContentStyles.PaymentMethods>
        )}

        {paymentObservation && (
          <DeliveryContentStyles.PaymentObservation>{`OBS: ${paymentObservation}`}</DeliveryContentStyles.PaymentObservation>
        )}
      </DeliveryContentStyles.PaymentInfo>

      <RadiusDeliveryContent />
    </>
  );
};

export default DeliveryContent;
