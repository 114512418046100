import Color from "octa-front-sdk/dist/enums/Color"
import styled from "styled-components"

export const PixWarning = styled.p`
  font-size: 0.75rem;
  color: ${Color.BlueGrey500};
  text-align: left;
`

export const TutorialList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    gap: 8px;

    margin-bottom: 1rem;

    div span {
      display: block;
      width: 16px;
      height: 16px;
      padding: 4px;
      border-radius: 50%;
      border: 2px solid ${Color.MeuDeliveryCyan};
      color: ${Color.MeuDeliveryCyan};
      text-align: center;
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }
`

export const CopyButton = styled.button`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: transparent;
  border: 1px solid ${Color.Grey400};
  border-radius: 4px;
  margin-bottom: 1rem;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  div {
    display: grid;
    place-items: center;
    color: ${Color.MeuDeliveryCyan};
  }
`;

export const QrCodeImg = styled.img`
  width: 320px;
  height: 320px;
  border: 1px solid ${Color.Grey400};
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: ${Color.Grey100};
`;

export const Error = styled.p`
  border: 1px solid ${Color.Red700};
  padding: 1rem;
  background-color: ${Color.Red100};
  color: ${Color.Red700} !important;
  text-align: center;
  border-radius: 6px;
`
export const Container = styled.div`
  max-width: 320px;
  width: 100%;
  padding: 1rem;

  h2 {
    color: ${Color.BlueGrey800}
  }

  p {
    color: ${Color.BlueGrey600}
  }
`
