import styled from 'styled-components';
import { MediaQuery } from 'octa-front-sdk';
import ToastStyles from 'octa-front-sdk/dist/components/Toast/styles';
import Color from 'octa-front-sdk/dist/enums/Color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;

  .router-container {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }

    ${MediaQuery.BiggerThanSmall} {
      overflow-y: initial;
    }
  }

  ${ToastStyles.Container} {
    background: linear-gradient(
      to top right,
      ${Color.MeuDeliveryCyan},
      ${Color.MeuDeliveryBlue}
    );
  }

  ${ToastStyles.Message} {
    color: ${Color.White};
  }
`;

const AppRouterStyles = { Container };

export default AppRouterStyles;
