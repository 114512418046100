import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ModalButtonStyles from './styles';

interface IProps {
  modalContent: ReactElement;
  icon?: IconProp;
  label: string;
  svgIcon?: string;
  children?: ReactElement;
}

const ModalButton = (props: IProps) => {
  const { modalContent, icon, label, svgIcon } = props;
  const [shouldRenderModal, setShouldRenderModal] = useState(false);

  useEffect((): void => {
    if (shouldRenderModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [shouldRenderModal]);

  const closeModal = useCallback((): void => {
    setShouldRenderModal(false);
  }, [setShouldRenderModal]);

  return (
    <ModalButtonStyles.Container>
      {shouldRenderModal && (
        <ModalButtonStyles.Overlay onClick={closeModal}>
          <ModalButtonStyles.CloseButton onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </ModalButtonStyles.CloseButton>

          <ModalButtonStyles.Modal onClick={(e): void => e.stopPropagation()}>
            <ModalButtonStyles.ModalContent>
              {modalContent}
            </ModalButtonStyles.ModalContent>
          </ModalButtonStyles.Modal>
        </ModalButtonStyles.Overlay>
      )}

      <ModalButtonStyles.BtnWithIcon
        onClick={(): void => setShouldRenderModal(true)}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {svgIcon && <ModalButtonStyles.IconSvg src={svgIcon} alt="SVG Icon" />}
        {label}
        {props.children}
      </ModalButtonStyles.BtnWithIcon>
    </ModalButtonStyles.Container>
  );
};

export default ModalButton;
