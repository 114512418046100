import styled, { css } from 'styled-components';
import Color from 'octa-front-sdk/dist/enums/Color';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';

interface CategoriesListInputFilter {
  showInput: boolean;
}

export const FilterTitle = styled.p`
  font-size: ${FontSize.Normal};
  text-align: center;
  margin: 0;
  padding: 20px;
  background-color: ${Color.Grey200};
`;

export const Category = styled.li`
  display: flex;
  align-items: center;

  font-weight: 500;

  position: relative;
  white-space: nowrap;
  padding: 10px 16px;
  color: #313131;
  border: 1px solid #313131;
  border-radius: 10px;
  margin-left: 16px;

  cursor: pointer;

  &.active {
    color: ${Color.White};
    border: none;
    background-color: #313131;
  }

  &.active::after {
    content: '';
    height: 3px;
    border-radius: 3px 3px 0 0;
    width: 100%;
    position: absolute;
    bottom: -15px;
    left: 0;
    background-color: #313131;
  }
`;

export const CategoriesList = styled.ul`
  display: flex;
  margin: 0;
  padding: 16px 0;
  list-style: none;
  box-sizing: content-box;

  &::after {
    content: '';
    flex: 0 0 8px;
  }
`;

export const CategoriesListInputFilter = styled.div<CategoriesListInputFilter>`
  margin-left: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .search-input {
    position: relative;
    display: flex;
    margin-bottom: 0;

    ${({ showInput }) =>
      showInput
        ? css`
            width: 16rem;
            opacity: 1;
            padding: 1;

            button,
            input {
              display: block;
            }

            input {
              padding: 10px 15px;
            }
          `
        : css`
            width: 0;
            opacity: 0;
            padding: 0;

            button,
            input {
              display: none;
            }
          `}

    button {
      position: absolute;
      right: 24px;
      top: 10%;
    }
  }
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: hidden;
  overflow-x: auto;

  width: 100%;
  max-width: 100vw;
  height: 70px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  box-sizing: border-box;

  background-color: #fff;
  border: 0.5px solid #d7d7d7;

  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 16px;
    background-color: transparent;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    width: 32px;
    background: #cfd8dc;
    height: 8px;
    border-radius: 8px;
    border: 3px solid #fff;
  }

  ::-webkit-scrollbar-thumb:hover {
    filter: brightness(0.9);
  }

  @media (min-width: 1024px) {
    padding-left: 90px;
  }

  @media (min-width: 1440px) {
    padding-left: 320px;
  }
`;
