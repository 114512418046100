import styled from 'styled-components';
import FontSize from 'octa-front-sdk/dist/enums/FontSize';
import Color from 'octa-front-sdk/dist/enums/Color';

export const Button = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  font-size: ${FontSize.Big};
  padding: 0;
  flex: 1;
  color: ${({ disabled }): Color =>
    disabled ? Color.Grey300 : Color.MeuDeliveryCyan};
  max-width: 27px;
  margin-left: auto;

  &:focus {
    outline: 0;
  }
`;

export const Count = styled.span`
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  flex: 1;
  color: #313131;
  font-weight: 500;
  margin: 0 1rem;
`;

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.35rem 0.5rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.75rem;
`;
