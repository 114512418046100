import React, { ReactElement, useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PageStyles from './styles';
import GlobalContext from '../../contexts/GlobalContext';
import OrganizationContext from '../../contexts/OrganizationContext';

interface IProps {
  title: string;
  shouldRenderTitle?: boolean;
  shouldCenterTitle?: boolean;
  isPrivate?: boolean;
  children: ReactElement | ReactElement[];
}

const Page = (props: IProps & RouteComponentProps): ReactElement => {
  const {
    title,
    children,
    shouldRenderTitle = true,
    shouldCenterTitle,
    isPrivate,
    history,
    match,
  } = props;
  const { isLoggedIn, setRedirectTarget } = useContext(GlobalContext);
  const { organizationInfo } = useContext(OrganizationContext);

  useEffect((): void => {
    if (organizationInfo) {
      document.title = `${title} | ${organizationInfo.name}`;
    }
  }, [organizationInfo, title]);

  useEffect((): void => {
    if (isPrivate && isLoggedIn !== undefined && !isLoggedIn) {
      setRedirectTarget({ pathname: match.url });

      history.push({ pathname: `/${match.url.split('/')[1]}/perfil/entrar` });
    }
  }, [isLoggedIn, isPrivate, history, match.url, setRedirectTarget]);

  return (
    <PageStyles.Container>
      {shouldRenderTitle && (
        <PageStyles.Title className={shouldCenterTitle ? 'centered' : ''}>
          {title}
        </PageStyles.Title>
      )}

      <PageStyles.ContentContainer>{children}</PageStyles.ContentContainer>
    </PageStyles.Container>
  );
};

export default withRouter(Page);
