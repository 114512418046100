import Divider from 'octa-front-sdk/dist/components/Divider';
import { ReactElement, useContext } from 'react';

import OrganizationContext from '../../../../contexts/OrganizationContext';
import { formatPrice } from '../../../../Helpers';

import DeliveryContentStyles from './styles';

function RadiusDeliveryContent() {
  const { organizationInfo } = useContext(OrganizationContext);

  if (!organizationInfo) return <></>;

  const { freeDeliveryMinimumOrderValue, neighbourhoods, states, deliveryRadius } = organizationInfo;

  let freeDeliveryMessage;

  if (freeDeliveryMinimumOrderValue !== undefined) {
    freeDeliveryMessage = 'Entrega GRÁTIS';

    if (freeDeliveryMinimumOrderValue > 0) {
      freeDeliveryMessage += ` a partir de ${formatPrice(
        freeDeliveryMinimumOrderValue,
      )}`;
    }
  }

  return (
    <DeliveryContentStyles.Container>
      <DeliveryContentStyles.Title>
        Regiões de entrega
      </DeliveryContentStyles.Title>

      {freeDeliveryMessage && (
        <DeliveryContentStyles.FreeDeliveryMessage>
          {freeDeliveryMessage}
        </DeliveryContentStyles.FreeDeliveryMessage>
      )}

      {neighbourhoods.length > 0 && (
        <DeliveryContentStyles.NeighbourhoodsTable>
          <thead>
            <tr>
              <th>Bairro</th>

              <th>Taxa de entrega</th>
            </tr>
          </thead>

          <tbody>
            {neighbourhoods
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(
                (neighbourhood): ReactElement => {
                  return (
                    <tr key={neighbourhood.id}>
                      <td>{neighbourhood.name}</td>

                      <td>
                        {neighbourhood.deliveryFee
                          ? formatPrice(neighbourhood.deliveryFee)
                          : 'Grátis'}
                      </td>
                    </tr>
                  );
                },
              )}
          </tbody>
        </DeliveryContentStyles.NeighbourhoodsTable>
      )}


      {states.length > 0 && (
        <>
          <Divider />
          <DeliveryContentStyles.NeighbourhoodsTable>
            <thead>
              <tr>
                <th>Estado</th>

                <th>Taxa de entrega</th>
              </tr>
            </thead>

            <tbody>
              {states
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(
                  (state): ReactElement => {
                    return (
                      <tr key={state.id}>
                        <td>{state.name}</td>

                        <td>
                          {state.deliveryFee
                            ? formatPrice(state.deliveryFee)
                            : 'Grátis'}
                        </td>
                      </tr>
                    );
                  },
                )}
            </tbody>
          </DeliveryContentStyles.NeighbourhoodsTable>
        </>
      )}

      {deliveryRadius.length > 0 && (
        <>
          <Divider />
          <DeliveryContentStyles.NeighbourhoodsTable>
            <thead>
              <tr>
                <th>Raio</th>

                <th>Taxa de entrega</th>
              </tr>
            </thead>

            <tbody>
              {deliveryRadius
                .sort((a, b) => a.radiusInMeters - b.radiusInMeters)
                .map(
                  (radius) => {
                    return (
                      <tr key={radius.radiusInMeters}>
                        <td>{radius.radiusInMeters / 1000} KM</td>

                        <td>
                          {radius.deliveryFee
                            ? formatPrice(radius.deliveryFee)
                            : 'Grátis'}
                        </td>
                      </tr>
                    );
                  },
                )}
            </tbody>
          </DeliveryContentStyles.NeighbourhoodsTable>
        </>
      )}
    </DeliveryContentStyles.Container>
  );
};

export default RadiusDeliveryContent;
