import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  withRouter,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom';
import PasswordRegisterStyles from './styles';
import GlobalContext from '../../contexts/GlobalContext';

interface IProps {
  onClose: () => void;
}

const PasswordRegister = (
  props: IProps & RouteComponentProps
) => {
  const { history, onClose } = props;
  const url = useRouteMatch().url.split('/')[1];
  const { setRedirectTarget } = useContext(GlobalContext);

  const hideButton = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    onClose();
  };

  return (
    <PasswordRegisterStyles.Container
      onClick={(): void => {
        setRedirectTarget({ pathname: `/${url}/pedidos` });

        history.push('perfil/cadastrar', { shouldPromote: true });
      }}
    >
      <PasswordRegisterStyles.CartBar>
        <span>Cadastrar senha para próximos pedidos</span>

        <span onClick={(e): void => hideButton(e)}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </PasswordRegisterStyles.CartBar>
    </PasswordRegisterStyles.Container>
  );
};

export default withRouter(PasswordRegister);
